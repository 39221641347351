const currentUser = (function() {
  if (localStorage.getItem("userData")) {
    let user = JSON.parse(localStorage.getItem("userData"));
    if (user.success === true && user.token) {
      return user;
    }
  }
})();

export default currentUser;
