import React, {Component} from "react";

import currentUser from "../utils/currentUser";
import {
    Button,
    Card,
    CardActions,
    Container,
    Dialog,
    DialogContent,
    DialogTitle,
    Grid,
    Paper,
    withStyles
} from "@material-ui/core";
import {Link, Link as RouterLink} from "react-router-dom";
import logo from "../assets/logo-v.png";
import bg from "../assets/bg4.jpg";
import bg2 from "../assets/bg5.jpg";
import videoThumnail1 from "../assets/episodes/episode-1.png";
import videoThumnail2 from "../assets/episodes/episode-2.jpg";
import videoThumnail3 from "../assets/episodes/episode-3.jpg";
import videoThumnail4 from "../assets/episodes/episode-4.jpg";
import videoThumnail5 from "../assets/episodes/episode-5.jpg";
import videoThumnail6 from "../assets/episodes/episode-6.jpg";

import {red} from "@material-ui/core/colors";
import {
    Close,
    DashboardOutlined,
    MeetingRoomOutlined,
    PersonOutlined,
    PersonRounded,
    PhoneRounded
} from "@material-ui/icons";
import {Follow, Timeline} from 'react-twitter-widgets'
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import {logoutUser} from "../redux/userAction";
import {connect} from "react-redux";
import {removeAllApplication} from "../redux/demandeAction";
import {removeAllEntreprise} from "../redux/entrepriseAction";
import {removeAllCip} from "../redux/cipAction";
import {AppVersion} from "../utils/config";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import YouTube from "react-youtube";

const useStyles = theme => ({
    root: {
        minHeight: '100vh',
        background: '#f3f3f3'
    },
    loginButton: {
        marginRight: 12
    },
    paper: {
        padding: "24px 36px 48px",
        margin: "24px 0"
    },
    logo: {
        display: "block",
        width: 250,
    },
    h2: {
        marginTop: 0,
        color: red["700"]
    },
    description: {
        fontSize: "1em"
    },
    media: {
        height: 197,
    },
    baner: {
        backgroundImage: `url(${bg})`,
        height: 320,
        backgroundColor: '#ffffff',
        backgroundPosition: "center right",
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        display: 'flex',
        alignItems: "center"
    },
    baner2: {
        backgroundImage: `url(${bg2})`,
        height: 350,
        backgroundColor: '#ffffff',
        backgroundPosition: "center left",
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        display: 'flex',
        alignItems: "center"
    },
    mainTitle: {
        width: "100%",
        color: "#fff",
        textAlign: "center",
        fontWeight: 900,
        fontSize: "3em",
        textShadow: "2px 1px 3px #000"
    },
    footer: {
        padding: "48px 0",
        backgroundColor: '#02204a',
        color: "#fff",
        textAlign: "center"
    },
    ul: {
        listStyle: "none"
    },
    a: {
        textDecoration: "underline",
        color: "#fff"
    },
    li: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    }

});

const responsive = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: {max: 4000, min: 3000},
        items: 5
    },
    desktop: {
        breakpoint: {max: 3000, min: 1024},
        items: 3
    },
    tablet: {
        breakpoint: {max: 1024, min: 464},
        items: 2
    },
    mobile: {
        breakpoint: {max: 464, min: 0},
        items: 1
    }
};

const videos = [
    {
        name: "Episode 1 - Créer un compte",
        description: "Comment créer un compte pour la plateforme.",
        thumbnail: videoThumnail1,
        urlId: "0IRz-CuCDs8"
    },
    {
        name: "Episode 2 - Vérifier votre Email",
        description: "Comment sécuriser votre compte en  vérifiant votre addresse email?",
        thumbnail: videoThumnail2,
        urlId: "768D-U8Bbps"
    },
    {
        name: "Episode 3 - Réinitialiser le mot de passe",
        description: "Vous pouvez changer votre mot de passe à tout moment, en cas d'oublie ou pour des raison de sécurité.",
        thumbnail: videoThumnail3,
        urlId: "3m3PxmGSIps"
    },
    {
        name: "Episode 4 - Poster une demande",
        description: "Poster une demande de CIP sur la plateforme pour votre entreprise ou une entreprise dont vous disposez d'un mandat vous autorisant à la représenter",
        thumbnail: videoThumnail4,
        urlId: "Fg2hfRJX5LA"
    },
    {
        name: "Episode 5 - Etat d’avancement d’une demande",
        description: "Vérifier comment avance votre dossier",
        thumbnail: videoThumnail5,
        urlId: ""
    },
    {
        name: "Episode 6 - Modifier une demande",
        description: "ã tout moment avant l'approbation finale vous pouvez modifier les details de la demande de CIP",
        thumbnail: videoThumnail6,
        urlId: ""
    },

]
const opts = {
    // eslint-disable-next-line no-restricted-globals
    height: window.innerHeight - 70,
    // eslint-disable-next-line no-restricted-globals
    width: window.innerWidth - 2,
    playerVars: {
        // https://developers.google.com/youtube/player_parameters
        autoplay: 1,
        modestbranding: 1,
        showinfo: 0,
        rel: 0,
        hl: 'fr',
    },
};

class Home extends Component {
    constructor(props) {
        super(props);
        if (currentUser) {
            this.state = {
                open: false
            };
        } else {
            this.state = {
                open: false
            }
        }
    }

    componentDidMount() {

    }

    handleClick = (event) => {
        this.setAnchorEl(event.currentTarget);
    };

    handleClose = () => {
        this.setAnchorEl(null);
    };

    setAnchorEl = (ev) => {
        this.setState(
            {
                anchorEl: ev || null,
                open: !!ev
            }
        )
    }


    logOut = () => {
        this.setState({
            open: false
        });
        this.props.logout(this.props.user?.token);
    };

    render() {
        const {classes} = this.props;
        return (
            <div>
                <header style={{backgroundColor: "#cee6ff"}}>
                    <Grid container justifyContent={"space-between"} alignItems={"center"}>
                        <Grid item>
                            <Link to={"/"}>
                                <img className={classes.logo} src={logo} alt="logo"/>
                            </Link>
                        </Grid>
                        <Grid item>
                            <div>
                                {
                                    !!this.props.user?.token ?
                                        <Button variant={"contained"} size="large" className={classes.loginButton}
                                                color={"secondary"}
                                                startIcon={<PersonOutlined/>}
                                                onClick={this.handleClick}>{this.props.user.user.full_name}</Button> :
                                        <Button variant={"contained"} className={classes.loginButton} size="large"
                                                startIcon={<PersonRounded/>}
                                                color={"secondary"} component={RouterLink} to={'/login'}>
                                            Connexion
                                        </Button>
                                }
                                <Menu
                                    id="long-menu"
                                    anchorEl={this.state.anchorEl}
                                    keepMounted
                                    open={this.state.open}
                                    onClose={this.handleClose}
                                    PaperProps={{style: {width: '40ch'}}}>
                                    <div>
                                        <MenuItem component={RouterLink} to={'/dashboard'}>
                                            <ListItemIcon>
                                                <DashboardOutlined fontSize="small"/>
                                            </ListItemIcon>
                                            Tableau de Bord
                                        </MenuItem>
                                        <MenuItem onClick={this.logOut}>
                                            <ListItemIcon>
                                                <MeetingRoomOutlined fontSize="small"/>
                                            </ListItemIcon>
                                            Déconnexion
                                        </MenuItem>
                                    </div>
                                </Menu>
                            </div>
                        </Grid>
                    </Grid>
                </header>
                <div className={classes.baner}>
                    <h1 className={classes.mainTitle}> MCI - CIP</h1>
                </div>
                <Container maxWidth={"md"}>
                    <Paper elevation={4} className={classes.paper}>
                        <h1 className={classes.h2}>Carte d’identité professionnelle </h1>
                        <h2>MINISTERE DU COMMERCE ET DE L'INDUSTRIE</h2>
                        <h3><strong>DIRECTION DU COMMERCE INTERIEUR</strong></h3>
                        <h4><strong>Procédures d'enregistrement des entreprises et d'émission
                            de la Carte d'Identité Professionnelle (CIP)</strong></h4>
                        <p><strong>Description</strong></p>

                        <p>Toute entreprise voulant exercer une activité commerciale ou industrielle doit détenir un
                            document d'identification, qui est la carte d'identité professionnelle, délivrée par le
                            Ministère du Commerce et de l'Industrie (MCI). Le délai de livraison de ce document est de
                            48 heures.<br/> <br/></p>
                        <p>La Carte d'Identité Professionnelle (CIP) fait l'objet d'une triple démarche administrative
                            au Ministère du Commerce et de l'Industrie et à la Direction Générale des Imp&ocirc;ts
                            (DGI)</p>

                        <ol>
                            <li>
                                <p><strong>A la Direction des Affaires Juridiques du M.C.I </strong></p>
                                <ol type={"a"}>
                                    <li>Enregistrement du nom commercial pour les entreprises individuelles</li>
                                    <li>Légalisation des statuts pour les sociétés</li>
                                </ol>
                            </li>
                            <li>
                                <strong>Au niveau de la DGI</strong>, l'intéressé doit obtenir les pièces suivantes :
                                <ol type={"a"}>
                                    <li>Carte d'Immatriculation Fiscale</li>
                                    <li>Reçu de caisse de la patente</li>
                                    <li>Reçu de caisse de la Carte d'Identité professionnelle (CIP)</li>
                                    <li>Certificat de patente</li>
                                </ol>
                            </li>
                            <p style={{paddingLeft: 24}}><strong>N.B :</strong> La copie de ces pièces doit être
                                valable pour l'exercice en cours</p>
                            <li>
                                <p>
                                    <strong>A la Direction du Commerce Intérieur du Ministère du Commerce et de
                                        L'Industrie </strong>Les formalités sont constituées par :
                                    <br/> - Pour une entreprise individuelle :
                                </p>
                                <ol type={"a"}>
                                    <li>Copie du Certificat d'enregistrement de nom commercial délivré par la Direction
                                        des Affaires Juridiques du MCI.
                                    </li>
                                    <li>Copie des Documents délivrés par la DGI pour l'exercice en cours</li>
                                    <li>Une photo d'identité de date récente</li>
                                    <li>
                                        Frais de traitement de dossier entre <b>750 gourdes</b> et <b>1,500
                                        gourdes</b> à payer au comptant au Service d' Approvisionnement du Ministère du
                                        Commerce et de l'Industrie.
                                        <div>
                                            <p><strong>N.B </strong>: Le montant initial d'investissement se situe :</p>
                                            <p><strong>Petit détaillant :</strong> entre <b>0</b> et <b>100,000.00
                                                gourdes</b>
                                                <br/> <strong>Gros détaillant : </strong>entre <b>100,001.00
                                                    gourdes</b> et plus<strong><br/> <br/></strong></p>
                                        </div>
                                    </li>
                                </ol>
                            </li>
                        </ol>

                        <p>En cas d'indisponibilité du commerçant, une tierce personne mandatée peut remplir les
                            formalités pour lui.</p>
                        <p>L'inspection de l'établissement commercial dans le cas d'une première application</p>
                        <p>La délivrance de la Carte d'Identité Professionnelle (CIP) dans un délai n'excédant pas 48
                            heures si toutes les conditions sont réunies. </p>


                        <h4>Autres pièces exigées (cas particulier)</h4>
                        <ul>
                            <li><strong>S'il s'agit d'un étranger : </strong>Permis de séjour pour l'exercice en cours
                            </li>
                            <li><strong>Sociétés de Capitaux (Société Anonyme et en Commandite par action) : </strong>Copie
                                du journal officiel &laquo; Le Moniteur &raquo; dans lequel sont publiés les statuts de
                                la Société
                            </li>
                            <li><strong>Sociétés de Personnes (en Nom Collectif et en Commandite Simple)
                                :</strong> Copie des statuts légalisés à la Direction des Affaires Juridiques du
                                Ministère du Commerce et de l'Industrie
                            </li>
                            <li><strong>Commerce de produits pharmaceutiques :</strong> Autorisation du Ministère de la
                                Santé Publique et de la Population (MSPP)
                            </li>
                            <li><strong>Agent de Commerce :</strong> Certificat d'inscription valide du greffe du
                                Tribunal civil du lieu de l'établissement.
                            </li>
                            <li><strong>Agence de voyage :</strong> copie du journal officiel &laquo; le
                                Moniteur &raquo; ayant publié l'arrêté présidentiel nommant l'intéressé officier
                                d'immigration.
                            </li>
                            <li><strong>Agences maritimes :</strong> autorisation de l'Autorité Portuaire Nationale
                                (APN)
                            </li>
                            <li><strong>Commissionnaire en Douane :</strong> Certificat d'agrément de l'administration
                                Générale des Douanes (AGD)
                            </li>
                            <li><strong>Service de courriers :</strong> autorisation de l'Office des Postes d'Haïti
                                (OPH)
                            </li>
                            <li><strong>Agent de change :</strong> Certificat signé du Gouverneur de la Banque de la
                                République d'Haïti (BRH)
                            </li>
                            <li><strong>Maison de transfert :</strong> Copie du journal officiel &laquo; Le
                                moniteur &raquo; contenant l'autorisation du Ministère de l'économie et Finances.
                            </li>
                            <li><strong>Commercialisation des produits pétroliers : </strong>Autorisation de la Mairie
                                o&ugrave; de se trouve l'Etablissement commercial, du service d'incendie ou du
                                commissariat de Police concerné.
                            </li>
                            <li><strong>Jeux de Hasard : </strong>Autorisation de la Loterie de l'Etat Haïtien.</li>
                            <li><strong>Presse parlée et Télévisée : </strong>Agrément du Conseil National des
                                Télécommunications (CONATEL) et l'autorisation du Ministère de l'Intérieur et des
                                Collectivités Territoriales (MICT).
                            </li>
                            <li><strong>Compagnie de sécurité : </strong>Autorisation du Ministère de l'Intérieur et des
                                Collectivités Territoriales (MICT).
                            </li>
                            <li><strong>Microfinance ou Microcrédit :</strong> Agrément de la Banque de la République
                                d'Haïti (BRH)
                            </li>
                            <li><strong>Transport Maritime :</strong> autorisation de la (SEMANAH) Service Maritime et
                                Navigation d'Haïti.
                            </li>
                            <li><strong>Ligne Aérienne : </strong>autorisation de l'Office National d'Aviation Civile
                                (OFNAC)
                            </li>
                            <li><strong>Dégroupeur :</strong> Autorisation signée du Ministre de l'Economie et des
                                Finances
                            </li>
                            <li><strong>Auto-école</strong> : autorisation de la Direction Centrale de la Police
                                Routière (DCPR)
                            </li>
                        </ul>
                    </Paper>
                </Container>

                <Container maxWidth={"xl"}
                           style={{backgroundColor: '#005b9f', minHeight: '48px', padding: '48px 24px'}}>
                    <h1 className={classes.mainTitle} style={{color: '#fff', marginTop: 0}}>Vidéo</h1>
                    <Carousel responsive={responsive} infinite swipeable centerMode>
                        {
                            videos.map(v => <Card style={{width: 350, height: 430}} key={`video-${v.urlId}`}>
                                <CardActionArea>
                                    <CardMedia
                                        className={classes.media}
                                        image={v.thumbnail}
                                        title={v.name}
                                    />
                                    <CardContent>
                                        <Typography gutterBottom variant="h5" component="h3">
                                            {v.name}
                                        </Typography>
                                        <Typography variant="body2" color="textSecondary" component="p">
                                            {v.description}
                                        </Typography>
                                    </CardContent>
                                </CardActionArea>
                                <CardActions>
                                    <Button variant={"contained"} color="primary"
                                            onClick={() => this.showVideo(v.urlId)}>
                                        Voire la vidéo
                                    </Button>
                                </CardActions>
                            </Card>)
                        }
                    </Carousel>;
                </Container>

                <Dialog
                    fullScreen={true}
                    open={!!this.state.urlId}
                    onClose={this.closeVideo}
                    className='no-padding'
                >
                    <DialogTitle> Voire la vidéo <Close color={"secondary"} style={{verticalAlign: "middle"}}
                                                        onClick={this.closeVideo}/></DialogTitle>
                    <DialogContent>
                        <YouTube videoId={this.state.urlId} opts={opts} onReady={this._onReady}/>
                    </DialogContent>
                </Dialog>

                <div className={classes.baner2}/>
                <Container maxWidth={"lg"}>
                    <Paper elevation={4} className={classes.paper}>
                        <Grid container spacing={3}>
                            <Grid item className={classes.description} md={7}>
                                <h1 className={classes.h2}>Mission du MCI</h1>
                                <p>Le Ministère du Commerce et de l'Industrie a pour mission de formuler et d'appliquer
                                    la politique du Gouvernement en matière commerciale et industrielle.</p>
                                <ol>
                                    <li>Il trace les lignes directrices de la politique en matière commerciale et
                                        industrielle.
                                    </li>
                                    <li>Il étudie toute mesure tendant à promouvoir le développement du commerce et de
                                        l'industrie.
                                    </li>
                                    <li>Il coordonne toutes négociations tendant aux conclusions des accords,
                                        conventions traités en matière commerciale, industrielle ou dans le domaine de
                                        l'intégration économique.
                                    </li>
                                    <li>Il applique sur toute l'étendue du territoire de la république les lois, les
                                        arrêtés, les règlements et les communiqués relatifs aux activités commerciales
                                        et industrielles.
                                    </li>
                                    <li>Il définit et coordonne les activités de promotion commerciale et industrielle à
                                        l'étranger.
                                    </li>
                                    <li>Il supervise et oriente les activités des organismes publics ou semi-publics
                                        sous sa tutelle.
                                    </li>
                                    <li>Il sert d'intermédiaire entre les chambres de commerce et d'industrie et les
                                        associations de protection du consommateur.
                                    </li>
                                    <li>Il exerce toutes autres attributions découlant de ses missions ou prévues par la
                                        loi.
                                    </li>
                                </ol>

                            </Grid>
                            <Grid item md={5}>
                                <Timeline
                                    dataSource={{
                                        sourceType: 'profile',
                                        screenName: 'HaitiMCI'
                                    }}
                                    options={{
                                        theme: "dark",
                                        lang: "fr",
                                        chrome: "noheader, nofooter",
                                        height: '600'
                                    }}
                                />
                            </Grid>
                        </Grid>


                    </Paper>
                </Container>
                <footer className={classes.footer}>
                    <Container maxWidth={"md"}>
                        <ul className={classes.ul}>
                            <li className={classes.li}><PhoneRounded/> <a href="tel:+509 2943-4488"
                                                                          className={classes.a}>+509 2943-4488</a></li>
                            <li className={classes.li}><PhoneRounded/> <a href="tel:+509 2943-1868"
                                                                          className={classes.a}>+509 2943-1868</a></li>
                            <li className={classes.li}><PhoneRounded/> <a href="tel:+509 4730-26918"
                                                                          className={classes.a}>+509 4730-2691</a></li>
                        </ul>

                        <p>
                            Rue Legitime, Port-au-Prince , Ouest, Haïti
                        </p>
                        <p>
                            MCI 2020 • <small>{AppVersion}</small>
                        </p>
                        <Follow username="HaitiMCI" options={{lang: "fr"}}/>
                    </Container>

                </footer>
            </div>
        )

    }

    showVideo(urlId) {
        this.setState({urlId})
    }

    closeVideo = () => {
        this.setState({urlId: false})
    }

    _onReady() {
        //
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
        app: state.app
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        logout: (token) => {
            dispatch(logoutUser(token));
            dispatch(removeAllApplication());
            dispatch(removeAllEntreprise());
            dispatch(removeAllCip());

        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(useStyles)(Home));

