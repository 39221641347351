import React, {Component} from "react";
import "../App.css";
import BadgeFront from "./BadgeFront";
import {IconButton, withStyles} from "@material-ui/core";
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import {
    CloseTwoTone,
    FlipCameraAndroidTwoTone,
    PrintTwoTone,
    RefreshTwoTone,
    WarningTwoTone,
    ZoomInTwoTone,
    ZoomOutTwoTone
} from "@material-ui/icons";
import BadgeBack from "./BadgeBack";
import {DateTime} from "luxon";
import {connect} from "react-redux";
import {BeatLoader} from "react-spinners";
import {css} from "@emotion/react";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Alert from "@material-ui/lab/Alert";
import {Api} from "../utils/api";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Slide from "@material-ui/core/Slide";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = (theme) => ({
    root: {
        width: '100vw',
        minHeight: '100vh',
        color: '#fff',
        backgroundColor: "#444748",
    },
    cardContainer: {
        justifyContent: "center",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    title: {
        flexGrow: 1,
    }
});

class PrintCip extends Component {
    constructor(props) {
        super(props);
        this.state = {
            seeDetailsOfDemande: true,
            zoom: false,
            entreprise: [],
            cipObject: {},
            cipEntreprise: {},
            cipUser: {},
            dossiers: [],
            loaded: false,
            loading: false,
            face: true,
            error: '',
            printing: true,
            openPrintingFinishe: false
        };
    }

    componentDidMount() {
        window.addEventListener('afterprint', (event) => {
            console.log('Printed')
            this.setState({
                openPrintingFinished: true
            })
        });

        window.addEventListener('beforeprint', (event) => {
            console.log('Printing...')
        });

        this.init();
    }

    componentWillUnmount() {
        // fix Warning: Can't perform a React state update on an unmounted component
        this.setState = (state, callback) => {
            return
        };

        console.log('unmounted')

        window.removeEventListener('beforeprint', (event) => {
        });
        window.removeEventListener('afterprint', (event) => {
        });

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.user?.token !== this.props.user?.token) {
            this.init();
        }
    }

    closeMarkedAsFinished = (markAsPrinted) => {
        console.log('Closed', markAsPrinted);
        this.setState({
            openPrintingFinished: false
        });

        if (markAsPrinted) {
            console.log('Marking');
            this.setState({
                loading: true
            });
            Api.markCardAsPrinted(this.state.cip.id).then(() => {
                this.setState({
                    loading: false
                }, this.props.triggerClose);
            }).catch((reason) => {
                console.error(reason);
                this.setState({
                    loading: false,
                    error: reason?.message
                });
            })
        }
    }

    init() {

        const user = this.props.user;
        if (!!user?.token && !this.state.loaded) {
            this.setState({
                loaded: true
            }, () => {
                this.loadCIP();
            })
        }
    }

    loadCIP = () => {
        this.setState({
            loading: true
        });
        const token = this.props.user?.token;

        let idCip = this.props.idCip;


        Api.getCipTopPrint(token, idCip).then(cip => {
            this.setState({
                cip,
                loading: false
            });
        }).catch(reason => {
            this.setState({
                loading: false,
                error: reason?.message
            })
        })
    }

    switchZoom = (ev) => {
        this.setState({zoom: !this.state.zoom});
    }

    printCard = () => {
        window.print()
    }

    close = () => {
        this.props.triggerClose();
    }

    flip = () => {
        this.setState({face: !this.state.face})
    }

    render() {
        const {classes} = this.props;
        const cip = this.state.cip || {};
        let {year, month} = DateTime.local();
        let {year: year2, month: month2} = DateTime.fromISO(cip?.printed?.created_at || cip?.created_at || '');
        if (month >= 10) {
            year++;
        }
        if (month2 >= 10) {
            year2++;
        }

        const face = this.state.face ? 'front' : 'back';
        const printing = this.state.printing;
        return (

            <div className={classes.root}>
                <AppBar className="hide-print" position="static">
                    <Toolbar>
                        <Typography variant="h6" className={classes.title}>
                            Impression CIP - Aperçu
                        </Typography>
                        <Box style={{display: "flex", padding: 6}}>
                            <IconButton disabled={this.state.loading} color='inherit'
                                        onClick={this.loadCIP}><RefreshTwoTone/></IconButton>
                            <IconButton disabled={this.state.loading} color='inherit' onClick={this.switchZoom}>
                                {
                                    this.state.zoom ? <ZoomOutTwoTone/> : <ZoomInTwoTone/>
                                }
                            </IconButton>
                            <IconButton disabled={this.state.loading || this.state.zoom} color='inherit'
                                        onClick={this.printCard}><PrintTwoTone/></IconButton>
                            <IconButton disabled={this.state.loading && Object.entries(cip).length > 0} color='inherit'
                                        onClick={this.close}><CloseTwoTone/></IconButton>
                        </Box>
                    </Toolbar>
                </AppBar>

                <div className="print-block">

                    {
                        !printing ?
                            <div style={{marginTop: 12}} className="center-content hide-print">
                                <Button color='primary' size='small' variant='contained' onClick={this.flip}
                                        startIcon={<FlipCameraAndroidTwoTone/>}>Tourner</Button>
                            </div> : null
                    }

                    {
                        (this.state.loading || Object.entries(cip).length === 0 || !cip.id) ?
                            <div style={{marginTop: 12}} className={"center-content hide-print"}>
                                {
                                    this.state.loading ? <BeatLoader
                                            css={override}
                                            size={10}
                                            color={"#116FBF"}
                                        /> :
                                        <Alert variant='standard' severity="error">
                                            {this.state.error || 'Il y a une erreur de connection pour le moment. Veuillez essayer à nouveau.'}
                                        </Alert>
                                }
                            </div> :
                            <div>
                                {
                                    this.state.error ? <Alert variant='standard' className='hide-print' severity="error">
                                        {this.state.error}
                                    </Alert> : null
                                }
                                {
                                    face === 'front' || printing ?
                                        <div className={classes.cardContainer}>
                                            <h4 className="hide-print">Face avant</h4>
                                            <BadgeFront
                                                zoom={this.state.zoom}
                                                cip={cip}
                                                token={this.props.user?.token}
                                                year={year}/>
                                        </div> : null
                                }
                                {
                                    face === 'back' || printing ?
                                        <div className={classes.cardContainer}>
                                            <h4 className="hide-print">Face arrière</h4>
                                            <BadgeBack
                                                id={cip?.id}
                                                year={year}
                                                created={year2}
                                                zoom={this.state.zoom}/>
                                        </div> : null
                                }
                            </div>
                    }
                    {
                        printing ? null :
                            <div style={{marginTop: 12}} className="center-content hide-print">
                                <Button color='primary' size='small' variant='contained' onClick={this.flip}
                                        startIcon={<FlipCameraAndroidTwoTone/>}>Tourner</Button>
                            </div>
                    }

                </div>

                <Dialog
                    disableBackdropClick
                    disableEscapeKeyDown
                    maxWidth="sm"
                    open={!!this.state.openPrintingFinished}
                    TransitionComponent={Transition}
                    onClose={() => this.closeMarkedAsFinished(false)}>
                    <DialogTitle>Impression réussi?</DialogTitle>
                    <DialogContent dividers>
                        <div className="center-content">
                            <WarningTwoTone style={{fontSize: '3rem', color: '#0b5dc3'}}/>
                        </div>
                        <DialogContentText style={{color: '#000'}}>
                            <span>Veuillez répondre <b><u>Oui</u></b> si l'impression est terminée et réussie. Le
                                responsable sera averti</span> <br/>
                            <small><b>Attention!</b> pour reprendre une impession, contacter la direction.</small>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => this.closeMarkedAsFinished(false)} color="secondary">
                            <b>Non</b>
                        </Button>
                        <Button onClick={() => this.closeMarkedAsFinished(true)} color="primary">
                            <b>Oui</b>
                        </Button>
                    </DialogActions>
                </Dialog>

            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

export default connect(mapStateToProps, {})(withStyles(useStyles)(PrintCip));

