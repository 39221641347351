import React, {Component} from "react";
import "./App.css";

// theme css for primereact library
// import components
import {HashRouter as Router, Route, Switch} from "react-router-dom";
import FormUser from "./component/FormUser";
import MainPage from "./component/MainPage/MainPage.lazy";
import Login from "./component/Login";
import Register from "./component/Register";
import Profile from "./component/Profile";
import FormSociety from "./component/FormSociety";
import Dashboard from "./component/Dashboard";
import Rapport from "./component/Rapport";
import FindRapport from "./component/FindRapport";
import PrintCip from "./component/PrintCip";
import NotFound from "./component/NotFound";
import ForgetPassword from "./component/ForgetPassword/ForgetPassword.lazy";
import Home from "./component/Home";
import NewPersonalBusiness from "./component/NewPersonalBusiness/NewPersonalBusiness.lazy";
import Users from "./component/Users/Users.lazy";
import Businessman from "./component/Businessman/Businessman.lazy";
import Demande from "./component/Demande/Demande.lazy";
import DemandeList from "./component/DemandeList/DemandeList.lazy";
import PrintingList from "./component/PrintingList/PrintingList.lazy";
import Business from "./component/Business/Business.lazy";
import PasswordReset from "./component/PasswordReset/PasswordReset.lazy";
import VerifyEmail from "./component/VerifyEmail/VerifyEmail.lazy";
import LogoutThenRedirect from "./component/LogoutThenRedirect";


class App extends Component {


    componentDidMount() {
        // this.setState();
    }

    render() {
        return (
            <Router>
                <Switch>
                    <Route
                        exact
                        path="/"
                        render={() => {
                            return <Home/>;
                        }}/>
                    <Route
                        exact
                        path="/home"
                        render={() => {
                            return <Home/>;
                        }}/>
                    <Route
                        exact
                        path="/login"
                        render={() => {
                            return <Login/>;
                        }}/>
                    <Route
                        exact
                        path="/profile"
                        render={() => <MainPage title={"Profile"}>
                            {
                                props => <Profile {...props}/>
                            }
                        </MainPage>}/>
                    <Route
                        exact
                        path="/register"
                        render={() => {
                            return (
                                <React.Fragment>
                                    <Register/>
                                </React.Fragment>
                            );
                        }}/>
                    <Route
                        exact
                        path="/forget-password"
                        render={(data) => {
                            return (
                                <React.Fragment>
                                    <ForgetPassword data/>
                                </React.Fragment>
                            );
                        }}/>
                    <Route
                        exact
                        path="/dashboard"
                        render={() => <MainPage title={"Tableau de Bord"}>
                            {
                                props => {
                                    const user = props.user;
                                    const isPrinter = user?.user?.group_id === 6;
                                    return isPrinter ? <PrintingList {...props} /> : <Dashboard {...props}/>
                                }
                            }
                        </MainPage>}/>
                    <Route
                        exact
                        path="/form"
                        render={() => <MainPage
                            title={"Nouvelle Demande"}>
                            {
                                props => <NewPersonalBusiness key='new-personal-business' {...props}/>
                            }
                        </MainPage>}/>
                    <Route
                        exact
                        path="/form-society"
                        render={() => <MainPage title={"Ajouter Société"}>
                            {
                                props => <FormSociety {...props}/>
                            }
                        </MainPage>}/>
                    <Route
                        exact
                        path="/list"
                        render={() => <MainPage title={"Liste de Demandes"}>
                            {
                                props => <DemandeList {...props}/>
                            }
                        </MainPage>}/>
                    <Route
                        exact
                        path="/list/:idDemande"
                        render={({match}) => <MainPage title={"Liste de Demandes"}>
                            {
                                props => <DemandeList idDemande={match.params.idDemande} {...props}/>
                            }
                        </MainPage>}/>
                    <Route
                        exact
                        path="/info_demande/:idDemande"
                        render={({match}) => <MainPage title={"Plus informations sur la demande"}>
                            {
                                props => <Demande idDemande={match.params.idDemande} {...props}/>
                            }
                        </MainPage>}/>
                    <Route
                        exact
                        path="/edit_form/:idDemande"
                        render={({match}) => <MainPage title={"Modifier Société"}>
                            {
                                props => <Demande edit={true} idDemande={match.params.idDemande} {...props}/>
                            }
                        </MainPage>}/>
                    <Route
                        exact
                        path="/rapport_unique"
                        render={() => <MainPage title={"Find Rapport"}>
                            {
                                props => <FindRapport {...props}/>
                            }
                        </MainPage>}/>
                    <Route
                        exact
                        path="/rapport"
                        render={() => <MainPage title={"Rapport des demandes de CPI"}>
                            {
                                props => <Rapport {...props}/>
                            }
                        </MainPage>}/>
                    <Route
                        exact
                        path="/entreprises"
                        render={() => <MainPage title={"Liste d'Entreprises"}>
                            {
                                props => <Business {...props}/>
                            }
                        </MainPage>}/>
                    <Route
                        exact
                        path="/entreprises/:idEntreprise"
                        render={({match}) => <MainPage title={"Liste d'Entreprises"}>
                            {
                                props => <Business {...props} idEntreprise={match.params.idEntreprise}/>
                            }
                        </MainPage>}/>
                    <Route
                        exact
                        path="/cip/:idCip"
                        render={({match}) => {
                            return (
                                <React.Fragment>
                                    <PrintCip idCip={match.params.idCip}/>
                                </React.Fragment>
                            );
                        }}/>
                    <Route
                        exact
                        path="/users/businessman"
                        render={() => <MainPage title={"Responssables & Commerçant"}>
                            {
                                props => (<Businessman {...props}/>)
                            }
                        </MainPage>}/>
                    <Route
                        exact
                        path="/users/mci"
                        render={() => <MainPage title={"Utilisateurs MCI"}>
                            {
                                props => (<Users {...props}/>)
                            }
                        </MainPage>}/>
                    <Route
                        exact
                        path="/form_user"
                        render={() => <MainPage title={"Créer un compte"}>
                            {
                                props => (<FormUser {...props}/>)
                            }
                        </MainPage>}/>

                    <Route
                        path="/password-reset/:token/:email"
                        render={({match}) => <LogoutThenRedirect
                            to={`/password-reset-page/${match.params.token}/${match.params.email}`}/>}/>

                    <Route
                        path="/password-reset-page/:token/:email"
                        render={({match}) => <PasswordReset email={match.params.email} token={match.params.token}/>}/>

                    <Route
                        path="/reset-email-result/:result"
                        render={({match}) => <VerifyEmail result={match.params.result}/>}/>

                    <Route component={NotFound}/>
                </Switch>
            </Router>
        );
    }
}


export default App;
