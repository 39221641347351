import {LOAD, SAVE} from 'redux-storage';

function storageAwareReducer(state = {loaded: false}, action) {
    switch (action.type) {
        case LOAD:
            return {...state, loaded: true};

        case SAVE:
            console.log('Something has changed and written to disk!', state);
            return {...state};
        default:
            return state;
    }
}

export default storageAwareReducer;
