export const SPECIALITY_LOAD = '[SPECIALITY] Load';
export const SPECIALITY_LOADED_SUCCESS = '[SPECIALITY] Load success';
export const SPECIALITY_LOADED_ERROR = '[SPECIALITY] Load error';
export const SPECIALITY_BUSY = '[SPECIALITY] busy';
export const SPECIALITY_CLEAR_ALL = '[SPECIALITY] clear all';

export function loadSpecialities(token) {
    return {
        type: SPECIALITY_LOAD,
        payload: {token}
    }
}

export function loadSpecialitySuccess(specialities) {
    return {
        type: SPECIALITY_LOADED_SUCCESS,
        payload: {
            specialities
        }
    }
}

export function loadSpecialityError(error) {
    return {
        type: SPECIALITY_LOADED_ERROR,
        payload: {error}
    }
}

export function processingSpeciality(busy) {
    return {
        type: SPECIALITY_BUSY,
        payload: {
            busy
        }
    }
}

export function removeAllSpeciality() {
    return {
        type: SPECIALITY_CLEAR_ALL,
        payload: {}
    }
}
