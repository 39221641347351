import React from "react";
import {Link} from "react-router-dom";

import "../../App.css";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import withStyles from "@material-ui/core/styles/withStyles";
import {PrintRounded} from "@material-ui/icons";
import IconButton from "@material-ui/core/IconButton";
import {DateTime} from "luxon";

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);

const ListCip = props => {
    if (props.typeList === "imprimer") {
        return (
            props.data.map(info => (
                <Link to={"/cip/" + info.id} target="_blank" data={info}>
                    <StyledTableRow key={info.id}>
                        <TableCell component="th" scope="row">
                            {info.no_cip}
                        </TableCell>
                        <TableCell align="right">
                            {info.entreprise.proprietaire !== null
                                ? info.entreprise.proprietaire
                                : info.entreprise.gerant_responsable}
                        </TableCell>
                        <TableCell align="right">{info.entreprise.nationalite} </TableCell>
                        <TableCell align="right">
                            {info.entreprise.nom_commercial !== null
                                ? info.entreprise.nom_commercial
                                : info.entreprise.raison_sociale}
                        </TableCell>
                        <TableCell align="right">{info.statut}</TableCell>
                        <TableCell align="right">{info.created_at}</TableCell>

                    </StyledTableRow>
                </Link>
            ))
        );
    }
    else if (props.typeList === "cipUser") {
        return (
            props.data.map(info => (

                    <TableRow key={info.id}>
                        <TableCell align="center">
                            {info.entreprise?.nom_commercial || '---'}
                        </TableCell>
                        <TableCell align="center">
                            {DateTime.fromISO(info.created_at).setLocale('fr-fr').toLocaleString(DateTime.DATETIME_MED) }
                        </TableCell>
                        <TableCell align="center">
                            {DateTime.fromISO(info.date_expiration).setLocale('fr-fr').toLocaleString(DateTime.DATETIME_MED) }
                        </TableCell>
                        <TableCell align="center">
                            {info.statut}
                        </TableCell>
                        <TableCell align="center">
                            <IconButton size='small' color="primary" to={"/cip/" + info.id} data={info} target="_blank" component={Link}>
                                <PrintRounded/>
                            </IconButton>
                        </TableCell>
                    </TableRow>
                )
            )
        )
    }
    else {
        const StyledTableCell = withStyles((theme) => ({
            head: {
                backgroundColor: theme.palette.common.black,
                color: theme.palette.common.white,
            },
            body: {
                fontSize: 14,
            },
        }))(TableCell);
        const StyledTableRow = withStyles((theme) => ({
            root: {
                '&:nth-of-type(odd)': {
                    backgroundColor: theme.palette.action.hover,
                },
            },
        }))(TableRow);

        return (
            props.data?.filter((d)=> d.entreprise ).map(info => (
                <StyledTableRow key={info.id}>
                    <StyledTableCell>
                        {info.no_cip}
                    </StyledTableCell>
                    <StyledTableCell>
                        {info.entreprise.proprietaire !== null
                            ? info.entreprise.proprietaire
                            : info.entreprise.gerant_responsable}
                    </StyledTableCell>
                    <StyledTableCell>{info.entreprise.nationalite}</StyledTableCell>
                    <StyledTableCell>
                        {info.entreprise.nom_commercial !== null
                            ? info.entreprise.nom_commercial
                            : info.entreprise.raison_sociale}
                    </StyledTableCell>
                    <StyledTableCell>{info.statut} </StyledTableCell>
                    <StyledTableCell>{info.created_at} </StyledTableCell>
                    <StyledTableCell>
                        <IconButton size='small' color="primary" to={"/cip/" + info.id} data={info} target="_blank" component={Link}>
                            <PrintRounded/>
                        </IconButton>
                    </StyledTableCell>
                </StyledTableRow>
            ))
        ) ||  <></>
    }
};

export default ListCip;
