import {SPECIALITY_CLEAR_ALL, SPECIALITY_LOAD, SPECIALITY_LOADED_ERROR, SPECIALITY_LOADED_SUCCESS} from "./specialityAction";

const initialState = {specialities: [], busy: false, lastVersion: null};

function specialityReducer(state = initialState, action) {
    switch (action.type) {
        case SPECIALITY_LOAD:
            return {
                ...state,
                busy: true
            };
        case SPECIALITY_LOADED_SUCCESS:
            return {
                ...state,
                specialities: action.payload.specialities,
                busy: false,
                lastVersion: new Date()
            };
        case SPECIALITY_LOADED_ERROR:
            return {
                ...state,
                error: action.payload.error,
                busy: false
            };
        case SPECIALITY_CLEAR_ALL:
            return {...initialState};
        default:
            return state;
    }
}

export default specialityReducer;
