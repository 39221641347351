export const ENTREPRISE_LOAD = '[ENTREPRISE] Load';
export const ENTREPRISE_LOADED_SUCCESS = '[ENTREPRISE] Load success';
export const ENTREPRISE_LOADED_ERROR = '[ENTREPRISE] Load error';
export const ENTREPRISE_TYPE_LOAD = '[ENTREPRISE_TYPE] Load';
export const ENTREPRISE_TYPE_LOADED_SUCCESS = '[ENTREPRISE_TYPE] Load success';
export const ENTREPRISE_TYPE_LOADED_ERROR = '[ENTREPRISE_TYPE] Load error';
export const ENTREPRISE_BUSY = '[ENTREPRISE] busy';
export const ENTREPRISE_CLEAR_ALL = '[ENTREPRISE] clear all';

export function loadEntreprise(token) {
    return {
        type: ENTREPRISE_LOAD,
        payload: {token}
    }
}

export function loadEntrepriseSuccess(entreprises) {
    return {
        type: ENTREPRISE_LOADED_SUCCESS,
        payload: {
            entreprises
        }
    }
}

export function loadEntrepriseError(error) {
    return {
        type: ENTREPRISE_LOADED_ERROR,
        payload: {error}
    }
}
export function loadEntrepriseType() {
    return {
        type: ENTREPRISE_TYPE_LOAD,
        payload: {}
    }
}

export function loadEntrepriseTypeSuccess(types) {
    return {
        type: ENTREPRISE_TYPE_LOADED_SUCCESS,
        payload: {
            types
        }
    }
}

export function loadEntrepriseTypeError(error) {
    return {
        type: ENTREPRISE_TYPE_LOADED_ERROR,
        payload: {error}
    }
}

export function processingEntreprise(busy) {
    return {
        type: ENTREPRISE_BUSY,
        payload: {
            busy
        }
    }
}

export function removeAllEntreprise() {
    return {
        type: ENTREPRISE_CLEAR_ALL,
        payload: {}
    }
}
