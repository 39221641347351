export const DEMANDE_ADD = '[DEMANDE] add new application';
export const DEMANDE_BUSY = '[DEMANDE] processing application';
export const DEMANDE_CLEAR = '[DEMANDE] clear saved application';
export const DEMANDE_CLEAR_ALL = '[DEMANDE] clear all saved applications';
export const DEMANDE_UPDATE = '[DEMANDE] update application';
export const DEMANDE_UPDATE_SELECTED = '[DEMANDE] update current demande';
export const DEMANDE_CLEAR_SELECTED = '[DEMANDE] clear current demande';
export const DEMANDE_LOAD = '[DEMANDE] Load all';
export const DEMANDE_LOAD_ONE = '[DEMANDE] Load one';
export const DEMANDE_LOAD_ONE_SUCCESS = '[DEMANDE] Load one success';
export const DEMANDE_LOADED_SUCCESS = '[DEMANDE] Load success';
export const DEMANDE_LOADED_ERROR = '[DEMANDE] Load error';


export function addApplication(data, id = null) {
    return {
        type: DEMANDE_ADD,
        payload: {
            data,
            id
        }
    }
}

export function updateApplication(id, step, update) {
    return {
        type: DEMANDE_UPDATE,
        payload: {
            id,
            update,
            step
        }
    }
}

export function updateSelectedDemande(update) {
    return {
        type: DEMANDE_UPDATE_SELECTED,
        payload: {
            update
        }
    }
}

export function clearSelectedDemande() {
    return {
        type: DEMANDE_CLEAR_SELECTED
    }
}

export function setApplicationBusy(busy) {
    return {
        type: DEMANDE_BUSY,
        payload: {
            busy
        }
    }
}

export function removeApplication(id) {
    return {
        type: DEMANDE_CLEAR,
        payload: {
            id
        }
    }
}

export function removeAllApplication() {
    return {
        type: DEMANDE_CLEAR_ALL,
        payload: {}
    }
}

export function loadDemande(token) {
    return {
        type: DEMANDE_LOAD,
        payload: {token}
    }
}

export function loadDemandeOne(token, id) {
    return {
        type: DEMANDE_LOAD_ONE,
        payload: {token, id}
    }
}

export function loadDemandeSuccess(demandes, lastUpdate) {
    return {
        type: DEMANDE_LOADED_SUCCESS,
        payload: {
            demandes,
            lastUpdate
        }
    }
}

export function loadDemandeOneSuccess(demande) {
    return {
        type: DEMANDE_LOADED_SUCCESS,
        payload: {
            demande
        }
    }
}

export function loadDemandeError(error) {
    return {
        type: DEMANDE_LOADED_ERROR,
        payload: {error}
    }
}
