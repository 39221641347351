import {all, call, put, select, spawn, takeLatest} from "@redux-saga/core/effects";
import {
    ENTREPRISE_LOAD,
    ENTREPRISE_LOADED_ERROR,
    ENTREPRISE_LOADED_SUCCESS,
    ENTREPRISE_TYPE_LOAD,
    ENTREPRISE_TYPE_LOADED_ERROR,
    ENTREPRISE_TYPE_LOADED_SUCCESS
} from "./entrepriseAction";
import {Api} from "../utils/api";
import {CIP_LOAD} from "./cipAction";
import {CATEGORY_LOAD, CATEGORY_LOADED_ERROR, CATEGORY_LOADED_SUCCESS} from "./categoryAction";
import {SPECIALITY_LOAD, SPECIALITY_LOADED_ERROR, SPECIALITY_LOADED_SUCCESS} from "./specialityAction";
import {
    DEMANDE_LOAD,
    DEMANDE_LOAD_ONE,
    DEMANDE_LOAD_ONE_SUCCESS,
    DEMANDE_LOADED_ERROR,
    DEMANDE_LOADED_SUCCESS
} from "./demandeAction";
import {getDemandeLastUpdate, getUserToken} from "./store";
import {
    USER_GET_STATUS,
    USER_GET_STATUS_ERROR,
    USER_GET_STATUS_SUCCES,
    USER_LOGIN,
    USER_LOGIN_ERROR,
    USER_LOGIN_SUCCESS,
    USER_LOGOUT,
    USER_LOGOUT_ERROR,
    USER_LOGOUT_SUCCESS
} from "./userAction";
import avatarM from "../assets/avatar_m.svg";
import avatarF from "../assets/avatar_f.svg";

function* fetchAllEntreprise(action) {
    try {
        const entreprises = yield call(Api.loadCompanies, action.payload.token);
        yield put({type: ENTREPRISE_LOADED_SUCCESS, payload: {entreprises}});
    } catch (e) {
        console.error(e);
        yield put({type: ENTREPRISE_LOADED_ERROR, payload: {error: e}});
    }
}

function* fetchAllEntrepriseTypes(action) {
    try {
        const types = yield call(Api.loadTypes);
        yield put({type: ENTREPRISE_TYPE_LOADED_SUCCESS, payload: {types}});
    } catch (e) {
        console.error(e);
        yield put({type: ENTREPRISE_TYPE_LOADED_ERROR, payload: {error: e}});
    }
}

function* fetchAllCIP(action) {
    try {
        const cips = yield call(Api.loadCIP, action.payload.token);
        yield put({type: ENTREPRISE_LOADED_SUCCESS, payload: {cips}});
    } catch (e) {
        console.error(e);
        yield put({type: ENTREPRISE_LOADED_ERROR, payload: {error: e}});
    }
}

function* postUserLgout(action) {
    try {
        yield call(Api.logoutUser, action.payload.token);
        yield put({type: USER_LOGOUT_SUCCESS});
    } catch (e) {
        console.error(e);
        yield put({type: USER_LOGOUT_ERROR, payload: {error: e}});
    }
}

function* processUserLogin(action) {
    const {user, token} = action.payload;

    try {

        const remoteAvatar = yield call(Api.getAvatar, token);
        let avatar = remoteAvatar || (user?.sexe?.toLowerCase() === "m" ? avatarM : avatarF);

        yield put({type: USER_LOGIN_SUCCESS, payload: {avatar}});
    } catch (e) {
        let avatar = user?.sexe?.toLowerCase() === "m" ? avatarM : avatarF;
        console.error(e);
        yield put({type: USER_LOGIN_ERROR, payload: {error: e, avatar}});
    }
}

function* fetchAllCategories() {
    try {
        const categories = yield call(Api.loadCategories);
        yield put({type: CATEGORY_LOADED_SUCCESS, payload: {categories}});
    } catch (e) {
        console.error(e);
        yield put({type: CATEGORY_LOADED_ERROR, payload: {error: e}});
    }
}

function* fetchAllDemandes() {
    try {
        const token = yield select(getUserToken);
        const lastUpdateServer = yield select(getDemandeLastUpdate);
        const {demandes, lastUpdate} = yield call(Api.loadDemandes, token, lastUpdateServer);
        yield put({type: DEMANDE_LOADED_SUCCESS, payload: {demandes, lastUpdate}});
    } catch (e) {
        console.error(e);
        yield put({type: DEMANDE_LOADED_ERROR, payload: {error: e}});
    }
}

function* fetchOneDemande(action) {
    try {
        const demande = yield call(Api.loadDemande, action.payload.token, action.payload.id);
        yield put({type: DEMANDE_LOAD_ONE_SUCCESS, payload: {demande}});
    } catch (e) {
        console.error(e);
        yield put({type: DEMANDE_LOADED_ERROR, payload: {error: e}});
    }
}

function* fetchAllSpecialities() {
    try {
        const specialities = yield call(Api.loadSpecialities);
        yield put({type: SPECIALITY_LOADED_SUCCESS, payload: {specialities}});
    } catch (e) {
        console.error(e);
        yield put({type: SPECIALITY_LOADED_ERROR, payload: {error: e}});
    }
}

function* fetcUserData() {
    try {
        const userStatus = yield call(Api.checkUserStatus);
        yield put({type: USER_GET_STATUS_SUCCES, payload: {userStatus}});
    } catch (e) {
        console.error(e);
        yield put({type: USER_GET_STATUS_ERROR, payload: {error: e}});
    }
}

// /*
//   Starts fetchEntreprise on each dispatched `ENTREPRISE_FETCH_REQUESTED` action.
//   Allows concurrent fetches of entreprise.
// */
// function* mySaga() {
//     yield takeEvery("ENTREPRISE_FETCH_REQUESTED", fetchEntreprise);
// }

function* entrepriseSaga() {
    yield takeLatest(ENTREPRISE_LOAD, fetchAllEntreprise);
}

function* typesSaga() {
    yield takeLatest(ENTREPRISE_TYPE_LOAD, fetchAllEntrepriseTypes);
}

function* cipSaga() {
    yield takeLatest(CIP_LOAD, fetchAllCIP);
}

function* logoutUser() {
    yield takeLatest(USER_LOGOUT, postUserLgout);
}

function* loginUser() {
    yield takeLatest(USER_LOGIN, processUserLogin);
}

function* categorySaga() {
    yield takeLatest(CATEGORY_LOAD, fetchAllCategories);
}

function* specialitySaga() {
    yield takeLatest(SPECIALITY_LOAD, fetchAllSpecialities);
}

function* userStatusSaga() {
    yield takeLatest(USER_GET_STATUS, fetcUserData);
}

function* demandesSaga() {
    yield takeLatest(DEMANDE_LOAD, fetchAllDemandes);
}

function* demandeSaga() {
    yield takeLatest(DEMANDE_LOAD_ONE, fetchOneDemande);
}

function* rootSaga() {
    const sagas = [
        entrepriseSaga,
        cipSaga,
        categorySaga,
        specialitySaga,
        typesSaga,
        demandesSaga,
        demandeSaga,
        logoutUser,
        loginUser,
        userStatusSaga
    ];
    yield all(sagas.map(saga =>
        spawn(function* () {
            while (true) {
                try {
                    yield call(saga);
                    break
                } catch (e) {
                    console.error(e)
                }
            }
        }))
    );

}

export default rootSaga;
