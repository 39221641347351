import React, { lazy, Suspense } from 'react';

const LazyForgetPassword = lazy(() => import('./ForgetPassword'));

const ForgetPassword = props => (
  <Suspense fallback={null}>
    <LazyForgetPassword {...props} />
  </Suspense>
);

export default ForgetPassword;
