import React, { lazy, Suspense } from 'react';

const LazyBusinessman = lazy(() => import('./Businessman'));

const Businessman = props => (
  <Suspense fallback={null}>
    <LazyBusinessman {...props} />
  </Suspense>
);

export default Businessman;
