export const CATEGORY_LOAD = '[CATEGORY] Load';
export const CATEGORY_LOADED_SUCCESS = '[CATEGORY] Load success';
export const CATEGORY_LOADED_ERROR = '[CATEGORY] Load error';
export const CATEGORY_BUSY = '[CATEGORY] busy';
export const CATEGORY_CLEAR_ALL = '[CATEGORY] clear all';

export function loadCategory(token) {
    return {
        type: CATEGORY_LOAD,
        payload: {token}
    }
}

export function loadCategorySuccess(categories) {
    return {
        type: CATEGORY_LOADED_SUCCESS,
        payload: {
            categories
        }
    }
}

export function loadCategoryError(error) {
    return {
        type: CATEGORY_LOADED_ERROR,
        payload: {error}
    }
}

export function processingCategory(busy) {
    return {
        type: CATEGORY_BUSY,
        payload: {
            busy
        }
    }
}

export function removeAllCategory() {
    return {
        type: CATEGORY_CLEAR_ALL,
        payload: {}
    }
}
