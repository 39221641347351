import React, {useState} from "react";
import armoirie from "../assets/armoirie.jpg";

import QRCode from "qrcode.react";
import {Api} from "../utils/api";

const BadgeFront = props => {
    const cip = props.cip;
    const entreprise = cip?.entreprise || {};
    const demande = cip?.demande || {};
    const dossiers = entreprise?.dossiers || [];
    const entreprise_type = entreprise.entreprise_type_id;
    let entreprise_name;

    switch (entreprise_type) {
        case 1:
            // entreprise individuelle
            entreprise_name = entreprise.nom_commercial
            break;
        case 2: // societe nom collectif
        case 3:
            // societe en commendite simple
            entreprise_name = entreprise.denomination_commerciale
            break;
        case 4:/// societe en commendite par action
        case 5:
            // societe anonyme
            entreprise_name = entreprise.raison_sociale
            break;
        default:
            entreprise_name = '';
    }

    const [initialized, setInit] = useState(false);
    const [photo, setPhoto] = React.useState(null);


    const qrCode = `Nom-PRéNOM: ${entreprise.proprietaire_lastname} ${entreprise.proprietaire_firstname}
Nationalité:${entreprise.nationalite}
Raison Sociale: ${entreprise.company_name}
Patente:${entreprise.no_patente}
Code:${demande.code}
Code de Bureau:${entreprise.po_box}
Adresse: ${entreprise.adresse} 
Téléphone: ${entreprise.phone}
Commune:${entreprise.commune}
Département:${entreprise.departement}-`;


    const withoutPictureFormat = [4, 5].includes(entreprise_type);

    if (!initialized && !withoutPictureFormat) {
        setInit(true);

        let arrayIdentite = [];

        dossiers.forEach(element => {
            if (
                element.type_id === 1
            ) {
                arrayIdentite.push(element);
            }
        });

        arrayIdentite.sort((a, b) => {
            if (a.id < b.id) {
                return 1;
            }
            if (a.id > b.id) {
                return -1;
            }
            return 0;
        });
        const img = arrayIdentite[0];
        if (img) {
            if (!!props.token) {
                Api.getFile(props.token, img.id)
                    .then(value => {
                        setPhoto(URL.createObjectURL(value))
                    })
                    .catch(e => console.error(e));
            }

        }
    }


    return (
        <div className={props.zoom ? 'cip-card zoom' : 'cip-card'}>
            <div className='br1'>
                <span>CARTE D’IDENTITÉ PROFESSIONELLE</span>
            </div>
            <div className="br2">
                <span>{cip.category?.name}</span>
            </div>
            <div className="br3">
                {
                    !withoutPictureFormat ?
                        <div className="profile-pic" id="cip-profile-pic" style={{backgroundImage: `url(${photo})`}}/> :
                        <div className='cip_no_picture_description'>
                            <div className="content">{cip.description_photo}</div>
                        </div>
                }
                {
                    !withoutPictureFormat ?
                        <div className="cip-info">
                            {
                                // TODO nom complet
                            }
                            <div className="c-title">Nom:</div>
                            <div className="c-name text">
                                {entreprise.proprietaire_lastname || '???'}
                            </div>
                            <div className="c-title">Prénom:</div>
                            <div className="c-name text">
                                {entreprise.proprietaire_firstname || '???'}
                            </div>
                            <div className="c-title">Exercice:</div>
                            {
                                // TODO automate exercice
                            }
                            <div className="c-exercice text">{`${props.year - 1} - ${props.year}`}</div>
                            <div className="c-title">Spécialité:</div>
                            <div className="c-speciality text">
                                {cip.speciality?.name || '???'}
                            </div>
                            <div className="c-title">Numéro:</div>
                            <div className="c-number">{cip.no_cip}</div>
                        </div> :
                        <div className="cip-info">

                            <div className="c-title" style={{textAlign: 'center'}}>Exercice</div>
                            <div className="c-exercice text"
                                 style={{textAlign: 'center'}}>{`${props.year - 1} - ${props.year}`}</div>
                            <div className="c-title">Spécialite</div>
                            <div className="c-speciality text">
                                {cip.speciality?.name || '???'}
                            </div>
                            <div className="c-title">Numéro</div>
                            <div className="c-number">{cip.no_cip}</div>
                        </div>
                }
            </div>
            <div className="br4">
                <div className="c-title">Nom Commercial/Raison Sociale</div>
                <div className="c-text c-raison">
                    {entreprise_name || '???'}
                </div>
            </div>
            <div className="br5">
                <div className="br5-child">
                    <img src={armoirie} className="armoirie" alt="🖼"/>
                    <p className="c-ministere">
                        Ministère du Commerce <br/>et de l'Industrie
                    </p>
                </div>
                <div className="br5-child">
                    <QRCode value={qrCode || ''} size={props.zoom ? 120 : 60}/>
                </div>
            </div>
            <div className="br6">
                www.mci.gouv.ht
            </div>
        </div>
    );
};
export default BadgeFront;
