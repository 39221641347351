import React, {Component} from "react";
import "../../App.css";
import {BeatLoader} from "react-spinners";
import {css} from "@emotion/react";
import red from "@material-ui/core/colors/red";
import pattern from 'patternomaly';
import withStyles from "@material-ui/core/styles/withStyles";
import {settings} from "../../utils/settings";
import { Line as LineChart} from 'react-chartjs-2';

const useStyles = theme => ({
    graph: {
        position: "relative",
        [theme.breakpoints.down('sm')]: {
            width: "95vw",
        },
        [theme.breakpoints.up('md')]: {
            width: "calc( 100vw - 270px ) ",
        },
        [theme.breakpoints.up('lg')]: {
            width: "calc( 100vw - 270px ) ",
        },
    },
});

const override = css`
  display: block;
  margin: 0 auto;
`;
// armchart


// const LineChart = require("react-chartjs-2").Line;
const chartOptions = {
    ///Boolean - Whether grid lines are shown across the chart
    scaleShowGridLines: true,

    //String - Colour of the grid lines
    scaleGridLineColor: "rgba(255,255,2555,.2)",

    //Number - Width of the grid lines
    scaleGridLineWidth: 1,

    scales: {
        yAxes: [{
            ticks: {
                precision: 0
            }
        }]
    },

    //Boolean - Whether to show horizontal lines (except X axis)
    scaleShowHorizontalLines: true,

    //Boolean - Whether to show vertical lines (except Y axis)
    scaleShowVerticalLines: false,

    //Boolean - Whether the line is curved between points
    bezierCurve: true,

    //Number - Tension of the bezier curve between points
    bezierCurveTension: 0.4,

    //Boolean - Whether to show a dot for each point
    pointDot: true,

    //Number - Radius of each point dot in pixels
    pointDotRadius: 8,

    //Number - Pixel width of point dot stroke
    pointDotStrokeWidth: 10,

    //Number - amount extra to add to the radius to cater for hit detection outside the drawn point
    pointHitDetectionRadius: 20,

    //Boolean - Whether to show a stroke for datasets
    datasetStroke: true,

    //Number - Pixel width of dataset stroke
    datasetStrokeWidth: 10,

    //Boolean - Whether to fill the dataset with a colour
    datasetFill: true,

    //Boolean - Whether to horizontally center the label and point dot inside the grid
    offsetGridLines: true,
    responsive: true,
    maintainAspectRatio: false
};

// const blue = "rgba(76, 244, 104, 0.49)";

// let chartData = {
//   labels: ["January", "February", "March", "April", "May", "June", "July"],
//   datasets: [
//     {
//       label: "Stat pour l'annee",
//       data: [5000, 7000, 4245, 9188, 5123, 3445, 1000],
//       fillColor: gray,
//       strokeColor: gray,
//       highlightFill: gray,
//       highlightStroke: gray
//     }
//   ]
// };

class MainChart extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            chartData: {
                labels: [
                    "Octobre",
                    "Novembre",
                    "Décembre",
                    "Janvier",
                    "Février",
                    "Mars",
                    "Avril",
                    "Mai",
                    "Juin",
                    "Juillet",
                    "Août",
                    "Septembre",
                ],
                datasets: []
            }
        };
    }

    componentDidMount() {
        this.fetchData(this.props.year);
    }

    componentDidUpdate(prevProps, s, ss) {
        if (prevProps.year !== this.props.year) {
            const year = this.props.year || new Date().getFullYear()
            this.fetchData(year);
        }
    }

    componentWillUnmount() {
        // fix Warning: Can't perform a React state update on an unmounted component
        this.setState = (state, callback) => {
            return;
        };
    }

    updateChart() {
        return this.fetchData(this.props.year);
    }

    fetchData(year) {
        this.setState({
            loading: true
        });
        let urlStat =
            `${settings.api}/api/v1/entreprise/analytics/fiscal-year/${year || new Date().getFullYear()}`;

        fetch(urlStat, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json; charset=utf-8",
                Authorization: "Bearer " + this.props.token
            }
        })
            .then(res => res.json())
            .then(response => {

                const data = response.data;

                // {data: 3, month: 8}
                const donnees = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

                for (const dt of data) {
                    let index = parseInt(dt.month, 10);

                    if (index >= 10) {
                        index = index - 10
                    } else {
                        index += 2;
                    }
                    donnees[index] = dt.data
                }

                const datasets = [
                    {
                        label: `Statistiques année fiscale 📅`,
                        borderColor: "#0968d9",
                        pointRadius: 4,
                        pointBorderColor: red["500"],
                        pointBackgroundColor: "#ffffff",
                        backgroundColor: pattern.draw('dot', 'rgb(55,166,255, .3)', "rgb(55,166,255, .7)"),
                        data: donnees
                    }
                ];
                // Return resObj;
                this.setState({
                    chartData: {
                        ...this.state.chartData,
                        datasets
                    }
                });

            })
            .catch(error => console.error("Error:", error))
            .finally(() => {
                this.setState({
                    loading: false
                });
            });
    }

    render() {
        const {classes} = this.props;
        return (
            <div>
                <div className={classes.graph}>
                    {!this.state.chartData ? null : (
                        <LineChart
                            data={this.state.chartData}
                            options={chartOptions}
                            height={200}
                            width={1200}
                            id={'main-Chart-1'}
                        />
                    )}
                </div>

                {!!this.state.loading ? (
                    <div style={{marginTop: 12}} className={"center-content"}>
                        <BeatLoader
                            css={override}
                            size={10}
                            color={"#116FBF"}
                        />
                    </div>

                ) : null}
            </div>
        );
    }
}

export default withStyles(useStyles)(MainChart);
