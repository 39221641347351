import React from "react";
import {Link} from "react-router-dom";

import "../../App.css";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import {VisibilityRounded} from "@material-ui/icons";
import {DateTime} from "luxon";
import {explainDemandeStatusLong, explainSemandeStatus} from "../../utils/funcs";
import {IconButton} from "@material-ui/core";
import Pop from "../Pop";

const ListShowAskAdmin = props => {
    if (props.typeList === "showEntrepriseDashbord") {

        return (
            props.data.map(info => {
                const created = DateTime.fromISO(info.created_at).setLocale('fr-FR').toLocaleString(DateTime.DATETIME_MED);
                const updated = DateTime.fromISO(info.updated_at).setLocale('fr-FR').toLocaleString(DateTime.DATETIME_MED);
                return <TableRow key={`demande-${info.id}`}>
                    <TableCell>
                        <small>{info.entreprise?.raison_sociale || info.entreprise?.nom_commercial || info.entreprise?.denomination_commerciale}</small>
                    </TableCell>
                    <TableCell><small>{info.category?.name}</small></TableCell>
                    <TableCell><small>{info.speciality?.name}</small></TableCell>
                    <TableCell><small>{info.entreprise?.entrepriseType?.description}</small></TableCell>
                    <TableCell><small>{created}</small></TableCell>
                    <TableCell><small>{updated}</small></TableCell>
                    <TableCell className='center-content'>
                        <Link to={"/info_demande_admin/" + info.id} data={info}>
                            <VisibilityRounded/>
                        </Link>
                    </TableCell>
                </TableRow>
            })
        );
    } else if (props.typeList === "for_print") {
        return props.data.map(info => (
                <TableRow key={`demande-${info.id}`}>
                    <TableCell>
                        {info.no_patente}
                    </TableCell>
                    <TableCell>
                        {info.proprietaire === null
                            ? info.gerant_responsable
                            : info.proprietaire}
                    </TableCell>
                    <TableCell>{info.nationalite}</TableCell>
                    <TableCell>
                        {info.raison_sociale === null
                            ? info.nom_commercial
                            : info.raison_sociale}
                    </TableCell>
                    <TableCell> {info.statut_demande}</TableCell>
                    <TableCell>
                        <Link to={"/info_demande_admin/" + info.id} data={info}>
                            <VisibilityRounded/>
                        </Link>
                    </TableCell>
                </TableRow>
            )
        )
    } else {
        const userId = props.user?.id;
        return (
            props.data.map(info => {
                const created = DateTime.fromISO(info.created_at).setLocale('fr-FR').toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS);
                const updated = DateTime.fromISO(info.updated_at).setLocale('fr-FR').toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS);
                let color = null;
                if (info.assigned_to) {
                    color = '#367dd6';
                    if (info.assigned_to === userId) {
                        color = '#00b107'
                    }
                }

                return <TableRow key={`demande-${info.id}`} className={`demande-${info.status}`}>
                    <TableCell>
                        <p className='number' style={{fontSize: '14px'}}>{info.code}</p>
                    </TableCell>
                    <TableCell>
                        <div className='cell'>
                            <h3 className="cell-title">
                                {info.entreprise?.company_name}
                            </h3>
                            <p className="cell-content">
                                {info.entreprise?.entreprise_type?.description}
                            </p>
                        </div>
                    </TableCell>
                    <TableCell>
                        <div className="cell">
                            <h4 className="cell-subtitle">Category</h4>
                            <p className="cell-content">{info.speciality?.name}</p>
                            <h4 className="cell-subtitle">Specialité</h4>
                            <p className="cell-content">{info.category?.name}</p>
                        </div>
                    </TableCell>
                    <TableCell>
                        <Pop description={explainDemandeStatusLong(info.status)}>
                            <small><i>{explainSemandeStatus(info.status)}</i></small>
                        </Pop>
                        <small>
                            {
                                info.assigned_to ? <span>Assigné</span> : null
                            }
                            {
                                info.assigned_to === userId ? <b> à vous</b> : null
                            }
                            {
                                info.user_id === userId ? <div>Demande postée par vous</div> : null
                            }
                        </small>
                    </TableCell>
                    <TableCell>
                        <div className="cell">
                            <h4 className="cell-subtitle">Création</h4>
                            <p className="cell-content">{created}</p>
                            <h4 className="cell-subtitle">Mise-à-jour</h4>
                            <p className="cell-content">{updated}</p>
                        </div>
                    </TableCell>
                    <TableCell align={"center"}>

                        {
                            props.onClickView ? <IconButton onClick={() => props.onClickView(info.id)} data={info}>
                                    <VisibilityRounded style={{color: color}}/>
                                </IconButton> :
                                <IconButton component={Link} to={`/list/${info.id}`} data={info}>
                                    <VisibilityRounded/>
                                </IconButton>
                        }
                    </TableCell>
                </TableRow>
            })
        );
    }
};
export default ListShowAskAdmin;
