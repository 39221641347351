export const CIP_LOAD = '[CIP] Load';
export const CIP_LOADED_SUCCESS = '[CIP] Load success';
export const CIP_LOADED_ERROR = '[CIP] Load error';
export const CIP_BUSY = '[CIP] busy';
export const CIP_CLEAR_ALL = '[CIP] clear all';

export function loadCip(token) {
    return {
        type: CIP_LOAD,
        payload: {token}
    }
}

export function loadCipSuccess(cips) {
    return {
        type: CIP_LOADED_SUCCESS,
        payload: {
            cips
        }
    }
}

export function loadCipError(error) {
    return {
        type: CIP_LOADED_ERROR,
        payload: {error}
    }
}

export function processingCip(busy) {
    return {
        type: CIP_BUSY,
        payload: {
            busy
        }
    }
}

export function removeAllCip() {
    return {
        type: CIP_CLEAR_ALL,
        payload: {}
    }
}
