import React, {Component} from "react";
import "../App.css";
import logo from "../assets/logo.png";
import {Link, Redirect} from "react-router-dom";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import {css} from "@emotion/react";
import {AppVersion, baseUrl} from "../utils/config";
import {BeatLoader} from "react-spinners";
import {Box, Button as Button2, Container, Grid, withStyles} from "@material-ui/core";
import {Button} from 'primereact/button';
import {InfoOutlined, VisibilityOffTwoTone, VisibilityTwoTone} from "@material-ui/icons";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";
import {connect} from "react-redux";
import {loginUser, processingUser} from "../redux/userAction";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

const useStyles = theme => ({
    root: {
        minHeight: '100vh'
        // background: '#f3f3f3'
    },
    paper: {
        height: 140,
        width: 100,
    },
    img: {
        height: '360px',
        display: 'block'
    },
    loginView: {
        background: '#fff',
        boxShadow: '0 2px 5px #a98686',
        borderRadius: 5
    },
    formLogin: {
        padding: "24px",
        marginTop: 24,
        boxShadow: "none",
        borderLeft: "1px solid #007ad9",
        paddingLeft: 48
    },
    buttonContainer: {
        width: "100%",
        display: "flex",
        height: 70,
        justifyContent: "flex-end",
        alignItems: "center"
    },
    input: {
        width: "100%",
        height: "40px",
        paddingLeft: "8px",
        borderRadius: "3px",
        border: "1px solid #666666",
        outline: "none",
        marginTop: "5px",
    },
    contentCreateAndForget: {
        display: "flex",
        justifyContent: "space-around",
        margin: "12px",
        alignItems: "center"
    },
    verticalSeparator: {
        width: 1,
        height: 31,
        background: "#b6b6b6"
    },
    forget: {
        borderRadius: 50,
        background: "#b6dcff40",
        padding: "6px 20px",
        textAlign: "center",
        height: "max-content",
        fontWeight: 600
    }
});

const ua = navigator.userAgent.toLowerCase();
if (ua.indexOf("safari") !== -1) {
    if (ua.indexOf("chrome") > -1) {
        // alert("1") // Chrome
    } else {
        alert("Please use chrome  browser for this app"); // Safari
    }
}

class Login extends Component {

    constructor(props) {
        super(props);
        this.state = {
            email: "",
            password: "",
            user: "",
            showIndicator: false,
            open: false,
            message: null,
            email_not_verified: false,
            lastEmail: '',
            showPassword: false
        };
    }

    handleClose = () => {
        this.setState({
            open: false
        });
    };

    handleChange = event => {
        this.setState({[event.target.name]: event.target.value});
    };

    componentWillUnmount() {
        // fix Warning: Can't perform a React state update on an unmounted component
        this.setState = (state, callback) => {
            return;
        };
    }

    toggleShowPassword = () => {
        this.setState({showPassword: !this.state.showPassword});
    }

    validerEmail = (event) => {
        event.preventDefault();
        this.setState({
            showIndicator: true
        });

        let dataToSend = {
            email: this.state.lastEmail
        };

        dataToSend = JSON.stringify(dataToSend);

        // const data = new FormData(event.target);
        // var dataToSend = stringifyFormData(data);

        fetch(`${baseUrl}user/verifyEmail`, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: dataToSend
        })
            .then(res => res.json())
            .then(res => {

                if (res.success === true) {
                    this.setState({
                        open: true,
                        message: res.message || "Vérifier votre email"
                    });
                } else {
                    this.setState({
                        open: true,
                        message: res.message || "Vérifier vos paramètres de connexion"
                    });
                }
                this.setState({
                    showIndicator: false
                });
            })
            .catch(err => {
                console.error("Error login: ", err);

                // alert("Veréifiez votre connexion internet");
                this.setState({
                    showIndicator: !this.state.showIndicator,
                    open: true,
                    message: "Vérifiez votre connexion internet"
                });
            });
    }


    handleSubmitLogin = event => {
        event.preventDefault();
        this.setState({
            showIndicator: true
        });

        let dataToSend = {
            email: this.state.email,
            password: this.state.password
        };

        dataToSend = JSON.stringify(dataToSend);

        // const data = new FormData(event.target);
        // var dataToSend = stringifyFormData(data);

        fetch(`${baseUrl}user/login`, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: dataToSend
        })
            .then(res => res.json())
            .then(res => {
                this.setState({
                    lastEmail: this.state.email
                });
                if (res.success === true) {
                    this.props.login(res.user, res.token)
                } else {
                    this.setState({
                        open: true,
                        message: res.message || "Vérifier vos paramètres de connexion",
                        email_not_verified: res.email_not_verified
                    });
                    // alert("Vérifier vos paramètres de connexion");
                }

            })
            .catch(err => {
                console.error("Error login: ", err);

                // alert("Veréifiez votre connexion internet");
                this.setState({
                    open: true,
                    message: "Vérifiez votre connexion internet",
                    email_not_verified: false
                });
            })
            .finally(() => {
                this.setState({
                    showIndicator: false
                });
            });
    };

    render() {
        const {classes} = this.props;
        return (
            <>
                {!!this.props.user?.token && !this.props.noRedirect ? (
                    <Redirect to={{pathname: "/dashboard"}}/>
                ) : null}

                <Container maxWidth="md">
                    <Grid container className={classes.root} justifyContent="center" spacing={0} alignItems={"center"}>
                        <Grid item xs={12}>
                            <Grid container className={classes.loginView} justifyContent={"center"} alignItems={"center"}>
                                <Grid md={5} item component={Link} to={'/'}>
                                    <img className={classes.img} src={logo} alt="logo"/>
                                </Grid>
                                <Grid md={7} item>
                                    <Box className={classes.formLogin}>
                                        <h1 style={{color: "#113a88"}}>Connexion</h1>
                                        <form onSubmit={this.handleSubmitLogin}>
                                            <div>
                                                <input
                                                    disabled={this.state.showIndicator}
                                                    type="text"
                                                    name="email"
                                                    className={classes.input}
                                                    autoComplete='username email'
                                                    placeholder="E-mail"
                                                    required
                                                    onChange={this.handleChange}
                                                />

                                                <div className='password-input'>
                                                    <input
                                                        disabled={this.state.showIndicator}
                                                        type={this.state.showPassword ? 'text' : 'password'}
                                                        name="password"
                                                        autoComplete='current-password'
                                                        placeholder="Mot de passe"
                                                        required
                                                        className={classes.input}
                                                        onChange={this.handleChange}
                                                    />
                                                    <button type='button' onClick={this.toggleShowPassword}>
                                                        {
                                                            this.state.showPassword ?
                                                                <VisibilityTwoTone/> :
                                                                <VisibilityOffTwoTone/>
                                                        }
                                                    </button>
                                                </div>

                                            </div>
                                            {this.state.showIndicator === true ? (
                                                <div style={{marginTop: 12}} className={"center-content"}>
                                                    <BeatLoader
                                                        css={override}
                                                        size={10}
                                                        color={"#116FBF"}
                                                    />
                                                </div>

                                            ) : null}

                                            <div className={classes.buttonContainer}>
                                                <Button disabled={this.state.showIndicator} label={"Continuer"}
                                                        className="p-button-raised"/>
                                            </div>

                                        </form>

                                        {
                                            this.state.email_not_verified ?
                                                <Alert severity="warning" color='warning' variant="outlined" action={
                                                    <Button2
                                                        type='buttom'
                                                        onClick={this.validerEmail}
                                                        color='inherit'
                                                        disabled={this.state.showIndicator}
                                                        variant={'outlined'}>
                                                        Renvoyer
                                                    </Button2>}>
                                                    <AlertTitle>Adresse Email non-verifié</AlertTitle>
                                                    Veuillez verifier la boîte d'acceuil de votre courrier electronique
                                                    pour les instruction de verification. <br/>
                                                    <small>N'oubliez pas la boite de SPAM</small> <br/>
                                                    <small>Nous pouvons vous les envoyer à nouveau le cas écéant</small>.

                                                </Alert>

                                                :
                                                null
                                        }

                                        <React.Fragment>
                                            <Dialog
                                                open={this.state.open}
                                                onClose={this.handleClose}>
                                                <DialogTitle>
                                                    <div className={"center-content"}
                                                         style={{justifyContent: "flex-start"}}>
                                                        <InfoOutlined color={"secondary"} style={{marginRight: 6}}/>
                                                        Informations
                                                    </div>
                                                </DialogTitle>
                                                <DialogContent>
                                                    <DialogContentText id="alert-dialog-description">
                                                        {this.state.message}
                                                    </DialogContentText>
                                                </DialogContent>
                                                <DialogActions>
                                                    <Button
                                                        onClick={this.handleClose}
                                                        color="primary"
                                                        label={"OK"}
                                                        autoFocus>
                                                    </Button>
                                                </DialogActions>
                                            </Dialog>
                                        </React.Fragment>
                                    </Box>
                                    <div className={classes.contentCreateAndForget}>
                                        <Link className={classes.forget} to="/register"
                                              style={{color: "#0969b3"}}><span className='redDot'>•</span>Nouveau
                                            Compte?</Link>
                                        <div className={classes.verticalSeparator}/>
                                        <Link className={classes.forget} to="/forget-password"
                                              style={{color: "#0969b3"}}>
                                            <span className='redDot'>•</span>Mot de passe oublié? </Link>
                                    </div>
                                    <Grid>
                                        <Grid item xs={12} container justifyContent={"center"} alignItems={"center"}>
                                            <p>
                                                MCI 2020 • <small>{AppVersion}</small>
                                            </p>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>

                    </Grid>
                </Container>

            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
        app: state.app
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        login: (user, token) => {
            dispatch(loginUser(user, token))
        },
        userBusy: (isBusy) => {
            dispatch(processingUser(isBusy))
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(useStyles)(Login));


