import React from "react";
import signature from "../assets/signature.png";
import {padToFour} from "../utils/funcs";


const BadgeBack = props => {
    const boxes = [];
    const year2 = props.created;
    for (let i = 1; i <= 4; i++) {
        boxes.push(<div key={i} className={year2 + i < props.year ? 'c-box past' : 'c-box'}>
            <div className="c-box-rect"/>
            <div className="c-title" style={{textAlign: 'center', fontWeight: 'normal'}}>
                <b style={{fontSize: '1.2em'}}>Exercice</b><br/>
                <span>{year2 + i}-{year2 + 1 + i}</span>
            </div>

        </div>)
    }
    return (
        <div className={props.zoom ? 'cip-card zoom back' : 'cip-card back'}>
            <div className=' cip_id'>{props.year}-{padToFour(props.id)}</div>
            <div className="backText">
                <p> Cette carte d’identité professionnelle (CIP) est valable pour une durée de 5 ans et doit être renouvelée à chaque exercice fiscal.</p>
                <p>Elle est valide uniquement si la vignette correspondante y est apposée.</p>
            </div>
            <div className="filler"/>
            <div className="renouvelement">
                <div className="c-title" style={{color: '#3E4095'}}>Renouvellement</div>
                <div className="c-boxes">
                    {boxes}
                </div>
            </div>
            <div className="authorise">
                <b>Authorisé par</b>
                <img src={signature} alt="🖼"/>
            </div>
        </div>
    )
}

export default BadgeBack;

