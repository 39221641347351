import {
    USER_BUSY, USER_GET_STATUS_ERROR, USER_GET_STATUS_SUCCES,
    USER_IDLE,
    USER_LOGIN,
    USER_LOGIN_ERROR,
    USER_LOGIN_SUCCESS,
    USER_LOGOUT,
    USER_LOGOUT_ERROR,
    USER_UPDATE
} from "./userAction";

const initialState = {
    user: {},
    token: '',
    busy: false,
    error: null,
    avatar: null,
    idle: false,
    userStatus: {},
    lastLogin: null
}

function userReducer(state = initialState, action) {
    switch (action.type) {
        case(USER_BUSY):
            return Object.assign({}, state,
                {
                    busy: action.payload.busy
                });
        case USER_LOGIN:
            return Object.assign({}, state,
                {
                    user: action.payload.user,
                    token: action.payload.token,
                    error: null,
                    idle: false
                });
        case USER_LOGIN_SUCCESS:
            return Object.assign({}, state,
                {
                    avatar: action.payload.avatar,
                    userStatus: {},
                    lastLogin: new Date()
                });
        case USER_LOGIN_ERROR:
            return Object.assign({}, state,
                {
                    error: action.payload.error,
                    avatar: action.payload.avatar
                });
        case USER_GET_STATUS_SUCCES:
            return Object.assign({}, state,
                {
                    userStatus: action.payload.userStatus || {}
                });
        case USER_GET_STATUS_ERROR:
            return Object.assign({}, state,
                {
                    error: action.payload.error,
                });
        case USER_LOGOUT_ERROR:
            return Object.assign({}, state,
                {
                    error: action.payload.error,
                });
        case USER_UPDATE:
            return Object.assign({}, state,
                {
                    user: {...state.user, ...action.payload.update},
                    token: action.payload.token || state.token
                });
        case USER_LOGOUT:
            return Object.assign({}, state, initialState);
        case USER_IDLE:
            return Object.assign({}, state,
                {
                    idle: action.payload.idle
                });
        default:
            return state;
    }
}

export default userReducer;

export const getToken = (state) => state.token;
