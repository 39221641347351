import * as storage from 'redux-storage';
import {applyMiddleware, combineReducers, compose, createStore} from "redux";
import userReducer, * as fromUser from "./userReducer";
import createEngine from 'redux-storage-engine-indexed-db';
import storageAwareReducer from "./storageAwareReducer";
import demandeReducer, * as fromDemande from "./demandeReducer";
import entrepriseReducer from "./entrepriseReducer";
import createSagaMiddleware from "@redux-saga/core";
import rootSaga from "./sagas";
import cipReducer from "./cipReducer";
import debounce from 'redux-storage-decorator-debounce'
import {CIP_LOAD} from "./cipAction";
import {ENTREPRISE_LOAD, ENTREPRISE_TYPE_LOAD} from "./entrepriseAction";
import categoryReducer from "./categoryReducer";
import {CATEGORY_LOAD} from "./categoryAction";
import specialityReducer from "./specialityReducer";
import {SPECIALITY_LOAD} from "./specialityAction";
import {DEMANDE_LOAD, DEMANDE_LOAD_ONE} from "./demandeAction";
import {createStateSyncMiddleware, initStateWithPrevTab, withReduxStateSync} from 'redux-state-sync'

const reducer = storage.reducer(
    withReduxStateSync(
        combineReducers({
                user: userReducer,
                app: storageAwareReducer,
                demandes: demandeReducer,
                entreprises: entrepriseReducer,
                cips: cipReducer,
                categories: categoryReducer,
                specialities: specialityReducer
            }
        )
    )
);
let engine = createEngine('mci-cip');
engine = debounce(engine, 800);
const middleware = storage.createMiddleware(
    engine,
    [CIP_LOAD, ENTREPRISE_LOAD, CATEGORY_LOAD, SPECIALITY_LOAD, ENTREPRISE_TYPE_LOAD, DEMANDE_LOAD, DEMANDE_LOAD_ONE],
    [],
    {disableDispatchSaveAction: false});

// create the saga middleware
const sagaMiddleware = createSagaMiddleware()

const composeEnhancers =
    typeof window === 'object' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
            // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
        }) : compose;


const syncMiddleware = createStateSyncMiddleware({});

const enhancer = composeEnhancers(
    applyMiddleware(sagaMiddleware),
    applyMiddleware(middleware),
    applyMiddleware(syncMiddleware)
);
const store = createStore(reducer, enhancer);
// const createStoreWithMiddleware = applyMiddleware(middleware)(createStore);
// const store = createStoreWithMiddleware(reducer);

initStateWithPrevTab(store);

const load = storage.createLoader(engine);

// then run the saga
sagaMiddleware.run(rootSaga);

load(store)
    // .then((newState) => console.log('Loaded state:', newState))
    .catch(() => console.log('Failed to load previous state'));

export default store;
export const getUserToken = (state) => fromUser.getToken(state.user);
export const getDemandeLastUpdate = (state) => fromDemande.getLastUpdate(state.demandes);

