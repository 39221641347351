import React, {Component} from "react";
import "../App.css";
import logo from "../assets/logo.png";
import {Link, Redirect} from "react-router-dom";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import {css} from "@emotion/react";
import {AppVersion, baseUrl} from "../utils/config";
import {BeatLoader} from "react-spinners";
import {Container, Grid, withStyles} from "@material-ui/core";
import CssBaseline from "@material-ui/core/CssBaseline";
import {Button} from 'primereact/button';
import {loginUser, processingUser} from "../redux/userAction";
import {connect} from "react-redux";
import Box from "@material-ui/core/Box";
import {processError} from "../utils/funcs";
import {VisibilityOffTwoTone, VisibilityTwoTone} from "@material-ui/icons";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;
const useStyles = theme => ({
    root: {
        minHeight: '100vh'
    },
    paper: {
        height: 140,
        width: 100,
    },
    img: {
        height: '360px',
        display: 'block'
    },
    loginView: {
        background: '#fff',
        boxShadow: '0 2px 5px #a98686',
        borderRadius: 5
    },
    formLogin: {
        padding: "24px",
        marginTop: 24,
        boxShadow: "none",
        borderLeft: "1px solid #007ad9",
        paddingLeft: 48
    },
    buttonContainer: {
        width: "100%",
        display: "flex",
        height: 70,
        justifyContent: "flex-end",
        alignItems: "center"
    },
    input: {
        width: "100%",
        height: "40px",
        paddingLeft: "8px",
        borderRadius: "3px",
        border: "1px solid #666666",
        outline: "none",
        marginTop: "5px",
    },
    contentCreateAndForget: {
        display: "flex",
        justifyContent: "space-around",
        alignItems: "center",
        margin: "12px"
    },
    verticalSeparator: {
        width: 1,
        height: 31,
        background: "#b6b6b6"
    },
    forget: {
        borderRadius: 50,
        background: "#b6dcff40",
        padding: "6px 20px",
        textAlign: "center",
        height: "max-content",
        fontWeight: 600
    }
});

class Register extends Component {
    constructor() {
        super();
        this.state = {
            full_name: null,
            firstname: null,
            lastname: null,
            email: null,
            password: null,
            showIndicator: false,
            open: false,
            message: null
        };
    }

    componentWillUnmount() {
        // fix Warning: Can't perform a React state update on an unmounted component
        this.setState = (state, callback) => {
            return;
        };
    }

    handleClose = () => {
        this.setState({
            open: false
        });
    };

    handleChange = event => {
        this.setState({[event.target.name]: event.target.value});
    };


    toggleShowPassword = () => {
        this.setState({showPassword: !this.state.showPassword});
    }

    handleSubmitRegister = event => {
        event.preventDefault();
        this.setState({
            showIndicator: true
        });

        let dataToSend = {
            full_name: this.state.firstname + ' ' + this.state.lastname,
            email: this.state.email,
            password: this.state.password,
            group_id: 2,
            phone: this.state.phone,
            sexe: this.state.sexe
        };

        // console.log(" Data to send ", dataToSend);

        dataToSend = JSON.stringify(dataToSend);

        fetch(`${baseUrl}user/register`, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: dataToSend
        })
            .then(res => res.json())
            .then(res => {
                if (res.success === true) {
                    this.props.login(res.user, res.token)
                } else {
                    this.setState({
                        showIndicator: false,
                        open: true,
                        message: processError(res) || "Enregistrement échoue"
                    });
                }
            })
            .catch(err => {
                console.error("Error login: ", err);
                this.setState({
                    showIndicator: !this.state.showIndicator,
                    open: true,
                    message: processError(err) || "Vérifiez votre connexion internet"
                });
            });
    };

    render() {
        const {classes} = this.props;
        return (

            <React.Fragment>
                {!!this.props.user?.token ? (
                    <Redirect to={{pathname: "/profile"}}/>
                ) : null}
                <CssBaseline/>
                <Container maxWidth="md">
                    <Grid container className={classes.root} justifyContent="center" spacing={0} alignItems={"center"}>
                        <Grid item xs={12}>
                            <Grid container className={classes.loginView} justifyContent={"center"} alignItems={"center"}>
                                <Grid lg={5} item component={Link} to={'/'}>
                                    <img className={classes.img} src={logo} alt="logo"/>
                                </Grid>
                                <Grid lg={7} item>
                                    <Box elevation={0} className={classes.formLogin}>
                                        <h1 style={{color: "#113a88"}}>Inscription</h1>
                                        <form onSubmit={this.handleSubmitRegister}>
                                            <div>
                                                <input
                                                    disabled={this.state.showIndicator}
                                                    className={classes.input}
                                                    type="text"
                                                    name="firstname"
                                                    placeholder="Votre prénom"
                                                    required
                                                    onChange={this.handleChange}
                                                />

                                                <input
                                                    disabled={this.state.showIndicator}
                                                    className={classes.input}
                                                    type="text"
                                                    name="lastname"
                                                    placeholder="Votre nom"
                                                    required
                                                    onChange={this.handleChange}
                                                />

                                                <input
                                                    disabled={this.state.showIndicator}
                                                    className={classes.input}
                                                    type="text"
                                                    name="email"
                                                    autoComplete={"email"}
                                                    placeholder="Votre e-mail"
                                                    required
                                                    onChange={this.handleChange}
                                                />

                                                <input
                                                    disabled={this.state.showIndicator}
                                                    className={classes.input}
                                                    type="tel"
                                                    name="phone"
                                                    placeholder="Numero de téléphone"
                                                    required
                                                    onChange={this.handleChange}
                                                />


                                                <select
                                                    disabled={this.state.showIndicator}
                                                    className={classes.input}
                                                    name="sexe" required onChange={this.handleChange}>
                                                    <option value=""> Choisissez votre sexe</option>
                                                    <option value="M"> M</option>
                                                    <option value="F"> F</option>
                                                </select>


                                                <div className='password-input'>
                                                    <input
                                                        disabled={this.state.showIndicator}
                                                        className={classes.input}
                                                        type={this.state.showPassword ? 'text' : 'password'}
                                                        name="password"
                                                        autoComplete={"new-password"}
                                                        placeholder="Mot de passe"
                                                        required
                                                        onChange={this.handleChange}
                                                    />
                                                    <button type='button' onClick={this.toggleShowPassword}>
                                                        {
                                                            this.state.showPassword ?
                                                                <VisibilityTwoTone/> :
                                                                <VisibilityOffTwoTone/>
                                                        }
                                                    </button>
                                                </div>

                                            </div>
                                            {this.state.showIndicator === true ? (
                                                <div style={{marginTop: 12}} className={"center-content"}>
                                                    <BeatLoader
                                                        css={override}
                                                        size={10}
                                                        color={"#116FBF"}
                                                    />
                                                </div>

                                            ) : null}

                                            <div className={classes.buttonContainer}>
                                                <Button disabled={this.state.showIndicator} label={"S'inscrire"}
                                                        className="p-button-raised"/>
                                            </div>


                                            <div>
                                                <p> Autres liens</p>
                                                <Link className={classes.forget} to="/login"
                                                      style={{color: "#4A90E2"}}>
                                                    <span className='redDot'>•</span>Se connecter
                                                </Link>
                                                <Link className={classes.forget} to='/' style={{color: "#4A90E2"}}>
                                                    <span className='redDot'>•</span>Acceuil
                                                </Link>
                                            </div>

                                        </form>
                                        <React.Fragment>
                                            <Dialog
                                                open={this.state.open}
                                                onClose={this.handleClose}
                                                aria-labelledby="alert-dialog-title"
                                                aria-describedby="alert-dialog-description">
                                                <DialogTitle id="alert-dialog-title">
                                                    {"Informations"}
                                                </DialogTitle>
                                                <DialogContent>
                                                    <DialogContentText id="alert-dialog-description">
                                                        {this.state.message}
                                                    </DialogContentText>
                                                </DialogContent>
                                                <DialogActions>
                                                    <Button
                                                        label='OK'
                                                        onClick={this.handleClose}
                                                        color="primary"
                                                        autoFocus/>
                                                </DialogActions>
                                            </Dialog>
                                        </React.Fragment>
                                    </Box>
                                    <Grid>
                                        <Grid item xs={12} container justifyContent={"center"} alignItems={"center"}>
                                            <p>
                                                MCI 2020 • <small>{AppVersion}</small>
                                            </p>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>


                    </Grid>
                </Container>

            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
        app: state.app
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        login: (user, token) => {
            dispatch(loginUser(user, token))
        },
        userBusy: (isBusy) => {
            dispatch(processingUser(isBusy))
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(useStyles)(Register));

