import React, { lazy, Suspense } from 'react';

const LazyDemande = lazy(() => import('./Demande'));

const Demande = props => (
  <Suspense fallback={null}>
    <LazyDemande {...props} />
  </Suspense>
);

export default Demande;
