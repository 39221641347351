import React, {Component} from "react";
import {Container, Grid, Paper, Typography, withStyles} from "@material-ui/core";
import logo from "../assets/logo-v.png";
import {Link} from "react-router-dom";

const useStyles = theme => ({
    root: {
        minHeight: '100vh',
        background: '#f3f3f3'
    },
    paper: {
        height: 500,
        maxWidth: "100%",
        padding: 24,
        textAlign: "center"
    },
    logo: {
        display: "block",
        width: 250,
    }
});

class NotFound extends Component {
    render() {
        const {classes} = this.props;
        return (
            <Container>
                <Link to={"/"}>
                    <img className={classes.logo} src={logo} alt="logo"/>
                </Link>
                <Grid container className={classes.root} direction={"column"}>


                    <Grid item>
                        <Paper elevation={3} className={classes.paper}>
                            <b>404 - PAGE NOT FOUND</b>
                            <Typography style={{color: "#022c81"}} variant="h2"
                                        gutterBottom>Contenue <br/> non-disponible</Typography>
                            <Typography variant="h4" gutterBottom>
                                Désolé , ce contenu n'est pas disponible.
                            </Typography>
                            <Link to={"/"}>Acceuil</Link>
                        </Paper>
                    </Grid>
                </Grid>
            </Container>

        );
    }
}

export default withStyles(useStyles)(NotFound);
