export const USER_LOGIN = '[USER] Login';
export const USER_LOGIN_SUCCESS = '[USER] Login success';
export const USER_LOGIN_ERROR = '[USER] Login error';
export const USER_LOGOUT = '[USER] logout';
export const USER_LOGOUT_SUCCESS = '[USER] logout success';
export const USER_LOGOUT_ERROR = '[USER] logout error';
export const USER_UPDATE = '[USER] update';
export const USER_BUSY = '[USER] busy';
export const USER_IDLE = '[USER] idle';
export const USER_GET_STATUS = '[USER] get  status';
export const USER_GET_STATUS_SUCCES = '[USER] get  status success';
export const USER_GET_STATUS_ERROR = '[USER] get  status error';

export function loginUser(user, token) {
    return {
        type: USER_LOGIN,
        payload: {
            user,
            token
        }
    }
}

export function getUserStatus() {
    return {
        type: USER_GET_STATUS
    }
}

export function logoutUser(token) {
    return {
        type: USER_LOGOUT,
        payload: {
            token
        }
    }
}

export function processingUser(busy) {
    return {
        type: USER_BUSY,
        payload: {
            busy
        }
    }
}

export function setUserIdlestatus(idle) {
    return {
        type: USER_IDLE,
        payload: {
            idle
        }
    }
}
