import React, {Component} from "react";
import "../App.css";
import {NavLink, Redirect} from "react-router-dom";
import {baseUrl} from "../utils/config";
import {BeatLoader} from "react-spinners";
import {css} from "@emotion/react";
import Container from "@material-ui/core/Container";
import {CardContent, Grid, withStyles} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import {Card} from "primereact/card";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";
import {red} from "@material-ui/core/colors";
import Snackbar from "@material-ui/core/Snackbar";
import {Alert, AlertTitle} from '@material-ui/lab';


const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

const useStyles = (theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 200,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    input: {
        width: "100%"
    },
    actions: {
        borderTop: "1px solid #afafaf",
        display: "flex",
        justifyContent: "flex-end"
    }
});

class FormUser extends Component {
    constructor(props) {
        super(props);
        this.state = {
            login: false,
            full_name: null,
            email: null,
            password: null,
            group_id: null,
            phone: null,
            userType: null,
            selectValue: "",
            showIndicator: false,
            redirect: null
        };
    }

    testAdmin = () => {
        if (localStorage.getItem("userData")) {
            let user = JSON.parse(localStorage.getItem("userData"));
            if (user.success === true && user.token) {
                user = JSON.parse(JSON.stringify(user));

                this.setState({
                    user: user,
                    login: true,
                    token: user.token,
                    userType: user.user.group.id
                });
            }
        }
    };

    UNSAFE_componentWillMount() {
        this.testAdmin();
    }

    handleChange = event => {
        this.setState({[event.target.name]: event.target.value});
    };

    handleSubmitRegister = event => {

        event.preventDefault();

        this.setState({
            showIndicator: true
        });

        // Var time = new Date();
        const dataToSend = {
            full_name: this.state.full_name,
            email: this.state.email,
            password: "MCI_PASS_WORD",
            group_id: parseInt(this.state.selectValue),
            phone: this.state.phone,
            sexe: "M"
        };

        // console.log("The data to send 's ", dataToSend);
        const stringData = JSON.stringify(dataToSend);

        fetch(`${baseUrl}user`, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "Bearer " + this.state.token
            },
            body: stringData
        })
            .then(res => res.json())
            .then(res => {
                // console.log("the res ", res);
                if (res.success === true) {
                    this.setState({
                        openSnackBarSuccess: true,
                        showIndicator: false
                    });

                    setTimeout(() => {
                        this.setState({
                            redirect: true
                        });
                    }, 1000)
                    // window.location.reload();
                } else {
                    console.error("Error add operator (1): ", res);
                    this.setState({
                        showIndicator: false,
                        openSnackBarError: true
                    });
                }

            })
            .catch(err => {
                this.setState({
                    showIndicator: false,
                    openSnackBarError: true
                });
                console.error("Error add operator: ", err);
            });
    };

    handleCloseAS = () => {
        this.setState({
            openSnackBarSuccess: false
        })
    }
    handleCloseAEss = () => {
        this.setState({
            openSnackBarError: false
        })
    }

    render() {
        const {classes} = this.props;
        if (this.state.redirect) {
            return <Redirect to="/operator"/>
        }
        return (
            <div>
                {this.state.userType !== 1 ? <Redirect to={{pathname: "/"}}/> : null}

                {this.state.login === false ? <Redirect to={{pathname: "/login"}}/> : null}


                <Container maxWidth={"md"}>
                    <Card elevation={3} style={{marginTop: 48, padding: 24}}>
                        {this.state.showIndicator === true ? (
                            <BeatLoader
                                css={override}
                                size={10}
                                color={"#116FBF"}
                            />
                        ) : null}

                        <form onSubmit={this.handleSubmitRegister}>

                            <CardContent>
                                <h2 style={{color: red["800"]}}>Formulaire</h2>
                                <div>
                                    <FormControl variant="outlined" required className={classes.formControl}>
                                        <InputLabel id="demo-simple-select-outlined-label">Type
                                            d'utilisateur</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-outlined-label"
                                            id="demo-simple-select-outlined"
                                            value={this.state.selectValue}
                                            onChange={event => {
                                                this.setState({
                                                    selectValue: event.target.value
                                                });
                                            }}
                                            label="Age">
                                            <MenuItem value="">
                                                <em>Choisissez le type</em>
                                            </MenuItem>
                                            <MenuItem value={3}>Operateur</MenuItem>
                                            <MenuItem value={4}>Superviseur</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>


                                <Grid container spacing={2}>
                                    <Grid item md={4}>
                                        <TextField type="text"
                                                   required
                                                   className={classes.input}
                                                   onChange={this.handleChange}
                                                   name="full_name" label="Nom complet" variant="outlined"/>
                                    </Grid>
                                    <Grid item md={4}>
                                        <TextField type="email"
                                                   required
                                                   className={classes.input}
                                                   onChange={this.handleChange}
                                                   name="email" label="E-mail" variant="outlined"/>
                                    </Grid>
                                    <Grid item md={4}>
                                        <TextField type="tel"
                                                   required
                                                   className={classes.input}
                                                   onChange={this.handleChange}
                                                   name="phone" label="Téléphone" variant="outlined"/>
                                    </Grid>
                                </Grid>
                            </CardContent>
                            <CardActions className={classes.actions}>
                                <Button variant="outlined" type="button" color="secondary"
                                        component={NavLink} to="/operator">
                                    Annuler
                                </Button>
                                <Button variant="contained" color="primary" type="submit">
                                    Crée compte
                                </Button>
                            </CardActions>

                        </form>
                    </Card>
                </Container>


                <Snackbar open={this.state.openSnackBarSuccess} autoHideDuration={6000} onClose={this.handleCloseAS}>

                    <Alert onClose={this.handleCloseAS} severity="success">
                        <AlertTitle>Success</AlertTitle>
                        L'utilisateur a été ajouté
                    </Alert>
                </Snackbar>
                <Snackbar open={this.state.openSnackBarError} autoHideDuration={6000} onClose={this.handleCloseAE}>
                    <Alert onClose={this.handleCloseAE} severity="error">
                        <AlertTitle>Error</AlertTitle>
                        L'utilisateur ne peut pas être ajouté
                    </Alert>
                </Snackbar>
            </div>
        );
    }
}

export default withStyles(useStyles)(FormUser);
