import React, {Component} from "react";
import "../App.css";
// armchart
import * as am4core from "@amcharts/amcharts4/core";

import am4themes_animated from "@amcharts/amcharts4/themes/animated";

import ListShowAskAdmin from "./list/ListAskAShowAdmin";
import MainChart from "./dashbord/MainChart";
import WeekChart from "./dashbord/WeekChart";
import {DateTime} from "luxon";
import {DatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import LuxonUtils from "@date-io/luxon";
import {ArrowRightAltTwoTone, RefreshRounded} from "@material-ui/icons";
import {Box, Container, IconButton, List, Paper} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import withStyles from "@material-ui/core/styles/withStyles";

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import {red} from "@material-ui/core/colors";
import {BeatLoader} from "react-spinners";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import Avatar from "@material-ui/core/Avatar";
import {simplifyNumber} from "../utils/funcs";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import {connect} from "react-redux";
import {loadCip} from "../redux/cipAction";
import {loadDemande} from "../redux/demandeAction";
import Button from "@material-ui/core/Button";
import {Link} from 'react-router-dom';
import { css } from '@emotion/react'

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

const useStyles = theme => ({
    header: {
        fontWeight: "bold",
        color: red["600"]
    },
    resp: {
        position: "relative",
        marginTop: 24,
        [theme.breakpoints.down('sm')]: {
            width: "95vw",
        },
        [theme.breakpoints.up('md')]: {
            width: "calc( 100vw - 270px ) ",
        },
        [theme.breakpoints.up('lg')]: {
            width: "calc( 100vw - 270px ) ",
        },
    },
    avatar: {
        color: "#0b1758",
        backgroundColor: "#ddf0ff",
        fontSize: "1rem",
        fontWeight: 700
    },
    title: {
        margin: theme.spacing(0, 0, 1),
        color: '#02204a'
    }
});


am4core.useTheme(am4themes_animated);

class Dashboard extends Component {
    constructor(props) {
        super(props);

        this.state = {
            user: null,
            userType: null,
            token: null,
            loading: true,
            selectedDate: DateTime.local(),
            rowPerPage: 10,
            page: 0
        };

        this.weekChart = React.createRef();
        this.annualChart = React.createRef();

    }

    componentDidMount() {
        this.init();
    }

    componentWillUnmount() {
        // fix Warning: Can't perform a React state update on an unmounted component
        this.setState = () => {
            return;
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.user?.token !== this.props.user?.token) {
            this.init();
        }
    }

    init = () => {
        const user = this.props.user;
        if (!!user?.token && !this.state.loaded) {
            this.setState({
                loaded: true,
                user: user,
                token: user.token,
                userType: user.user.group.id
            }, () => {
                if (!this.props.demandes?.demandes || !this.props.demandes?.lastVersion) {
                    this.loadDemande();
                }
                if (!this.props.cips?.cips) {
                    this.loadCIP();
                }

            })
        }
    }

    handleDateChange = (change) => {
        console.log(`YEAR ${change.year}`)
        this.setState({
            year: change
        })
    }


    handleChangePage = (event, newPage) => {
        this.setState({
            page: newPage
        })
    };

    handleChangeRowsPerPage = (event) => {
        this.setState({
            rowPerPage: +event.target.value,
            page: 0
        })
    };

    loadCIP() {
        // const token = this.state.token;

    }

    loadDemande = () => {
        const token = this.state.token;
        this.props.loadDemandes(token);
    };

    updateAnnualChart = () => {
        return this.annualChart.current.updateChart();
    }

    render() {
        const {classes, user} = this.props;
        // const userType = user?.user?.group?.id;
        const token = user.token;
        let {year, month} = DateTime.local();
        if (month >= 10) {
            year++;
        }
        const fiscalYear = new Date(new Date().setFullYear(year)); // Fiscal year
        const selectedFiscYear = this.state.year ? this.state.year.year : year;

        const role = user?.user?.group.id;
        const loadingDemandes = this.props.demandes?.busy || false;
        const modified = this.props.demandes?.lastVersion ?
            new DateTime.fromJSDate(this.props.demandes?.lastVersion)
                .setLocale('fr-FR')
                .toLocaleString(DateTime.DATETIME_MED)
            : '---';
        // const loadingCIP = this.props.cips?.busy || false;
        const demandes = this.props.demandes?.demandes || [];

        const demandesEncours = demandes.filter(
            el => ['posted',
                'pre-processing',
                'processed',
                'in-inspection',
                'out-to-inspection', 'waiting-approval',
                'in-printing-queue',
                'printing'].includes(el.status)
        );

        const demandesTraites = demandes.filter(
            el => ['cancel',
                'printed'].includes(el.status)
        );

        const demandesRejected = demandes.filter(
            el => ['rejected'].includes(el.status)
        ); // refuse

        return (
            <MuiPickersUtilsProvider utils={LuxonUtils}>
                <>
                    {
                        [1, 3, 4, 5].includes(role) ?
                            <div style={{marginBottom: "24px"}}>

                                <div className="chartBackgroud">
                                    <MainChart token={token} id={'mainChart'} year={this.state.year?.year} ref={this.annualChart}/>
                                    <div className="center-content" style={{marginTop: 12}}>
                                        <DatePicker
                                            views={["year"]}
                                            label="Choisir l'année fiscale"
                                            value={this.state.year}
                                            onChange={this.handleDateChange}
                                            animateYearScrolling
                                            initialFocusedDate={fiscalYear}
                                            inputVariant={"outlined"}
                                            maxDate={new Date()}
                                            minDate={'2000-06-06'}
                                            disableFuture={true}
                                        />
                                        <IconButton size="small" onClick={this.updateAnnualChart} color="primary">
                                            <RefreshRounded/>
                                        </IconButton>
                                    </div>
                                    <div className='center-content' style={{}}>
                                        <small>{`Octobre ${selectedFiscYear - 1} - Octobre ${selectedFiscYear}`}</small>
                                    </div>
                                </div>

                                <Container className={classes.resp}>
                                    <Grid container className="cpi_status" spacing={3} justifyContent={"center"}
                                          alignItems={"center"}>
                                        <Grid item md={6}>
                                            <WeekChart id={'weekChart'} token={token} ref={this.weekChart} year={this.state.year?.year}/>
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="h6" className={classes.title}>
                                                Demandes
                                            </Typography>
                                            <Paper elevation={3} className="stats-item">
                                                <List>
                                                    <ListItem divider>
                                                        <ListItemAvatar>
                                                            <Avatar className={classes.avatar}>
                                                                <small>{simplifyNumber(demandesEncours.length)}</small>
                                                            </Avatar>
                                                        </ListItemAvatar>
                                                        <ListItemText primary="En Cours"/>
                                                    </ListItem>
                                                    <ListItem divider>
                                                        <ListItemAvatar>
                                                            <Avatar className={classes.avatar}>
                                                                <small>{simplifyNumber(demandesTraites.length)}</small>
                                                            </Avatar>
                                                        </ListItemAvatar>
                                                        <ListItemText primary="Traités"/>
                                                    </ListItem>
                                                    <Divider/>
                                                    <ListItem>
                                                        <ListItemAvatar>
                                                            <Avatar className={classes.avatar}>
                                                                <small>{simplifyNumber(demandesRejected.length)}</small>
                                                            </Avatar>
                                                        </ListItemAvatar>
                                                        <ListItemText primary="Refusés"/>
                                                    </ListItem>
                                                </List>
                                            </Paper>
                                        </Grid>
                                    </Grid>
                                </Container>
                            </div> : null
                    }
                    {
                        role === 2 ?
                            <Container>
                                <b style={{
                                    marginBottom: 12,
                                    display: 'block',
                                    color: "#000",
                                    marginLeft: 12
                                }}>Demandes:</b>
                                <Grid container alignItems='center' justifyContent='center' spacing={3}>
                                    <Grid item md={4}>
                                        <Paper elevation={4} className='quick-numbers'>
                                            <div>En cours</div>
                                            <p>{simplifyNumber(demandesEncours.length)}</p>
                                        </Paper>
                                    </Grid>
                                    <Grid item md={4}>
                                        <Paper elevation={4} className='quick-numbers'>
                                            <div>Traités</div>
                                            <p>{simplifyNumber(demandesTraites.length)}</p>
                                        </Paper>
                                    </Grid>
                                    <Grid item md={4}>
                                        <Paper elevation={4} className='quick-numbers'>
                                            <div>Refusés</div>
                                            <p>{simplifyNumber(demandesRejected.length)}</p>
                                        </Paper>
                                    </Grid>
                                </Grid>
                            </Container> : null
                    }
                    {
                        [6, 7].includes(role) ?
                            <div className='center-content' style={{padding: 24}}>
                                <Button component={Link} to={'rapport'} variant='contained' color='primary'>List de
                                    CIP</Button>
                            </div> :
                            <Box style={{margin: 12, padding: 12}}>
                                <Typography variant="h6" className={classes.title}>
                                    Demandes Récentes
                                </Typography>

                                {loadingDemandes ? (
                                    <div className="center-content">
                                        <BeatLoader
                                            css={override}
                                            size={10}
                                            color={"#116FBF"}
                                        />
                                    </div>
                                ) : null}
                                <TableContainer component={Paper} elevation={4}
                                                style={{margin: 12, width: 'calc(100% - 26px)'}}>
                                    <Table className={classes.table} size="small">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell className={classes.header}>Dossier</TableCell>
                                                <TableCell className={classes.header}>Entreprise</TableCell>
                                                <TableCell className={classes.header} style={{width: 190}}>CIP</TableCell>
                                                <TableCell className={classes.header} style={{width: 180}}>Avancement</TableCell>
                                                <TableCell className={classes.header} style={{width: 145}}>Date</TableCell>
                                                <TableCell className={classes.header} style={{width: 90}}/>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <ListShowAskAdmin
                                                onClickView={this.onView}
                                                data={demandes.slice(this.state.page * 10, (this.state.page + 1) * 10)}
                                            />
                                        </TableBody>
                                    </Table>
                                </TableContainer>

                                <div style={{textAlign: 'right'}}>
                                    <small>Version: {modified}</small>
                                    <Button variant='contained' color='primary' style={{marginLeft: 12}} size='small'
                                            endIcon={<ArrowRightAltTwoTone/>} component={Link} to='list'>Voir
                                        plus</Button>
                                </div>

                            </Box>
                    }
                </>
            </MuiPickersUtilsProvider>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        demandes: state.demandes,
        cip: state.cips
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        loadDemandes: (token) => {
            dispatch(loadDemande(token));
        },
        loadCips: (token) => {
            dispatch(loadCip(token));
        },

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(useStyles)(Dashboard));

