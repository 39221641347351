import React, {Component} from "react";
import "../App.css";
import receipt_and_change from "../assets/receipt_and_change.png";
import security_checked from "../assets/security_checked.png";
import autorisation from "../assets/autorisation.png";
import picIdentity from "../assets/avatar.png";
import receipt from "../assets/receipt.png";
import patente from "../assets/patente.png";

import {Link} from "react-router-dom";
// import Menu from "./Menu";
// import TextField from "@material-ui/core/TextField";
import {baseUrl} from "../utils/config";
// import currentUser from "../utils/currentUser";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import LinearProgress from "@material-ui/core/LinearProgress";
import Tooltip from "@material-ui/core/Tooltip";
import {CheckCircle, Close, CloudUpload} from "@material-ui/icons";
import {blue, green, red} from "@material-ui/core/colors";
import {Container, Grid, Paper} from "@material-ui/core";

class FormSociety extends Component {
    constructor(props) {
        super(props);

        this.state = {
            token: null,
            user: null,
            userInfo: null,
            userType: null,
            open: false,
            loading: false,
            showModale: false,
            showSmallModale: false,
            showNationalite: false,

            // for images preview
            fileCertificatPatente: null,
            fileIdentite: null,
            fileRecuPatente: null,
            fileRecuCpi: null,
            filePermisSejour: null,
            filePermisAurisation: null,

            // For images file
            fileCertificatPatenteSend: null,
            fileIdentiteSend: null,
            fileRecuPatenteSend: null,
            fileRecuCpiSend: null,
            filePermisSejourSend: null,
            filePermisAurisationSend: null
        };
    }

    componentDidMount() {
        this.init();
    }

    componentWillUnmount() {
        // fix Warning: Can't perform a React state update on an unmounted component
        this.setState = (state, callback) => {
            return;
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.user?.token !== this.props.user?.token) {
            this.init();
        }
    }

    init = () => {
        const user = this.props.user;
        if (!!user?.token && !this.state.loaded) {
            this.setState({
                loaded: true,
                user: user,
                token: user.token,
                userType: user.user.group.id
            }, () => {
            })
        }
    }

    showNationaliteBox = () => {
        this.setState({
            showNationalite: true
        });
    };

    hideNationaliteBox = () => {
        this.setState({
            showNationalite: false
        });
    };

    closeDialogSmall = () => {
        this.setState({
            showSmallModale: false,
            showModale: false
        });
    };

    closeDialog = () => {
        this.setState({
            showModale: false
        });
    };

    showDialog = () => {
        this.setState({
            showModale: true
        });
    };

    showSamllDialog = () => {
        this.setState({
            showSmallModale: true
        });
    };

    handleClose = () => {
        this.setState({
            open: false
        });
    };

    handleFormCip = event => {
        event.preventDefault();

        if (
            this.state.fileCertificatPatenteSend == null ||
            this.state.fileIdentiteSend == null ||
            this.state.fileRecuPatenteSend == null ||
            this.state.fileRecuCpiSend == null
        ) {
            this.handleClose();
            alert(
                " Vous ne pouvez pas effectuer cette demande sans ajouter les documents demandés "
            );
        } else {
            const data = new FormData(event.target);
            this.setState({
                loading: true
            });
            var dataToSend = stringifyFormData(data);
            // convert it to jan object
            var objectData = JSON.parse(dataToSend);

            // add id for type entreprise not use this societe type
            objectData.type_entreprise_id = 2;
            dataToSend = JSON.stringify(objectData);
            // console.log("Your form sending", objectData);
            fetch(`${baseUrl}entreprise`, {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + this.state.token
                },
                body: dataToSend
            })
                .then(res => res.json())
                .then(res => {
                    // console.log("res", res);
                    if (res.success === true) {
                        this.sendFilesForEntreprise(res.entreprise.id);
                        // localStorage.setItem("currentWorkForm", JSON.stringify(res));
                    }
                })
                .catch(err => {
                });
        }
    };
    sendFilesForEntreprise = id_entreprise => {
        let {
            fileCertificatPatenteSend,
            fileIdentiteSend,
            fileRecuCpiSend,
            fileRecuPatenteSend,
            filePermisSejourSend,
            filePermisAurisationSend
        } = this.state;

        let fileSendArray = [
            {
                file: fileCertificatPatenteSend,
                type: "Certificat patente",
                entreprise_id: id_entreprise
            },
            {
                file: fileIdentiteSend,
                type: " Photo identité",
                entreprise_id: id_entreprise
            },
            {
                file: fileRecuCpiSend,
                type: "Récu cpi",
                entreprise_id: id_entreprise
            },
            {
                file: fileRecuPatenteSend,
                type: "Récu patente",
                entreprise_id: id_entreprise
            },
            {
                file: filePermisSejourSend,
                type: "Permis sejour",
                entreprise_id: id_entreprise
            },
            {
                file: filePermisAurisationSend,
                type: "Photo autorisation",
                entreprise_id: id_entreprise
            }
        ];

        fileSendArray.forEach(el => {
            var dataFile = new FormData();

            dataFile.append("file", el.file);
            dataFile.append("type", el.type);
            dataFile.append("entreprise_id", el.entreprise_id);

            // console.log(" See the file i sent", dataFile);

            fetch(`${baseUrl}dossier`, {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + this.state.token
                },
                body: dataFile
            })
                .then(res => res.json())
                .then(res => {
                    if (res.success === true) {
                        // this.showSamllDialog();
                        this.setState({
                            loading: false
                        });
                    }
                })
                .catch(err => {
                    console.error("Error login: ", err);
                });
        });
        this.showSamllDialog();
    };

    // Mes fonction for photos
    showFileUploadCertPatent = event => {
        this.setState({
            fileCertificatPatente: URL.createObjectURL(event.target.files[0]),
            fileCertificatPatenteSend: event.target.files[0]
        });
    };

    showFileUploadProfilePic = event => {
        this.setState({
            fileIdentite: URL.createObjectURL(event.target.files[0]),
            fileIdentiteSend: event.target.files[0]
        });
    };

    showFileUploadRecuPatente = event => {
        this.setState({
            fileRecuPatente: URL.createObjectURL(event.target.files[0]),
            fileRecuPatenteSend: event.target.files[0]
        });
    };

    showFileUploadRecuCip = event => {
        this.setState({
            fileRecuCpi: URL.createObjectURL(event.target.files[0]),
            fileRecuCpiSend: event.target.files[0]
        });
    };

    showFileUploadPermisSejour = event => {
        this.setState({
            filePermisSejour: URL.createObjectURL(event.target.files[0]),
            filePermisSejourSend: event.target.files[0]
        });
    };

    showFileUploadAutorisation = event => {
        this.setState({
            filePermisAurisation: URL.createObjectURL(event.target.files[0]),
            filePermisAurisationSend: event.target.files[0]
        });
    };

    render() {
        return (
            <div>
                {this.state.loading === true ? (
                    <React.Fragment>
                        <LinearProgress/>
                        <LinearProgress color="secondary"/>
                    </React.Fragment>
                ) : null}

                <Container>
                    <form onSubmit={this.handleFormCip}>

                        <Grid container justifyContent={"center"} alignItems={"center"} spacing={2}>>


                            {/* for Profile pic */}
                            {/* onClick={this.showDialog} */}

                            <Grid item>
                                <input
                                    type="file"
                                    style={{display: "none"}}
                                    onChange={this.showFileUploadProfilePic}
                                    ref={fileInputForProfilePic =>
                                        (this.fileInputForProfilePic = fileInputForProfilePic)
                                    }
                                />
                                <Paper elevation={3} className="imagesBox"
                                       onClick={() => this.fileInputForProfilePic.click()}>
                                    <div className="imageContent">
                                        <img
                                            alt="🖼"
                                            src={
                                                this.state.fileIdentite == null
                                                    ? picIdentity
                                                    : this.state.fileIdentite
                                            }
                                        />
                                        <div className="titleimages"> Photo D'identité</div>
                                    </div>

                                    <div className="telAndImag">
                                        <div className="televerser"> Televerser</div>
                                        <CloudUpload style={{fontSize: 22}}/>
                                    </div>
                                </Paper>

                            </Grid>
                            {/* End Profile pic */}

                            {/* For patente */}

                            <Grid item>
                                <input
                                    type="file"
                                    style={{display: "none"}}
                                    onChange={this.showFileUploadCertPatent}
                                    ref={fileInputForCertificat =>
                                        (this.fileInputForCertificat = fileInputForCertificat)
                                    }
                                />

                                <Paper elevation={3} className="imagesBox"
                                       onClick={() => this.fileInputForCertificat.click()}>
                                    <div
                                        className="imageContent"
                                    >
                                        <img
                                            alt="🖼"
                                            src={
                                                this.state.fileCertificatPatente == null
                                                    ? patente
                                                    : this.state.fileCertificatPatente
                                            }
                                        />
                                        <div className="titleimages"> Certificat de patente</div>
                                    </div>

                                    <div className="telAndImag">
                                        <div className="televerser"> Televerser</div>
                                        <CloudUpload style={{fontSize: 22}}/>
                                    </div>
                                </Paper>

                            </Grid>
                            {/* end patente */}

                            {/* Recu de caise patente  */}
                            <Grid item>
                                <Paper elevation={3} className="imagesBox"
                                       onClick={() => this.fileInputRecuPatente.click()}>
                                    <input
                                        type="file"
                                        style={{display: "none"}}
                                        onChange={this.showFileUploadRecuPatente}
                                        ref={fileInputRecuPatente =>
                                            (this.fileInputRecuPatente = fileInputRecuPatente)
                                        }
                                    />
                                    <div className="imageContent">
                                        <img
                                            alt="🖼"
                                            src={
                                                this.state.fileRecuPatente == null
                                                    ? receipt
                                                    : this.state.fileRecuPatente
                                            }
                                        />
                                        <div className="titleimages"> Reçu de caisse patente</div>
                                    </div>

                                    <div className="telAndImag">
                                        <div className="televerser"> Televerser</div>
                                        <CloudUpload style={{fontSize: 22}}/>
                                    </div>
                                </Paper>
                            </Grid>
                            {/* end recu caise patente */}

                            {/*  recu caise CIP */}
                            <Grid item>
                                <Paper elevation={3} className="imagesBox"
                                       onClick={() => this.fileInputRecuCpi.click()}>
                                    <input
                                        type="file"
                                        style={{display: "none"}}
                                        onChange={this.showFileUploadRecuCip}
                                        ref={fileInputRecuCpi =>
                                            (this.fileInputRecuCpi = fileInputRecuCpi)
                                        }
                                    />
                                    <div className="imageContent">
                                        <img
                                            alt="🖼"
                                            src={
                                                this.state.fileRecuCpi == null
                                                    ? receipt_and_change
                                                    : this.state.fileRecuCpi
                                            }
                                        />
                                        <div className="titleimages"> Reçu de caise de la CIP</div>
                                    </div>

                                    <div className="telAndImag">
                                        <div className="televerser"> Televerser</div>
                                        <CloudUpload style={{fontSize: 22}}/>
                                    </div>
                                </Paper>
                            </Grid>
                            {/* end recu caise CIP */}

                            {this.state.showNationalite === true ? (
                                <React.Fragment>
                                    <Grid item>
                                        <Paper className="imagesBox" onClick={() => this.fileInputPermisSejour.click()}>
                                            <input
                                                type="file"
                                                style={{display: "none"}}
                                                onChange={this.showFileUploadPermisSejour}
                                                ref={fileInputPermisSejour =>
                                                    (this.fileInputPermisSejour = fileInputPermisSejour)
                                                }
                                            />

                                            <div className="imageContent">
                                                <img
                                                    alt="🖼"
                                                    src={
                                                        this.state.filePermisSejour == null
                                                            ? security_checked
                                                            : this.state.filePermisSejour
                                                    }
                                                />
                                                <div className="titleimages">Permis de sejour</div>
                                            </div>

                                            <div className="telAndImag">
                                                <div className="televerser"> Televerser</div>
                                                <CloudUpload style={{fontSize: 22}}/>
                                            </div>
                                        </Paper>
                                    </Grid>
                                    <Grid item>
                                        <Paper className="imagesBox"
                                               onClick={() => this.fileInputForAurisation.click()}>
                                            <input
                                                type="file"
                                                style={{display: "none"}}
                                                onChange={this.showFileUploadAutorisation}
                                                ref={fileInputForAurisation =>
                                                    (this.fileInputForAurisation = fileInputForAurisation)
                                                }
                                            />

                                            <div className="imageContent">
                                                <img
                                                    alt="🖼"
                                                    src={
                                                        this.state.filePermisAurisation == null
                                                            ? autorisation
                                                            : this.state.filePermisAurisation
                                                    }
                                                />
                                                <div className="titleimages">Autorisation</div>
                                            </div>

                                            <div className="telAndImag">
                                                <div className="televerser"> Televerser</div>
                                                <CloudUpload style={{fontSize: 22}}/>
                                            </div>
                                        </Paper>
                                    </Grid>
                                </React.Fragment>
                            ) : null}
                        </Grid>

                        <Paper elevation={3} className="contentForm">
                            <div className="topFomulaire">
                                <input
                                    className="input2"
                                    type="text"
                                    name="no_enreg_autor"
                                    placeholder="No enregistrement "
                                />
                                <div className="date_enregistrementAanInput input2">
                                    <Tooltip title="Date enregistrement." placement="top-end">
                                        <input
                                            required
                                            type="date"
                                            name="date_enregistrement"
                                            placeholder="Date"
                                        />
                                    </Tooltip>
                                </div>
                            </div>
                            <Grid container>
                                <Grid item md={5}>
                                    <div className="form">
                                        <div className="typeForm">
                                            <div className="input">
                                                <input
                                                    required
                                                    type="text"
                                                    name="gerant_responsable"
                                                    placeholder="Nom et Prénom du gérant responsable"
                                                />
                                                <input
                                                    required
                                                    type="text"
                                                    name="raison_sociale"
                                                    placeholder="Raison Sociale"
                                                />
                                                <br/>
                                                <div className="date_enregistrement">
                                                    Date démarrage des activités
                                                </div>
                                                {/* <br /> */}
                                                <input
                                                    required
                                                    type="date"
                                                    name="date_demarrage"
                                                    placeholder="Date demarrage des activités"
                                                />
                                                <input
                                                    required
                                                    type="text"
                                                    name="nif"
                                                    placeholder="Numéro matricule Fiscal"
                                                />
                                                <input
                                                    required
                                                    type="number"
                                                    name="capital_social"
                                                    placeholder="Capitale sociale"
                                                />
                                                <input
                                                    required
                                                    type="number"
                                                    name="montant_initial_investi"
                                                    placeholder="Montant investissement initial"
                                                />
                                                <input
                                                    required
                                                    type="text"
                                                    name="no_patente"
                                                    placeholder="Patente #"
                                                />
                                                <input
                                                    required
                                                    type="text"
                                                    name="categorie"
                                                    placeholder="Catégorie "
                                                />
                                                <input
                                                    type="text"
                                                    name="specialite"
                                                    placeholder=" Spécialité "
                                                />
                                                <input type="text" name="nif" placeholder="Numéro CIP"/>
                                            </div>
                                        </div>
                                    </div>
                                </Grid>
                                <Grid item md={7}>
                                    <Grid container spacing={2}>
                                        <Grid item md={6} className="nationality">
                                            <label name="TypeDemande"> Type de Societe </label> <br/>
                                            <input type="radio" name="type_entreprise_id" value="2"/>
                                            Anonyme <br/>
                                            <input type="radio" name="type_entreprise_id" value="2"/>
                                            Nom Collectif <br/>
                                            <input type="radio" name="type_entreprise_id" value="2"/>
                                            Commandite Simple <br/>
                                            <input type="radio" name="type_entreprise_id" value="2"/>
                                            Commandite par Action
                                        </Grid>

                                        <Grid item md={6} className="nationality">
                                            <label name="TypeDemande"> Nationalité </label> <br/>
                                            <input
                                                type="radio"
                                                name="nationalite"
                                                value="Haitienne"
                                                onClick={this.hideNationaliteBox}
                                            />
                                            Haitienne <br/>
                                            <input
                                                type="radio"
                                                name="nationalite"
                                                onClick={this.showNationaliteBox}
                                                value="Etrangere"
                                            />
                                            Étrangère
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={2} alignItems={"center"}>
                                        <Grid md={6} item className="TypeFonctionnement">
                                            <input
                                                type="radio"
                                                name="autorisation_fonctionnement"
                                                defaultValue="Enregistrement"
                                            />
                                            Enregistrement <br/>
                                            <input
                                                type="radio"
                                                name="autorisation_fonctionnement"
                                                defaultValue="Autorisation de Fonctionnement"
                                            />
                                            Autorisation de Fonctionnement
                                        </Grid>
                                        <Grid md={6} item className="TypeFonctionnement">
                                            <div className="input">
                                                <div className="inputFonctionnement ">
                                                    <input
                                                        type="text"
                                                        name="no_register_or_fonctionnement"
                                                        placeholder=" No "
                                                    />{" "}
                                                    <br/>
                                                    <Tooltip title="Date" placement="right-end">
                                                        <input
                                                            type="date"
                                                            name="date_register_or_fonctionnement"
                                                            placeholder=" Date "
                                                        />
                                                    </Tooltip>
                                                </div>
                                            </div>

                                        </Grid>
                                    </Grid>
                                    <div className="input" style={{marginTop: 12}}>
                                        <div className="publication">
                                            <div className="titleAdress">
                                                <label>Journal a grand Tirage </label>
                                            </div>
                                            <div className="adressContent">
                                                <input
                                                    required
                                                    type="text"
                                                    name="titre_journal"
                                                    placeholder=" Titre Journal "
                                                />
                                                <input
                                                    required
                                                    type="text"
                                                    name="no_journal"
                                                    placeholder="  Numero journal "
                                                />
                                                <input
                                                    required
                                                    type="text"
                                                    name="no_moniteur"
                                                    placeholder=" Moniteur N0 "
                                                />

                                                <br/>
                                                <input
                                                    type="date"
                                                    required
                                                    name="date_journal"
                                                    placeholder="Date"
                                                />
                                            </div>
                                        </div>
                                        <div className="adresse">
                                            <div className="titleAdress">
                                                <label>Adresse </label>
                                            </div>

                                            <input
                                                type="text"
                                                required
                                                name="adresse"
                                                placeholder=" Rue "
                                            />

                                            <div className="adressContent">
                                                <input
                                                    required
                                                    type="text"
                                                    name="phone"
                                                    placeholder=" Téléphone  "
                                                />
                                                <input
                                                    className="smallInput"
                                                    type="text"
                                                    name="fax"
                                                    placeholder=" FAX "
                                                />
                                                <input
                                                    className="smallInput"
                                                    type="text"
                                                    name="po_box"
                                                    placeholder=" PO BOX "
                                                />
                                            </div>

                                            <input
                                                className="smallInput"
                                                type="text"
                                                name="commune"
                                                placeholder=" Commune "
                                            />

                                            <input
                                                className="smallInput"
                                                type="text"
                                                name="departement"
                                                placeholder=" Departement "
                                            />
                                        </div>
                                        <div className="boxSauvegade">
                                            <div className="cancel"> Annuler</div>
                                            <button className="accept">Sauvegarder</button>
                                        </div>
                                    </div>

                                </Grid>
                            </Grid>
                        </Paper>
                        {this.state.showModale === true ? (
                            <div className="modal">
                                <div className="contentModal">
                                    <div className="closeModale">
                                        <div className="iconClose">
                                            <Close style={{color: red[500], fontSize: 52}}
                                                   onClick={this.closeDialog}
                                            />
                                        </div>
                                    </div>

                                    <div className="titleModale">Choisir photo à televerser</div>

                                    <div className="box">
                                        <div className="iconDownload">
                                            <CloudUpload style={{color: blue[500], fontSize: 32}}/>
                                        </div>
                                        <div className="textinBox">.JPEG .PNG</div>
                                    </div>
                                    <div className="cancelAndAccept">
                                        <div className="cancel" onClick={this.closeDialog}>
                                            Annuler
                                        </div>
                                        <div className="accept" onClick={this.closeDialog}>
                                            Accepter
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : null}

                        <Dialog
                            open={this.state.open}
                            onClose={this.handleClose}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description">
                            <DialogTitle id="alert-dialog-title">{"Informations "}</DialogTitle>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                    Vous devez ajouter tous les documents.
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={this.handleClose} color="primary" autoFocus>
                                    OK
                                </Button>
                            </DialogActions>
                        </Dialog>
                        {this.state.showSmallModale === true ? (
                            <div className="modal">
                                <div className="contentModalSmall">
                                    <div className="closeModale">
                                        <div className="iconClose">
                                            <Close style={{color: red[500], fontSize: 32}}
                                                   onClick={this.closeDialogSmall}
                                            />
                                        </div>
                                    </div>
                                    <div className="contentSuccess">
                                        <div className="iconSuccess">
                                            <CheckCircle icon="check_circle" style={{color: green[500], fontSize: 52}}/>
                                        </div>
                                        <div className="titleModale">
                                            Demande Soumis avec success.
                                        </div>
                                    </div>
                                    <div className="cancelAndAccept">
                                        <Link to="/profile">
                                            <div className="accept" onClick={this.closeDialogSmall}>
                                                Continuer
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        ) : null}
                    </form>
                </Container>
            </div>
        );
    }
}

function stringifyFormData(fd) {
    const data = {};
    for (let key of fd.keys()) {
        data[key] = fd.get(key);
    }
    return JSON.stringify(data, null, 2);
}

export default FormSociety;
