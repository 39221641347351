import React, { Component } from "react";
import "../App.css";
import { Link, Redirect } from "react-router-dom";
import {baseUrl} from "../utils/config";
import ListCip from "../component/list/ListCip";
import CircularProgress from "@material-ui/core/CircularProgress";
import TablePagination from "@material-ui/core/TablePagination";
import {Bookmark} from "@material-ui/icons";

const centerLoading = {
  margin: "auto",
  width: "100%",
  marginTop: "20px",
  textAlign: "center"
};

class FindRapport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: null,
      login: null,
      token: null,
      userType: null,
      cipListes: [],
      cipListesResult: [],
      searchName: null,
      loading: true
    };
  }
  handleChangePage = (event, newPage) => {
    this.setState({
      setPage: newPage
    });
  };

  handleChangeRowsPerPage = event => {
    this.setState({
      setRowsPerPage: event.target.value
    });
  };
  UNSAFE_componentWillMount() {
    if (localStorage.getItem("userData")) {
      let user = JSON.parse(localStorage.getItem("userData"));
      if (user.success === true && user.token) {
        user = JSON.parse(JSON.stringify(user));

        this.setState({
          user: user,
          login: true,
          token: user.token,
          userType: user.user.group.id
        });
      }
      this.loadCIP(user.token);
    }
  }

  loadCIP(token) {
    const url = `${baseUrl}cip?relations=entreprise&amount=100000`;
    fetch(url, {
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: "Bearer " + token
      }
    })
      .then(res => res.json())
      .then(response => {
        console.log(" Response results", response.results);
        this.setState({
          loading: false,
          cipListes: JSON.parse(JSON.stringify(response.results)).reverse(),
          cipListesResult: JSON.parse(
            JSON.stringify(response.results)
          ).reverse()
        });
      })
      .catch(error => console.error("Error:", error));
  }

  handleSeach = event => {
    let value = event.target.value;

    this.setState({
      cipListes: this.state.cipListesResult.filter(el => {
        if (
          el.entreprise.nom_commercial != null ||
          el.entreprise.proprietaire !== null
        ) {
          return (
            el.entreprise.nom_commercial.includes(value) ||
            el.no_cip.includes(value) ||
            el.entreprise.proprietaire.includes(value)
          );
        } else {
          return (
            el.no_cip.includes(value) ||
            el.entreprise.raison_sociale.includes(value) ||
            el.entreprise.gerant_responsable.includes(value)
          );
        }
      })
    });
  };
  render() {
    return (
      <div>
        {this.state.userType !== 1 ? <Redirect to={{ pathname: "/" }} /> : null}
        {this.state.login !== true ? <Redirect to={{ pathname: "/login" }} /> : null}

        <div className="headerList">
          <div>Imprimer une CPI </div>
          <div className="plusIcon">
            <Link to="/print" target="_blank">
              <Bookmark style={{fontSize:32}}/>
            </Link>
          </div>
        </div>
        <div className="headerOptions">
          <div className="search">
            <input
              value={this.state.searchName}
              onChange={this.handleSeach}
              type="search"
              placeholder="Rechercher"
              className="search_box"
            />
          </div>
        </div>

        <div className="contentList">
          <div className="recentTable">
            <div className="heanderDemande">
              <div className="item"> Numero </div>
              <div className="item"> Proprietaire </div>
              <div className="item"> Nationalite </div>
              <div className="item"> Nom Commerciale </div>
              <div className="item"> Statut </div>
              <div className="item"> Date creation</div>
            </div>

            {this.state.loading === true ? (
              <div style={centerLoading}>
                <CircularProgress size={40} />
              </div>
            ) : (
              <ListCip data={this.state.cipListes} typeList="imprimer" />
            )}
          </div>
        </div>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={this.state.cipListes.length}
          rowsPerPage={this.state.rowsPerPage || 10}
          page={this.state.setPage ?? 0}
          backIconButtonProps={{
            "aria-label": "Previous Page"
          }}
          nextIconButtonProps={{
            "aria-label": "Next Page"
          }}
          onPageChange={this.handleChangePage}
          onRowsPerPageChange={this.handleChangeRowsPerPage}
        />
      </div>
    );
  }
}

export default FindRapport;
