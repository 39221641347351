import {CATEGORY_CLEAR_ALL, CATEGORY_LOAD, CATEGORY_LOADED_ERROR, CATEGORY_LOADED_SUCCESS} from "./categoryAction";

const initialState = {categories: [], busy: false, lastVersion: null};

function categoryReducer(state = initialState, action) {
    switch (action.type) {
        case CATEGORY_LOAD:
            return {
                ...state,
                busy: true
            };
        case CATEGORY_LOADED_SUCCESS:
            return {
                ...state,
                categories: action.payload.categories,
                busy: false,
                lastVersion: new Date()
            };
        case CATEGORY_LOADED_ERROR:
            return {
                ...state,
                error: action.payload.error,
                busy: false
            };
        case CATEGORY_CLEAR_ALL:
            return {...initialState};
        default:
            return state;
    }
}

export default categoryReducer;
