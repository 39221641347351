import {
    DEMANDE_ADD,
    DEMANDE_BUSY,
    DEMANDE_CLEAR,
    DEMANDE_CLEAR_ALL,
    DEMANDE_CLEAR_SELECTED,
    DEMANDE_LOAD,
    DEMANDE_LOAD_ONE,
    DEMANDE_LOAD_ONE_SUCCESS,
    DEMANDE_LOADED_ERROR,
    DEMANDE_LOADED_SUCCESS,
    DEMANDE_UPDATE,
    DEMANDE_UPDATE_SELECTED
} from "./demandeAction";
import uuid from 'react-uuid';
import {DateTime} from "luxon";
import cloneDeep from 'lodash/cloneDeep';
import merge from "lodash/merge";

const initialState = {
    applications: {},
    demandes: [],
    busy: false,
    currentApplication: '',
    currentDemande: {},
    lastVersion: null,
    lastUpdate: null
};

function demandeReducer(state = initialState, action) {
    switch (action.type) {
        case(DEMANDE_ADD):
            const id = action.payload.id || uuid();
            const now = DateTime.local().toString();
            return {
                ...state,
                applications: {
                    ...cloneDeep(state.applications),
                    [`${id}`]: {
                        data: {
                            ...cloneDeep(action.payload.data)
                        },
                        creationDate: now,
                        lastUpdated: now,
                        step: 1
                    }
                },
                currentApplication: id
            };
        case DEMANDE_UPDATE:
            const now1 = DateTime.local().toString();
            const data = state.applications[action.payload.id].data;
            return {
                ...state,
                applications: {
                    ...state.applications,
                    [`${action.payload.id}`]: {
                        ...state.applications[action.payload.id],
                        data: {...data, ...action.payload.update},
                        lastUpdated: now1,
                        step: action.payload.step
                    }
                },
                currentApplication: action.payload.id
            };

        case DEMANDE_UPDATE_SELECTED:
            return {
                ...state,
                currentDemande: {...merge(state.currentDemande, action.payload.update), lastLoaded: (new Date()).toISOString()}
            };

        case DEMANDE_CLEAR_SELECTED:
            return {
                ...state,
                currentDemande: {}
            };

        case DEMANDE_CLEAR:
            const {[`${action.payload.id}`]: selected, ...rest} = state.applications;
            return {
                ...state,
                applications: rest
            };

        case DEMANDE_CLEAR_ALL:
            return cloneDeep(initialState);

        case DEMANDE_BUSY:
            return {
                ...state,
                busy: action.payload.busy
            };
        case DEMANDE_LOAD:
            return {
                ...state,
                busy: true,
                error: null
            };
        case DEMANDE_LOAD_ONE:
            return {
                ...state,
                error: null,
                busy: true,
                currentDemande: {}
            };
        case DEMANDE_LOAD_ONE_SUCCESS:
            const newDemande = action.payload.demande;
            let demandes1 = state.demandes || [];

            demandes1 = demandes1.map(value => {
                if (newDemande?.id && value.id === newDemande.id) {
                    return newDemande;
                }
                return value
            })

            return {
                ...state,
                busy: false,
                demandes: demandes1,
                currentDemande: {...cloneDeep(newDemande), lastLoaded: (new Date()).toISOString()}
            };
        case DEMANDE_LOADED_SUCCESS:
            //Add new values  + update old element value
            let demandes = state.demandes || [];
            const newDemandes = cloneDeep(action.payload.demandes) || [];
            const newDemandesIds = newDemandes.map(d => d.id) || [];
            demandes = demandes.filter(d => !newDemandesIds.includes(d.id)) || [];
            demandes.unshift(...newDemandes);
            return {
                ...state,
                demandes: demandes,
                busy: false,
                lastUpdate: action.payload.lastUpdate, // serveur timestamp
                lastVersion:(new Date()).toISOString()
            };
        case DEMANDE_LOADED_ERROR:
            return {
                ...state,
                error: action.payload.error,
                busy: false,
            };
        default:
            return state;
    }
}

export default demandeReducer;
export const getLastUpdate = (state) => state.lastUpdate;
