import React, { lazy, Suspense } from 'react';

const LazyBusiness = lazy(() => import('./Business'));

const Business = props => (
  <Suspense fallback={null}>
    <LazyBusiness {...props} />
  </Suspense>
);

export default Business;
