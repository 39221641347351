import React, {Component} from "react";

import "../App.css";
import {LockRounded} from "@material-ui/icons";
import Paper from "@material-ui/core/Paper";
import withStyles from "@material-ui/core/styles/withStyles";
import {Button, Card, CardContent, Container, Grid, InputLabel, Select} from "@material-ui/core";
import CardHeader from "@material-ui/core/CardHeader";
import Avatar from "@material-ui/core/Avatar";
import AvatarEdit from 'react-avatar-edit'
import TextField from "@material-ui/core/TextField";
import {Link} from "react-router-dom";
import FormControl from "@material-ui/core/FormControl";
import {BeatLoader} from "react-spinners";
import {override, processError} from "../utils/funcs";
import {Api} from "../utils/api";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import {loginUser} from "../redux/userAction";
import {connect} from "react-redux";

const useStyles = (theme) => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
        display: "flex",
        alignItems: "center"
    },
    avatar: {
        display: "block",
        width: 120
    },
    contentProfile: {
        maxWidth: 700,
        display: "block",
        margin: "12px auto",
        padding: "24px"
    },
    formControl: {
        width: '100%'
    }
});

class Profile extends Component {

    setEditorRef;

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            loaded: false,
            preview: null,
            currentInput: null,
            open: false,
            form: {
                email: '',
                name: '',
                phone: '',
                sex: '',
                password: '',
                newPassword: '',
                confirmPassword: ''
            },
            editingProfilePicture: false
        };

    }

    componentDidMount() {
        this.init();
    }

    componentWillUnmount() {
        // fix Warning: Can't perform a React state update on an unmounted component
        this.setState = () => {
            return;
        };
    }

    init() {

        const user = this.props.user;

        if (!!user?.token && !this.state.loaded) {
            this.setState({
                loaded: true,
                form: {
                    ...this.state.form,
                    email: user.user?.email || '',
                    name: user.user?.full_name || '',
                    phone: user.user?.phone || '',
                    sex: user.user?.sexe || ''
                }
            }, () => {
                // this.loadCompany();
                // this.loadCIP();
            })
        }
    }

    handleFormChange = (field, event) => {
        this.setState({
            form: {
                ...this.state.form,
                [`${field}`]: event.target.value
            }
        })
    }

    modifierProfile = () => {
        this.setState({
            loading: true
        });

        const {email, ...form} = this.state.form
        Api.changeProfile(
            this.props.user.token, form)
            .then(res => {
                const {user, token} = res;
                this.props.loadProfile(user, token);
            })
            .catch(err => {
                this.setState({
                    open: true,
                    message: processError(err) || "Vérifiez votre connexion internet"
                });
            })
            .finally(() => {
                this.setState({
                    loading: false
                });
            })
    }

    handleClose = () => {
        this.setState({
            open: false,
            message: ''
        })
    }

    updateAvatar = () => {
        this.setState({
            editingProfilePicture: !this.state.editingProfilePicture
        })
    }

    onClose = () => {
        this.setState({preview: null})
    }

    onCrop = (preview) => {
        this.setState({preview})
    }

    onBeforeFileLoad = (elem) => {
        if (elem.target.files[0].size > 8000000) {
            alert(`File is too big!: ${elem.target.files[0].size}`);
            elem.target.value = "";
        }
        ;
    }

    uploadAvatar = async () => {

        this.setState({
            loading: true
        });

        const url = this.state.preview;

        const blob = await fetch(url)
            .then(res => res.blob())
            .then(blob => blob);

        Api.updateAvatar(this.props.user.token, blob)
            .then((res) => {
                const {user, token} = res;
                this.props.loadProfile(user, token);
            })
            .catch(err => {
                this.setState({
                    open: true,
                    message: processError(err) || "Vérifiez votre connexion internet"
                });
            })
            .finally(() => {
                this.setState({
                    loading: false,
                    editingProfilePicture: false
                });
            })
    }

    updatePasssword = () => {
        this.setState({
            loading: true
        });

        const {
            password,
            newPassword,
            confirmPassword,
        } = this.state.form

        Api.updatePassword(this.props.user.token, {
            old_password: password,
            new_password: newPassword,
            confirm_password: confirmPassword,
        })
            .then((res) => {
                const {user, token} = res;
                this.props.loadProfile(user, token);
                alert('Mot de passe modifié avec succès!');
                this.setState({
                    form: {
                        email: '',
                        name: '',
                        phone: '',
                        sex: '',
                        password: '',
                        newPassword: '',
                        confirmPassword: ''
                    }
                });
            })
            .catch(err => {
                this.setState({
                    open: true,
                    message: processError(err) || "Vérifiez votre connexion internet"
                });
            })
            .finally(() => {
                this.setState({
                    loading: false,
                    editingProfilePicture: false
                });
            })
    }

    render() {
        const {classes} = this.props;
        const user = this.props.user?.user;
        const avatar = this.props.user?.avatar;
        const form = this.state.form;
        const formChanged = user.full_name !== form.name || user.phone !== form.phone || user.sexe !== form.sex;
        const loading = this.state.loading;

        const Input = (props) => {
            const {icon, readOnly, name, autoComplete, ...rest} = props;
            const key = `form_${name}`;
            return <TextField
                {...rest}
                style={{width: '100%'}}
                key={key}
                size="small"
                onChange={(event) => this.handleFormChange(name, event)}
                InputProps={{
                    readOnly: readOnly ?? false,
                    autoComplete,
                }}
                autoFocus={this.state.currentInput === props.name}
                variant="outlined"/>
        }

        return (
            <Container>
                <Dialog
                    open={this.state.open}
                    onClose={this.handleClose}>
                    <DialogTitle>
                        {"Informations"}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText className="withNewLine">
                            {this.state.message}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={this.handleClose}
                            color="primary"
                            variant="contained"
                            autoFocus>Ok</Button>
                    </DialogActions>
                </Dialog>

                <Paper className={classes.contentProfile} elevation={3}>
                    <Grid container spacing={1} alignItems='center'>
                        <Grid item md={this.state.editingProfilePicture ? 12 : 6}>
                            <div className={"center-content"} style={{flexDirection: 'column'}}>
                                <Card elevation={4} style={{
                                    marginBottom: 12,
                                    backgroundColor: "#cbeaff",
                                    width: this.state.editingProfilePicture ? '100%' : 'auto'
                                }}>

                                    {
                                        this.state.editingProfilePicture ?
                                            <Grid container>
                                                <Grid item md={6}>
                                                    <AvatarEdit
                                                        width={250}
                                                        height={250}
                                                        cropRadius={0}
                                                        onCrop={this.onCrop}
                                                        onClose={this.onClose}
                                                        onBeforeFileLoad={this.onBeforeFileLoad}
                                                        src={avatar}
                                                    />
                                                </Grid>
                                                <Grid item md={6}>
                                                    <img style={{display: 'block'}} src={this.state.preview}
                                                         alt="Preview"/>
                                                </Grid>

                                            </Grid>
                                            :
                                            <img className={classes.avatar}
                                                 src={avatar} alt='avatar-preview'/>
                                    }
                                </Card>

                                {
                                    this.state.editingProfilePicture ?
                                        <div style={{
                                            display: 'flex',
                                            alignItem: 'center',
                                            minWidth: '50%',
                                            marginBottom: 12,
                                            justifyContent: 'space-around'
                                        }}>
                                            <Button disabled={loading} size={"small"} variant="contained"
                                                    onClick={this.uploadAvatar}>
                                                Sauvegarder</Button>
                                            <Button disabled={loading} size={"small"} variant="contained"
                                                    onClick={this.updateAvatar}>Annuler</Button>
                                        </div> :
                                        <Button disabled={loading} size={"small"} variant="contained"
                                                onClick={this.updateAvatar}>
                                            Modifier la photo</Button>
                                }

                            </div>
                        </Grid>
                        <Grid item container spacing={2} md={6}>
                            <Grid item md={12}>
                                <TextField
                                    label='Nom'
                                    key='profile-form-name'
                                    value={this.state.form.name}
                                    style={{width: '100%'}}
                                    name='name'
                                    size="small"
                                    onChange={(event) => this.handleFormChange('name', event)}
                                    InputProps={{
                                        autoComplete: 'name',
                                    }}
                                    variant="outlined"/>
                            </Grid>
                            <Grid item md={12}>
                                <Input readOnly={true} label="Email" value={this.state.form.email}/>
                            </Grid>
                            <Grid item md={12}>
                                <FormControl variant="outlined"
                                             size={'small'}
                                             className={classes.formControl}>
                                    <InputLabel>Sex</InputLabel>
                                    <Select
                                        native
                                        value={this.state.form.sex}
                                        onChange={(event) => this.handleFormChange('sex', event)}
                                        label="Age"
                                        inputProps={{
                                            name: 'sex'
                                        }}>
                                        <option aria-label="None" value=""/>
                                        <option value="M">Masculin</option>
                                        <option value="F">Féminin</option>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item md={12}>
                                <TextField
                                    label='Téléphone'
                                    key='profile-form-phone'
                                    value={this.state.form.phone}
                                    name='phone'
                                    style={{width: '100%'}}
                                    size="small"
                                    onChange={(event) => this.handleFormChange('phone', event)}
                                    InputProps={{
                                        autoComplete: 'phone',
                                    }}
                                    variant="outlined"/>
                            </Grid>
                            <Grid item md={12} style={{justifyContent: 'center', display: 'flex'}}>
                                {
                                    formChanged ?
                                        <Button
                                            size={"small"}
                                            variant="contained"
                                            disabled={this.state.loading}
                                            onClick={this.modifierProfile}>Modifier les information</Button> : null
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                    {
                        this.state.loading ? <div style={{marginTop: 12}} className={"center-content"}>
                                <BeatLoader
                                    css={override}
                                    size={10}
                                    color={"#116FBF"}
                                />
                            </div>
                            : null
                    }
                </Paper>

                <Card elevation={3} style={{marginBottom: 24}}>
                    <CardHeader
                        avatar={
                            <Avatar>
                                <LockRounded/>
                            </Avatar>
                        }
                        title="Sécurité"
                        subheader="Gestion des paramètres de sécurité de votre compte"
                    />
                    <CardContent>
                        <Grid container spacing={1} alignItems='center'>
                            <Grid item md={7}>
                                <div className={"center-content"} style={{flexDirection: 'column'}}>
                                    <h2>2FA</h2>
                                    <h4 style={{margin: "0 0 12px"}}>Authentification à double facteurs</h4>
                                    <div style={{
                                        maxWidth: 400,
                                        margin: '0 auto 24px',
                                        fontSize: '.8rem',
                                        lineHeight: '1rem'
                                    }}>
                                        L'authentification à double facteur est un processus de sécurité par lequel
                                        l'utilisateur fournit deux modes d'identification à partir de catégories de
                                        données distinctes : l'une se présente généralement sous la forme d'un jeton
                                        physique, comme une carte, et l'autre sous forme d'informations mémorisées,
                                        par exemple un code de sécurité.
                                    </div>
                                    <Button disabled={true} variant="contained">Activer</Button>
                                </div>
                            </Grid>
                            <Grid item container spacing={2} md={5}>
                                <Grid item md={12}>
                                    <h2>Changer le mot de passe</h2>
                                    <h4>Option 1 - Changement direct</h4>
                                </Grid>
                                <Grid item md={12}>
                                    <input name="username" type="text"
                                           readOnly
                                           value={this.props.user?.user?.email} style={{display: 'none'}} />
                                    <TextField
                                        label='Ancien Mot de passe'
                                        key='profile-form-password'
                                        value={this.state.form.password}
                                        type='password'
                                        name='password'
                                        style={{width: '100%'}}
                                        size="small"
                                        onChange={(event) => this.handleFormChange('password', event)}
                                        InputProps={{
                                            autoComplete: 'password',
                                        }}
                                        variant="outlined"/>
                                        <hr/>
                                </Grid>
                                <Grid item md={12}>
                                    <TextField
                                        label='Nouveau mot de passe'
                                        key='profile-form-new-password'
                                        type='password'
                                        value={this.state.form.newPassword}
                                        name='new-password'
                                        style={{width: '100%'}}
                                        size="small"
                                        onChange={(event) => this.handleFormChange('newPassword', event)}
                                        InputProps={{
                                            autoComplete: 'new-password',
                                        }}
                                        variant="outlined"/>
                                </Grid>
                                <Grid item md={12}>
                                    <TextField
                                        label='Confirmation'
                                        key='profile-form-confirm-password'
                                        value={this.state.form.confirmPassword}
                                        type='password'
                                        name='confirm-password'
                                        style={{width: '100%'}}
                                        size="small"
                                        onChange={(event) => this.handleFormChange('confirmPassword', event)}
                                        InputProps={{
                                            autoComplete: 'new-password',
                                        }}
                                        variant="outlined"/>

                                    <br/>
                                    <hr/>
                                    <Button variant="contained" onClick={this.updatePasssword}>Re-initialiser</Button>
                                </Grid>
                                <Grid item md={12}>
                                    <h4>Option 2 - Envoyer une demande de re-initialisation par email</h4>
                                    <div className='center-content'>
                                        <Button variant="contained" component={Link}
                                                to="/forget-password">Re-initialiser</Button>
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>

            </Container>
        );
    }

}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        loadProfile: (user, token) => {
            dispatch(loginUser(user, token));
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(useStyles)(Profile));
