import React, {useCallback, useRef, useState} from 'react';
import Grid from "@material-ui/core/Grid";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {Dialog, DialogActions, DialogContent, DialogTitle} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Slider from '@material-ui/core/Slider';
import Cropper from 'react-easy-crop';
import styles from './CropImageModal.module.css';
import {CloseRounded, InfoRounded, RotateLeftTwoTone, ZoomInTwoTone} from "@material-ui/icons";
import {getCroppedImg} from "../../utils/funcs";

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
}));


const CropImageModal = (props) => {

    const [crop, setCrop] = useState({x: 0, y: 0});
    const [zoom, setZoom] = useState(1);
    const [rotation, setRotation] = useState(0);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

    const onCropComplete = useCallback((croppedArea, croppedAreaPixels2) => {
        setCroppedAreaPixels(croppedAreaPixels2);
    }, [])

    const rootRef = useRef(null);
    const classes = useStyles();

    const handleClose = async () => {
        // ddd
        try {
            const croppedImage = await getCroppedImg(
                props.src,
                croppedAreaPixels,
                rotation
            )

            props.onClose(croppedImage)
        } catch (e) {
            props.onClose(null);
            console.error(e)
        }

    }


    return <div ref={rootRef}>
        <Dialog
            disablePortal
            disableEnforceFocus
            disableAutoFocus
            fullWidth={true}
            maxWidth={"lg"}
            className='no-padding'
            open={!!props.src}
            container={() => rootRef.current}>
            <DialogTitle>
                <span className={'center-content'} style={{justifyContent: 'space-between', width: '100%'}}>
                <span>Modifier l' image</span>
                    <CloseRounded onClick={handleClose}/>
                </span>
            </DialogTitle>

            <p className={'center-content'} style={{margin: '12px'}}>
                <InfoRounded/>
                Assurez vouz que la photo est bien centrée dans le cadre pour ne pas avoir de refus. Pour plus d'
                information voire la rubrique "Photo d'identification" plus bas.
            </p>

            <DialogContent>
                <div style={{position: 'relative', height: '60vh'}} className={styles['crop-container']}>
                    <Cropper
                        image={props.src}
                        crop={crop}
                        zoom={zoom}
                        rotation={rotation}
                        aspect={93 / 139}
                        onCropChange={setCrop}
                        onRotationChange={setRotation}
                        onCropComplete={onCropComplete}
                        onZoomChange={setZoom}
                    />
                </div>
                <Grid container spacing={0} style={{minHeight: 100}}>
                    <Grid item xs={4} style={{marginLeft: 24}}>
                        <div className={styles.controls}>
                            <ZoomInTwoTone style={{marginRight: 12}}/>
                            <Slider
                                value={zoom}
                                min={1}
                                max={3}
                                step={0.1}
                                aria-labelledby="Zoom"
                                onChange={(e, zoom2) => setZoom(zoom2)}
                                classes={{root: styles.slider}}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={4}>
                        <div className={styles.controls}>
                            <RotateLeftTwoTone style={{marginRight: 12, marginLeft: 12}}/>
                            <Slider
                                value={rotation}
                                min={0}
                                max={360}
                                step={1}
                                aria-labelledby="Rotation"
                                classes={{root: classes.slider}}
                                onChange={(e, rotation2) => setRotation(rotation2)}
                            />
                        </div>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button variant='contained' onClick={handleClose} color="primary">
                    Terminer
                </Button>
            </DialogActions>
        </Dialog>
    </div>

};

CropImageModal.propTypes = {};

CropImageModal.defaultProps = {};

export default CropImageModal;
