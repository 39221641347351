import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Redirect} from "react-router-dom";
import {logoutUser} from "../redux/userAction";
import {removeAllApplication} from "../redux/demandeAction";
import {removeAllEntreprise} from "../redux/entrepriseAction";
import {removeAllCip} from "../redux/cipAction";

const LogoutThenRedirect = (props) => {
    // const [params] = useState({});
    const user = useSelector(state => state.user);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(removeAllApplication());
        dispatch(removeAllEntreprise());
        dispatch(removeAllCip());
        dispatch(logoutUser(user?.token));
    }, [user, dispatch]);

    return (
        !user?.token ? (
            <Redirect to={{pathname: props.to}}/>
        ) : null
    )

}
LogoutThenRedirect.propTypes = {};

LogoutThenRedirect.defaultProps = {};

export default LogoutThenRedirect;
