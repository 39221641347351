import {CIP_CLEAR_ALL, CIP_LOAD, CIP_LOADED_ERROR, CIP_LOADED_SUCCESS} from "./cipAction";

const initialState = {cips: [], busy: false, lastVersion: null};

function cipReducer(state = initialState, action) {
    switch (action.type) {
        case CIP_LOAD:
            return {
                ...state,
                busy: true
            };
        case CIP_LOADED_SUCCESS:
            return {
                ...state,
                cips: action.payload.cips,
                busy: false,
                lastVersion: new Date()
            };
        case CIP_LOADED_ERROR:
            return {
                ...state,
                error: action.payload.error,
                busy: false
            };
        case CIP_CLEAR_ALL:
            return {...initialState};
        default:
            return state;
    }
}

export default cipReducer;
