import React, {Component} from "react";
import "../App.css";
import {CheckRounded, CloseRounded, PrintTwoTone, RedeemTwoTone, RefreshRounded} from "@material-ui/icons";
import Paper from '@material-ui/core/Paper';
import {DateTime} from "luxon";
import MaterialTable from "@material-table/core";
import {explainDemandeStatusLong, explainSemandeStatus, override} from "../utils/funcs";
import {TABLE_LOCALIZATION_FR} from "../utils/const";
import {Api} from "../utils/api";
import Pop from "./Pop";
import {Link} from "react-router-dom";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    IconButton,
    Radio,
    RadioGroup,
    TextField
} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import {Alert} from "@material-ui/lab";
import {BeatLoader} from "react-spinners";


const hdrCss = {
    background: '#f4f8ff',
    color: '#02204a',
    fontWeight: '500'
};

class Rapport extends Component {

    materialTableRef = React.createRef();

    constructor(props) {
        super(props);
        this.state = {
            user: null,
            token: null,
            userType: null,
            cipListes: [],
            fuse: null,
            loading: false,
            filterType: 0,
            searchWord: '',
            selectedStartDate: DateTime.local(),
            selectedEndDate: DateTime.local(),
            confirmDistributionModal: false,
            errorMessage: ''
        };
    }

    componentDidMount() {
        this.init();
    }

    componentWillUnmount() {
        // fix Warning: Can't perform a React state update on an unmounted component
        this.setState = (state, callback) => {
            return;
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.user?.token !== this.props.user?.token) {
            this.init();
        }
    }

    init = () => {
        const user = this.props.user;

        if (!!user?.token) {
            this.setState({
                loaded: true,
                user: user,
                token: user.token,
                userType: user.user.group.id
            })
        }
    }

    printAgain(data) {
        const {id, no_cip} = data;
        const accept = window.confirm(`Vous êtes sur le point d' envoyer la carte No. ${no_cip} pour re-impression. Voulez-vous continuer?`);

        if (accept) {
            this.setState({loading: true});
            Api.sendCardToPrint(id)
                .then(res => {
                    this.materialTableRef.current && this.materialTableRef.current.onQueryChange();
                })
                .catch(reason => {
                    alert(reason.message)
                })
                .finally(() => {
                    this.setState({loading: false});
                })
        }
    }

    handleClose(accept) {

        if (accept === true) {
            const nameInput = document.getElementById('accuse-reception-nom');
            const name = nameInput?.value;
            const noPieceInputType = document.querySelector('input[name="accuse-reception-type-carte"]:checked');
            const type = noPieceInputType?.value;
            const noPieceInput = document.getElementById('accuse-reception-carte')
            const no = noPieceInput?.value;
            let error;

            if (!name) {
                error = 'Il manque le nom complet';
            } else if (!type) {
                error = 'Il manque le type de pièce d\'identité';
            } else if (!no) {
                error = 'Il manque le numero  de la pièce d\'identité';
            }

            if (error) {
                this.setState({
                    errorMessage: error
                })
            } else {
                this.setState({
                    loading: true,
                    errorMessage: false
                })

                Api.markCardAsDelivered(this.state.confirmDistributionModal.id, {name, type, no})
                    .then(() => {
                        this.setState({
                            confirmDistributionModal: false,
                            loading: false
                        }, () => {
                            this.materialTableRef.current && this.materialTableRef.current.onQueryChange();
                        });
                    })
                    .catch((e) => {
                        this.setState({
                            errorMessage: e.message,
                            loading: false
                        })
                    })
            }

        } else {
            this.setState({
                errorMessage: false,
                confirmDistributionModal: false
            });
        }
    }

    delivery(data) {
        this.setState({
            confirmDistributionModal: data
        });
    }

    render() {

        const columns = [
            {
                title: 'No.', field: 'no_cip',
                render: (data) => <Link style={{color: '#000'}}
                                        to={`/list/${data?.demande_id}`}><b>{data.no_cip}</b></Link>
            },
            {
                title: 'Entreprise', field: 'company_name'
            },
            {
                title: "Catégorie", field: 'category_name'
            },
            {
                title: 'Spécialité', field: 'speciality_name',
            },

            {
                title: 'Status', field: 'demande_status',
                width: 150,
                cellStyle: {width: 150},
                render: ({demande_status}) => <Pop
                    description={explainDemandeStatusLong(demande_status)}><u style={{
                    maxWidth: 140,
                    display: "inline-block"
                }}><small>{explainSemandeStatus(demande_status)}</small></u></Pop>
            },
            {
                title: 'Date de création',
                field: 'created_at',
                render: ({created_at}) =>
                    <small>{DateTime.fromSQL(created_at).setLocale('fr-FR').toLocaleString(DateTime.DATETIME_MED)}</small>
            },
            {
                title: 'Mise à jour',
                field: 'updated_at',
                render: ({updated_at}) =>
                    <small>{DateTime.fromSQL(updated_at).setLocale('fr-FR').toLocaleString(DateTime.DATETIME_MED)}</small>
            }
        ];

        const selected = this.state.confirmDistributionModal;

        return (
            <>
                <Paper elevation={3} style={{margin: 6}} className='hide-print'>
                    <MaterialTable
                        tableRef={this.materialTableRef}
                        title=""
                        columns={columns}
                        localization={TABLE_LOCALIZATION_FR}
                        options={{
                            search: true,
                            debounceInterval: 800,
                            headerStyle: hdrCss,
                            searchFieldVariant: 'outlined',
                            actionsColumnIndex: -1
                        }}
                        data={
                            query =>
                                new Promise((resolve, reject) => {
                                    Api.loadCip(this.props.user?.token, query)
                                        .then(result => {
                                            const data = result.data || [];
                                            resolve({
                                                data: data.map(cip => {
                                                    return {
                                                        ...cip
                                                    }
                                                }),
                                                page: result.page - 1,
                                                totalCount: result.totalCount
                                            });
                                        })
                                        .catch(reason => {
                                            reject('⚠ Impossible d\'afficher la liste pour le moment. err2');
                                        })
                                })
                        }
                        actions={[
                            {
                                icon: () => <RefreshRounded/>,
                                tooltip: 'Actualiser la liste ',
                                isFreeAction: true,
                                onClick: () => this.materialTableRef.current && this.materialTableRef.current.onQueryChange()
                            }, (rowData) => {
                                return {
                                    icon: () => <PrintTwoTone/>,
                                    tooltip: 'Envoyer pour Impression',
                                    hidden: this.state.userType !== 1,
                                    disabled: !['printed', 'delivered'].includes(rowData?.demande_status),
                                    isFreeAction: false,
                                    onClick: (event, rowData2) => this.printAgain(rowData2)
                                }
                            }, (rowData) => {
                                return {
                                    icon: () => <RedeemTwoTone/>,
                                    tooltip: 'Livrer',
                                    hidden: this.state.userType !== 7 || rowData?.demande_status === '',
                                    disabled: !['printed'].includes(rowData?.demande_status),
                                    isFreeAction: false,
                                    onClick: (event, rowData2) => this.delivery(rowData2)
                                }
                            }
                        ]}/>


                </Paper>

                <Dialog
                    open={!!this.state.confirmDistributionModal}
                    onClose={() => this.handleClose(false)}
                >
                    <DialogTitle>
                        <div style={{display: 'flex', justifyContent: 'space-between', width: '100%'}}>
                            <span>Livrer la CIP</span>
                            <b style={{fontFamily: 'monospace', color: 'red'}}>{selected?.no_cip}</b>
                        </div>
                    </DialogTitle>
                    <DialogContent>

                        {
                            this.state.errorMessage ?
                                <Alert severity="error">{this.state.errorMessage}</Alert>
                                : null
                        }

                        {!!this.state.loading || this.state.showIndicator ? (
                            <div className="center-content" style={{width: 90}}>
                                <BeatLoader
                                    css={override}
                                    size={12}
                                    color={"#116FBF"}
                                />
                            </div>
                        ) : null}

                        <ul>
                            <li>Sujet : {selected?.company_name}</li>
                            <li>Catégory: {selected?.category_name}</li>
                            <li>Spetialité: {selected?.speciality_name}</li>
                        </ul>

                        <p>
                            Veuillez rentrer les informations concernant la personne qui est venue chercher la carte.
                        </p>


                        <TextField
                            autoFocus
                            variant='outlined'
                            label="Nom complet"
                            type="text"
                            id='accuse-reception-nom'
                            size='small'
                            margin="dense"
                            style={{marginBottom: 24}}
                            fullWidth
                        />

                        <FormControl component="fieldset">
                            <FormLabel>Tyoe de pièce d'identité </FormLabel>
                            <RadioGroup aria-label="gender" name="accuse-reception-type-carte"
                                        id='accuse-reception-type-carte'>
                                <FormControlLabel value="Permis de séjour" control={<Radio/>} label="Permis de séjour"/>
                                <FormControlLabel value="CIN" control={<Radio/>} label="CIN"/>
                                <FormControlLabel value="NIF" control={<Radio/>} label="NIF"/>
                                <FormControlLabel value="Passport" control={<Radio/>} label="Passport"/>
                            </RadioGroup>
                        </FormControl>

                        <TextField
                            style={{marginTop: 24}}
                            variant='outlined'
                            label="No. Pièce d'identité"
                            id='accuse-reception-carte'
                            type="text"
                            margin="dense"
                            size='small'
                            fullWidth
                        />

                    </DialogContent>
                    <DialogActions style={{display: 'flex', justifyContent: 'space-between'}}>
                        <IconButton disabled={this.state.loading} onClick={() => this.handleClose(false)}
                                    color="secondary">
                            <CloseRounded/>
                        </IconButton>
                        <IconButton disabled={this.state.loading} onClick={() => this.handleClose(true)}
                                    color="primary">
                            <CheckRounded/>
                        </IconButton>
                    </DialogActions>

                </Dialog>
            </>
        );
    }
}

export default Rapport;
