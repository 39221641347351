export const SOCIETIES = [
    {
        id: 1,
        name: "Entreprise Individuelle"
    },
    {
        id: 5,
        name: "Société Anonyme"
    },
    {
        id: 2,
        name: "Société en Nom Collectif"
    },
    {
        id: 3,
        name: "Société en Commandite Simple"
    },
    {
        id: 4,
        name: "Société en Commandite par Action"
    },
];

export const STATUS_LIST = ['posted', 'pre-processing', 'out-to-inspection', 'processed', 'in-inspection', 'rejected', 'waiting-approval', 'in-printing-queue', 'printing', 'printed', 'cancel', 'delivered','waiting-payment'];

export const TABLE_LOCALIZATION_FR = {
    body: {
        emptyDataSourceMessage: "Pas d'enregistrement à afficher",
        addTooltip: 'Ajouter',
        deleteTooltip: 'Supprimer',
        editTooltip: 'Editer',
        filterRow: {
            filterTooltip: 'Filtrer'
        },
        editRow: {
            deleteText: 'Voulez-vous désactiver cette ligne?',
            cancelTooltip: 'Annuler',
            saveTooltip: 'Enregistrer'
        }
    },
    grouping: {
        placeholder: "Tirer l'entête ...",
        groupedBy: 'Grouper par:'
    },
    header: {
        actions: 'Actions'
    },
    pagination: {
        labelDisplayedRows: '{from}-{to} de {count}',
        labelRowsSelect: 'lignes',
        labelRowsPerPage: 'lignes par page:',
        firstAriaLabel: 'Première page',
        firstTooltip: 'Première page',
        previousAriaLabel: 'Page précédente',
        previousTooltip: 'Page précédente',
        nextAriaLabel: 'Page suivante',
        nextTooltip: 'Page suivante',
        lastAriaLabel: 'Dernière page',
        lastTooltip: 'Dernière page'
    },
    toolbar: {
        addRemoveColumns: 'Ajouter ou supprimer des colonnes',
        nRowsSelected: '{0} ligne(s) sélectionée(s)',
        showColumnsTitle: 'Voir les colonnes',
        showColumnsAriaLabel: 'Voir les colonnes',
        exportTitle: 'Exporter',
        exportAriaLabel: 'Exporter',
        exportName: 'Exporter en CSV',
        searchTooltip: 'Chercher',
        searchPlaceholder: 'Chercher'
    }
}
