import React, {useState} from "react";
import {Popover} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {InfoTwoTone} from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
    popover: {
        pointerEvents: 'none',
    },
    paper: {
        padding: theme.spacing(1),
    },
}));

const Pop = props => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);

    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    return <div style={{cursor: 'help'}}>
        <div style={{display: 'inline-block'}}
             aria-owns={open ? 'mouse-over-popover' : undefined}
             aria-haspopup="true"
             onMouseEnter={handlePopoverOpen}
             onMouseLeave={handlePopoverClose}
        >
            {props.children}
            <InfoTwoTone  color='primary' style={{verticalAlign: 'middle', marginLeft: 6, width: 20}}/>
        </div>
        <Popover
            className={classes.popover}
            classes={{
                paper: classes.paper,
            }}
            PaperProps={{
                style: {width: '100%', maxWidth: 200},
            }}
            open={open}
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            onClose={handlePopoverClose}
            disableRestoreFocus>{props.description}</Popover>
    </div>
}

export default Pop;
