import React, {Component} from "react";
import "../../App.css";
import {css} from "@emotion/react";
import {BeatLoader} from "react-spinners";
import withStyles from "@material-ui/core/styles/withStyles";
import {settings} from "../../utils/settings";
import {IconButton, Paper} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import red from "@material-ui/core/colors/red";
import pattern from "patternomaly";
import {RefreshRounded} from "@material-ui/icons";
import { Line as LineChart} from 'react-chartjs-2';

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

const useStyles = theme => ({
    graph: {
        position: "relative",
        [theme.breakpoints.down('sm')]: {
            width: "95vw",
        },
        [theme.breakpoints.up('md')]: {
            width: "100%",
        },
        [theme.breakpoints.up('lg')]: {
            width: "100%",
        },
    }
});

// armchart
// const LineChart = require("react-chartjs-2").Line;

// const gray = "rgba(225, 255, 255, 0.2)";
// const blue = "rgba(76, 244, 104, 0.49)";

const chartOptionsMini = {
    ///Boolean - Whether grid lines are shown across the chart
    scaleShowGridLines: true,

    //String - Colour of the grid lines
    scaleGridLineColor: "rgba(255,255,2555,.2)",

    //Number - Width of the grid lines
    scaleGridLineWidth: 1,

    //Boolean - Whether to show horizontal lines (except X axis)
    scaleShowHorizontalLines: true,

    //Boolean - Whether to show vertical lines (except Y axis)
    scaleShowVerticalLines: false,

    //Boolean - Whether the line is curved between points
    bezierCurve: true,

    //Number - Tension of the bezier curve between points
    bezierCurveTension: 0.4,

    //Boolean - Whether to show a dot for each point
    pointDot: true,

    //Number - Radius of each point dot in pixels
    pointDotRadius: 2,

    //Number - Pixel width of point dot stroke
    pointDotStrokeWidth: 2,

    //Number - amount extra to add to the radius to cater for hit detection outside the drawn point
    pointHitDetectionRadius: 2,

    //Boolean - Whether to show a stroke for datasets
    datasetStroke: true,

    //Number - Pixel width of dataset stroke
    datasetStrokeWidth: 2,

    //Boolean - Whether to fill the dataset with a colour
    datasetFill: true,

    //Boolean - Whether to horizontally center the label and point dot inside the grid
    offsetGridLines: false,
    responsive: true,
    maintainAspectRatio: false,
    layout: {padding: 0},
    scales: {
        yAxes: [{
            ticks: {
                precision: 0
            }
        }]
    },
};


class WeekChart extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showIndicator: null,
            entreprisetype: 0,
            chartDataMin: {
                labels: ["Lundi", "Mardi", "Mercedi", "Jeudi", "Vendredi", "Samedi", "Dimanche"],
                datasets: []
            }
        };
    }

    componentDidMount() {
        this.filterStat();
    }

    handleOnChange = event => {
        this.setState({entreprisetype: event.target.value}, this.filterStat);
    };

    filterStat = () => {
        this.setState({
            showIndicator: true
        });
        let year = this.props.year || new Date().getFullYear();
        let urlStat = `${settings.api}/api/v1/entreprise/analytics/per-week/${year}/${
            this.state.entreprisetype
        }`;

        fetch(urlStat, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json; charset=utf-8",
                Authorization: "Bearer " + this.props.token
            }
        })
            .then(res => res.json())
            .then(response => {
                const data = response.data;

                // 0 = Monday, 1 = Tuesday, 2 = Wednesday, 3 = Thursday, 4 = Friday, 5 = Saturday, 6 = Sunday.
                const donnees = [0, 0, 0, 0, 0, 0, 0];

                for (const dt of data) {
                    let index = parseInt(dt.Day, 10);
                    donnees[index] = dt.data
                }

                const datasets = [
                    {
                        label: `Enregistrement par jour`,
                        borderColor: "#0968d9",
                        pointRadius: 4,
                        pointBorderColor: red["500"],
                        pointBackgroundColor: "#ffffff",
                        backgroundColor: pattern.draw('dot', 'rgb(55,166,255, .3)', "rgb(55,166,255, .7)"),
                        data: donnees
                    }
                ];

                // Return resObj;
                this.setState({
                    chartDataMin: {
                        ...this.state.chartDataMin,
                        datasets
                    }
                });
            })
            .catch(error => console.error("Error:", error))
            .finally(() => {
                this.setState({
                    showIndicator: false
                });
            });
    };

    render() {
        const {classes} = this.props;
        // let type_entreprise_id = this.state.entreprisetype;
        return (

            <Paper elevation={3} className="itemWeek">
                {this.state.showIndicator === true ? (
                    <div className="centerLoading" style={{height: 200}}>
                        <BeatLoader
                            css={override}
                            size={10}
                            color={"#116FBF"}
                        />
                    </div>
                ) : <div className={classes.graph}>
                    {!this.state.chartDataMin ? <div className="center-content">
                        <h3 style={{color: '#888888'}}>Aucune données...</h3>
                    </div> : (
                        <LineChart
                            data={this.state.chartDataMin}
                            id={'weekChart-1'}
                            options={chartOptionsMini}
                            width={300}
                            height={200}
                        />
                    )}
                </div>}
                <div className="center-content" style={{marginTop: 12}}>
                    <FormControl variant="outlined" size="small">
                        <InputLabel>Type d'entreprise</InputLabel>
                        <Select
                            native
                            label="Type d'entreprise"
                            value={this.state.entreprisetype} size="small"
                            onChange={this.handleOnChange}>
                            <option value="0"> Tous</option>
                            <option value="1"> Entreprise Individuelle</option>
                            <option value="2"> Société Anonyme</option>
                            <option value="3"> Société en Nom Collectif</option>
                            <option value="4"> Société en Commandite Simple</option>
                            <option value="5"> Société en Commandite par Action</option>
                        </Select>
                    </FormControl>
                    <IconButton size="small" onClick={this.filterStat} color="primary">
                        <RefreshRounded/>
                    </IconButton>
                </div>

            </Paper>
        );
    }
}

export default withStyles(useStyles)(WeekChart);
