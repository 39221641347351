import React, { lazy, Suspense } from 'react';

const LazyNewPersonalBusiness = lazy(() => import('./NewPersonalBusiness'));

const NewPersonalBusiness = props => (
  <Suspense fallback={null}>
    <LazyNewPersonalBusiness {...props} />
  </Suspense>
);

export default NewPersonalBusiness;
