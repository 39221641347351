import React, { lazy, Suspense } from 'react';

const LazyPrintingList = lazy(() => import('./PrintingList'));

const PrintingList = props => (
  <Suspense fallback={null}>
    <LazyPrintingList {...props} />
  </Suspense>
);

export default PrintingList;
