import {
    ENTREPRISE_CLEAR_ALL,
    ENTREPRISE_LOAD,
    ENTREPRISE_LOADED_ERROR,
    ENTREPRISE_LOADED_SUCCESS,
    ENTREPRISE_TYPE_LOAD,
    ENTREPRISE_TYPE_LOADED_ERROR,
    ENTREPRISE_TYPE_LOADED_SUCCESS
} from "./entrepriseAction";

const initialState = {entreprises: [], busy: false, busy2: false, types: {}, lastVersion: null, lastVersion2: null, error: null};

function entrepriseReducer(state = initialState, action) {
    switch (action.type) {
        case ENTREPRISE_LOAD:
            return {
                ...state,
                busy: true
            };
        case ENTREPRISE_LOADED_SUCCESS:
            return {
                ...state,
                entreprises: action.payload.entreprises,
                busy: false,
                lastVersion: new Date()
            };
        case ENTREPRISE_LOADED_ERROR:
            return {
                ...state,
                error: action.payload.error,
                busy: false
            };
        case ENTREPRISE_TYPE_LOAD:
            return {
                ...state,
                busy2: true
            };
        case ENTREPRISE_TYPE_LOADED_SUCCESS:
            return {
                ...state,
                types: action.payload.types,
                busy2: false,
                lastVersion2: new Date()
            };
        case ENTREPRISE_TYPE_LOADED_ERROR:
            return {
                ...state,
                error: action.payload.error,
                busy2: false
            };
        case ENTREPRISE_CLEAR_ALL:
            return {...initialState};
        default:
            return state;
    }
}

export default entrepriseReducer;
export const getDossierTypes = (state) => state.types.dossier || [];
