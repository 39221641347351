import {Grid, Paper} from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import {convertBlobToBase64, isFileValid, trimFileName} from "../utils/funcs";
import {
    AttachFileRounded,
    CheckCircleRounded,
    Close,
    CloudDone,
    CloudDownloadRounded,
    VisibilityRounded
} from "@material-ui/icons";
import CardActions from "@material-ui/core/CardActions";
import React, {useEffect, useState} from 'react';
import {Document, Page, pdfjs} from 'react-pdf';
import {saveAs} from 'file-saver';
import picIdentity from "../assets/avatar.svg";
import patente from "../assets/patente.svg";
import receipt from "../assets/receipt.svg";
import receipt_and_change from "../assets/recut.svg";
import security_checked from "../assets/permis.svg";
import authorisation from "../assets/cerificate.svg";
import badge from "../assets/badge.svg";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";
import Alert from "@material-ui/lab/Alert";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Slide from "@material-ui/core/Slide";
import Pagination from "@material-ui/lab/Pagination";
import Snackbar from "@material-ui/core/Snackbar";
import {Api} from "../utils/api";
import LinearProgress from "@material-ui/core/LinearProgress";
import CropImageModal from "./CropImageModal/CropImageModal";
import { createTheme } from '@material-ui/core/styles';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
export const DOCUMENTS = [
    {
        id: 1,
        image: picIdentity,
    },
    {
        id: 2,
        image: patente
    },
    {
        id: 3,
        image: receipt
    },
    {
        id: 4,
        image: receipt_and_change
    },
    {
        id: 6,
        image: security_checked
    },
    {
        id: 26,
        image: badge
    }
];

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        width: '100%',
        border: '1px solid #3f51b5'
    },
    details: {
        display: 'flex',
        width: '70%',
        flexDirection: 'column',
        minHeight: '100px'
    },
    content: {
        flex: '1 1 auto',
        paddingBottom: "12px !important"
    },
    contentSmall: {
        flex: '1 1 auto',
        padding: "6px !important"
    },
    cover: {
        flex: '1 1',
    },
    controls: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'center'
    },
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    bigImage: {
        width: '100%',
        display: 'block',
        height: 'auto',
        border: '6px solid #fff',
        boxShadow: '-1px -1px 2px #000',
        borderRadius: 2
    },
    presentation: {
        maxWidth: 1200,
        margin: '0 auto',
        background: '#191717',
        color: '#dedede',
        height: '100%'
    },
    pHolder: {
        background: '#191717',
        minHeight: 'calc(100% - 65px)'
    },
    button: {
        width: `80%`
    }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
const darkTheme = createTheme({
    palette: {
        type: 'light',
    }
});

export function FilePicker(props) {
    useEffect(() => {
        return () => console.log("Removing FIlePicker for: ", props.dossier.type);
    }, [props.dossier.type]);
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [hasError, setHasError] = useState(false);
    const [open, setOpen] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [imageError, setImageError] = React.useState(false);
    const [imageToCrop, setImageToCrop] = React.useState('');
    const [file, setfileF] = React.useState(props.dossier?.file);

    let input;
    const {canEdit, dossier, token} = props;
    let name = dossier.file?.name ? dossier.file.name : dossier.path;

    let image = dossier.image || authorisation;

    let isImage = (file && file.type?.split('/')[0] === 'image');

    let isPDF = false;
    const Pdf = (prp) => {
        const {
            width = props.displayMode === 'module' ? 250 : 127,
            height = props.displayMode === 'module' ? 200 : 112
        } = {prp};
        return (
            <div style={{height, overflow: prp.allowOverflow ? 'visible' : 'hidden'}}
                 className={props.displayMode === 'module' ? 'imageContent-img' : classes.cover}>
                <Document
                    renderMode='canvas'
                    loading="Générer aperçu"
                    file={URL.createObjectURL(file)}
                    onLoadError={handleImageErrored}
                    onLoadSuccess={onDocumentLoadSuccess}>
                    <Page pageNumber={pageNumber} width={width}/>
                </Document>
            </div>)
    }

    if (!!file && isImage) {
        image = URL.createObjectURL(file);
    } else if (!!file && (!!file.name || !!file.type)) {

        if (file.name?.toLowerCase().split('.').pop() === 'pdf' || file.type === 'application/pdf') {
            isPDF = true;
        }
    }

    const Pdf2 = (prp) => {

        return (<div>
            <Document
                renderMode='svg'
                file={URL.createObjectURL(file)}
                onLoadError={handleImageErrored}
                onLoadSuccess={onDocumentLoadSuccess}>
                <Page pageNumber={pageNumber} width={700}/>
            </Document>

        </div>)
    }

    const Description = () => <p style={{fontSize: '11px', margin: 0}}
                                 dangerouslySetInnerHTML={{__html: dossier.description}}/>;

    if (!!dossier.path && (!file || (!file?.type && !file?.name))) {
        // getFile();
    }

    const onDocumentLoadSuccess = (dt) => {
        setNumPages(dt.numPages);
        handleImageLoaded();
    }

    const setFile = async (f, isNew = false) => {
        if (f.name?.toLowerCase().match(/(\.jpg|\.png|\.jpeg|\.tif|\.tiff)$/) && props?.dossier?.type === 1) {
            let i = await convertBlobToBase64(f);
            setImageToCrop(i);
            setfileF(f);
        } else if (isFileValid(f)) {
            setfileF(f);
            props.onChange({
                target: {
                    files: [f]
                }
            }, dossier.type, isNew);

        } else {
            setHasError(true);
        }

    }

    const saveFile = async () => {
        if (!file) {
            const f = await Api.getFile(token, dossier.id);
            setFile(f);
            if (f) {
                saveAs(f, name);
            }
        } else {
            saveAs(file, name);
        }
    }
    const handleClickOpen = async () => {
        setOpen(true);
        if (!file) {
            const blob = await Api.getFile(token, dossier.id);
            setfileF(blob);
        }
    };

    const handleClose = () => {
        setOpen(false);
    };

    const classes = useStyles();

    // const theme = useTheme();

    function handleImageLoaded() {
        setImageError('');
        setLoading(false);
    }

    function handleImageErrored() {
        setImageError('Il y a un problème avec le téléchargement du fichier.');
        setLoading(false);
    }

    function closeCropModal(p) {

        if (p && p.file) {
            const f = new File([p.file], dossier?.file?.name || dossier?.path || file?.name, {
                type: "image/png",
                lastModified: new Date().getTime()
            });

            if (isFileValid(f)) {
                props.onChange({
                    target: {
                        files: [f]
                    }
                }, dossier.type, true);

                image = p.url;
                setfileF(f);
            } else {
                setfileF(null);
                setHasError(true);
            }
        }

        setImageToCrop(null);
    }

    return <>
        <Grid item md={props.displayMode === 'module' ? 4 : 12}
              lg={props.displayMode === 'module' ? 3 : 6}>
            <Snackbar open={hasError}
                      anchorOrigin={{vertical: 'top', horizontal: 'center'}}
                      autoHideDuration={8000}
                      onClose={() => setHasError(false)}>
                <Alert onClose={() => setHasError(false)} severity="error">
                    "Ce fichier est trop lourd ou n'est pas du bon format. Veuillez ne pas dépasser 12mb et utiliser
                    l'un
                    des types de fichier authorisés."
                </Alert>
            </Snackbar>
            <input
                type="file"
                accept={dossier.type !== 1 ? "image/*, .pdf" : "image/*"}
                style={{display: "none"}}
                onChange={(e) => {
                    setFile(e.target.files[0], true)
                }}
                ref={value =>
                    (input = value)
                }
            />
            {
                props.displayMode === 'module' ?
                    <Card className="docBox" elevation={4}>
                        <CardActionArea>
                            {
                                isPDF ? <Pdf key={'pdf-' + dossier.type}/> :
                                    <CardMedia
                                        key={'pdf-' + dossier.type}
                                        className="imageContent-img"
                                        image={image}
                                        title={dossier.name}/>
                            }
                            <CardContent>
                                <Typography gutterBottom={props.type !== 2}
                                            style={{
                                                textAlign: "center",
                                                lineHeight: props.type === 2 ? "1em" : '1.2em',
                                                color: props.type === 2 ? "blue" : "darkRed",
                                                fontSize: props.type === 2 ? ".9em" : '1.1em'
                                            }}>
                                    {dossier.name}
                                </Typography>

                                <Description/>
                                {

                                    !canEdit && name ?
                                        <div className="fileAdded" style={{marginTop: props.marginTop || 0}}>
                                            <b>{trimFileName(name)}</b>
                                            <CheckCircleRounded color="inherit"/>
                                        </div> : null

                                }
                                {
                                    !!canEdit && name ?
                                        <div className="fileAdded2" style={{marginTop: props.marginTop || 0}}>
                                            <CloudDone color="inherit"/>
                                            <b>{trimFileName(name)}</b>
                                        </div> : null
                                }
                                {
                                    !!name ? null :
                                        <Alert className='small-alert' severity="warning">Ce fichier manque.</Alert>
                                }
                            </CardContent>
                        </CardActionArea>
                        <CardActions style={{
                            justifyContent: "center",
                            borderTop: "1px solid #b4bdc9"
                        }}>
                            <ButtonGroup variant="contained" color='primary'>
                                {
                                    !canEdit ?
                                        <Tooltip title="Choisir un document">
                                            <Button onClick={() => input?.click()}>
                                                <AttachFileRounded/>
                                            </Button>
                                        </Tooltip> : null
                                }
                                {
                                    (dossier.url || file) ?
                                        <Tooltip title="Visualiser">
                                            <Button onClick={handleClickOpen}>
                                                <VisibilityRounded/>
                                            </Button>
                                        </Tooltip> : null
                                }
                                {
                                    (dossier.path || file) ?
                                        <Tooltip title="Telecharger">
                                            <Button onClick={() => saveFile()}>
                                                <CloudDownloadRounded/>
                                            </Button>
                                        </Tooltip>
                                        : null
                                }

                            </ButtonGroup>
                        </CardActions>
                    </Card>
                    :
                    <Card className={classes.root}>

                        {
                            isPDF ? <Pdf key={'pdf-' + dossier.type} className={classes.cover}/> :
                                <CardMedia
                                    className={classes.cover}
                                    image={image}
                                    title={props.title}/>
                        }
                        <div className={classes.details}>
                            <CardContent className={classes.contentSmall}>
                                <Typography gutterBottom={false}
                                            style={{
                                                textAlign: "left",
                                                lineHeight: "1em",
                                                color: "#012776",
                                                fontSize: '1em',
                                                fontWeight: '600'
                                            }}>
                                    {dossier.name}
                                </Typography>
                                <div style={{
                                    textAlign: "left",
                                    lineHeight: ".9em",
                                    fontSize: '.8em',
                                    margin: "0",
                                    minHeight: 28
                                }}>
                                    <Description/>
                                </div>
                                {
                                    !!name ?
                                        !canEdit ?
                                            <Alert className='small-alert' severity="success"
                                                   icon={<CheckCircleRounded/>}>
                                                {trimFileName(name)}
                                            </Alert> :
                                            <Alert className='small-alert' severity="info" icon={<CloudDone/>}>
                                                {trimFileName(name)}
                                            </Alert>
                                        : <Alert className='small-alert' severity="warning">Ce fichier manque.</Alert>
                                }
                            </CardContent>
                        </div>
                        <ButtonGroup
                            style={{
                                marginRight: 3, display: 'flex', justifyContent: 'center',
                                paddingLeft: '5px',
                                boxShadow: 'none',
                                borderLeft: '1px dashed #b4bdc9',
                                borderRadius: 0
                            }}
                            orientation="vertical"
                            color="primary"
                            variant="contained">
                            {
                                !canEdit ?
                                    <Tooltip title="Choisir un document">
                                        <Button onClick={() => input?.click()} size="small">
                                            <AttachFileRounded/>
                                        </Button>
                                    </Tooltip> : null
                            }
                            {
                                (dossier.path || file) ?
                                    <Tooltip title="Visualiser">
                                        <Button onClick={handleClickOpen} size="small">
                                            <VisibilityRounded/>
                                        </Button>
                                    </Tooltip> : null
                            }
                            {
                                (dossier.path || file) ?
                                    <Tooltip title="Telecharger">
                                        <Button onClick={() => saveFile()} size="small">
                                            <CloudDownloadRounded/>
                                        </Button>
                                    </Tooltip>
                                    : null
                            }
                        </ButtonGroup>
                    </Card>
            }

            <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition} theme={darkTheme}>
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <Typography variant="h6" className={classes.title}>
                            {dossier.name}
                        </Typography>
                        <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                            <Close/>
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <div className={classes.pHolder}>
                    <Grid container spacing={2} alignItems='center' className={classes.presentation}>
                        <Grid md={8} item style={{padding: 24}} xs={12}>
                            <div style={{
                                maxHeight: 'calc(100vh - 120px)',
                                overflow: 'auto', position: 'relative'
                            }}>
                                {
                                    isPDF ? <Pdf2/> :
                                        <img
                                            onLoad={handleImageLoaded}
                                            onError={handleImageErrored}
                                            className={classes.bigImage}
                                            src={image}
                                            alt={props.title}/>
                                }
                                {
                                    loading ?
                                        <>
                                            <LinearProgress/>
                                        </> : null
                                }

                                {
                                    imageError ?
                                        <Alert severity="error">{imageError}</Alert>
                                        : null
                                }
                            </div>

                        </Grid>
                        <Grid md={4} item>
                            <Description/>
                            {
                                isPDF ?
                                    <Paper elevation={5} className='center-content'
                                           style={{width: '100%', padding: 12}}>
                                        <Pagination count={numPages} showFirstButton showLastButton
                                                    theme={darkTheme}
                                                    page={pageNumber}
                                                    siblingCount={0}
                                                    onChange={(event, value) => {
                                                        setPageNumber(value);
                                                    }}/>
                                    </Paper>
                                    : null
                            }
                            {
                                !canEdit ?
                                    <div className='center-content'>
                                        <Button variant="contained" color="primary" onClick={() => input?.click()}
                                                size="large" className={classes.button}
                                                startIcon={<AttachFileRounded/>}>
                                            Choisir un document
                                        </Button>
                                    </div>
                                    : null
                            }
                            <div className='center-content' style={{marginTop: 6}}>
                                <Button variant="contained" color="primary" onClick={() => saveFile()} size="large"
                                        className={classes.button} disabled={!(dossier.path || file)}
                                        startIcon={<CloudDownloadRounded/>}>
                                    Telecharger
                                </Button>
                            </div>

                            <small>Notes:
                                <ul>
                                    <li>Les fichiers ne sont pas toujours affichés avec la meilleur fidélité. Il est
                                        parfois
                                        necessaire de les télécharger afin de les voir sans distortions ou artefacts.
                                    </li>
                                    <li>Assurez-vous de scanner les fichier téléchargés avec un <b>antivirus</b> avant
                                        de
                                        les ouvrir.
                                    </li>
                                </ul>
                            </small>
                        </Grid>
                    </Grid>
                </div>

            </Dialog>

        </Grid>

        <CropImageModal src={imageToCrop} onClose={(p) => {
            closeCropModal(p)
        }}/>
    </>

}
