import {baseUrl} from "./config";
import {settings} from "./settings";
import {convertBlobToBase64} from "./funcs";
import uuid from "react-uuid";

// TODO simplify result with function or service

const processFile = (promise) => {
    return promise.then(async response => {
        if (!response.ok) {
            throw await response.json();
        }
        return response.json()
    }).then(value => (value.results || []))
}

const postFn = (url, token, data = {}) => {
    const headers = token ?
        {
            Accept: "application/json",
            "Content-Type": "application/json; charset=utf-8",
            Authorization: "Bearer " + token
        } :
        {
            Accept: "application/json",
            "Content-Type": "application/json; charset=utf-8"
        };

    return fetch(url, {
        headers,
        method: 'POST',
        body: JSON.stringify(data)
    });
}

const getFn = (url, token = '', accept = "application/json") => {
    return fetch(url, {
        headers: {
            Accept: accept,
            "Content-Type": "application/json; charset=utf-8"
        }
    });
}

export const Api = {
    postNotes: (token, id, data) => {
        const url = `${baseUrl}demande/${id}/note`;
        const f = postFn(url, token, data);
        return processFile(f);
    },
    loadCompanies(token) {
        // TODO minimize size by fetching updates only
        let url = `${baseUrl}entreprises?`;
        const f = getFn(url, token);
        return processFile(f);
    },
    loadCompaniesPaged: (token, query = {}) => {
        let url = `${baseUrl}entreprises?`;
        url += 'per_page=' + (query.pageSize || 10);
        url += '&page=' + ((query.page || 0) + 1);
        if (query.orderBy) {
            url += `&order_by=${query.orderBy?.field || ''}`;
            url += `&direction=${query.orderDirection || "asc"}`
        }
        if (query.search) {
            url += `&search=${query.search}`;
        }

        const f = getFn(url, token);
        return processFile(f);
    },
    loadCIP: token => {
        const url = `${baseUrl}cip?relations=entreprise`;
        const f = getFn(url, token);
        return processFile(f);
    },
    loadCategories: () => {
        const url = `${baseUrl}categories`;
        const f = getFn(url);
        return processFile(f);
    },
    loadSpecialities: () => {
        const url = `${baseUrl}specialities`;

        const f = getFn(url);
        return processFile(f);
    },
    loadTypes: () => {
        const url = `${baseUrl}types`;
        const f = getFn(url);
        return processFile(f);
    },
    getEntreprise: (token, id) => {
        const url = `${baseUrl}entreprise/${id}`;
        const f = getFn(url, token);
        return processFile(f);
    },
    loadImages: (token, idEntreprise) => {
        const url = `${baseUrl}entreprise/${idEntreprise}/dossiers`;
        return getFn(url, token).then(res => res.json())
            .then(response => {
                const files = response.results || [];
                let dossiersFull = {};
                for (const f of files) {
                    if (dossiersFull.hasOwnProperty(f.type)) {
                        if (dossiersFull[f.type].last.id < f.id) {
                            dossiersFull[f.type].last = f;
                        }
                        dossiersFull[f.type].files.push(f);
                    } else {
                        dossiersFull[f.type] = {
                            last: f,
                            files: [f]
                        };
                    }
                }

                const dossiers = [];
                for (const tp in dossiersFull) {
                    if (dossiersFull.hasOwnProperty(tp)) {
                        dossiers.push(dossiersFull[tp].last)
                    }
                }
                return {dossiers, dossiersFull}
            })
    },
    loadDemandes: (token, lastUpdateServer) => {
        const url = `${baseUrl}demandes?lastUpdate=${lastUpdateServer}&y=${uuid()}`;
        const f = getFn(url, token);
        return processFile(f);
    },
    loadDemande: (token, id) => {
        const url = `${baseUrl}demande/${id}`;
        const f = getFn(url, token);
        return processFile(f);
    },
    loadAdmins: (token, query) => {
        console.log(query);
        let url = `${baseUrl}users/admins?`
        url += 'per_page=' + query.pageSize;
        url += '&page=' + (query.page + 1);
        if (query.orderBy) {
            url += `&order_by=${query.orderBy?.field || ''}`;
            url += `&direction=${query.orderDirection || "asc"}`
        }

        if (query.search) {
            url += `&search=${query.search}`;
        }

        const f = getFn(url, token);
        return processFile(f);
    },
    loadCip: (token, query) => {
        let url = `${baseUrl}cips?`
        url += 'per_page=' + query.pageSize;
        url += '&page=' + (query.page + 1);
        if (query.orderBy) {
            url += `&order_by=${query.orderBy?.field || ''}`;
            url += `&direction=${query.orderDirection || "asc"}`
        }
        if (query.search) {
            url += `&search=${query.search}`;
        }

        const f = getFn(url, token);
        return processFile(f);
    },
    updateAdmin(token, id, body) {
        const url = `${baseUrl}admin/${id}/update`;
        const f = postFn(url, token, body);
        return f.then(res => res.json());
    },
    addAdmin(token, body) {
        const url = `${baseUrl}admin`;
        const f = postFn(url, token, body);
        return f.then(res => res.json());
    },
    drawOneDemande(token) {
        const url = `${baseUrl}drawOne/demande`;
        const f = postFn(url, token);
        return processFile(f);
    },
    updateCip(id, no_cip, description_photo, category_id, speciality_id, savedCip, token) {
        const url = `${baseUrl}demande/${id}/cip`;
        const body = {
            no_cip, description_photo, category_id, speciality_id
        };

        if(savedCip && savedCip.no_cip){
            body.oldCip = savedCip.no_cip;
        }

        const f = postFn(url, token, body);
        return processFile(f);
    },
    supervisorPick(id, token) {
        const url = `${baseUrl}demande/${id}/give_me`;
        const f = postFn(url, token);
        return processFile(f);
    },
    loadOfficiers(token) {
        const url = `${baseUrl}officiers`;
        const f = getFn(url, token);
        return processFile(f);
    },
    setDemandePriority(id, value, token) {
        const url = `${baseUrl}demande/${id}/priority`;
        const body = {
            value
        };
        const f = postFn(url, token, body);
        return processFile(f);
    },
    setDemandeForInpection(id, inspector, inspectionDate, token) {
        const url = `${baseUrl}demande/${id}/inspector`;
        const body = {
            inspector,
            date: inspectionDate
        };
        const f = postFn(url, token, body);
        return processFile(f);
    },
    assignOfficer(officer_id, id) {
        const url = `${baseUrl}demande/${id}/assign_officer`;
        const body = {
            officer_id
        };
        const f = postFn(url, '', body);
        return processFile(f);
    },
    rejectToBusinessMan(token, id, message) {
        const url = `${baseUrl}demande/${id}/reject_to_owner`;
        const body = {
            message
        };
        const f = postFn(url, token, body);
        return processFile(f);
    },
    continueToDirector(id, token) {
        const url = `${baseUrl}demande/${id}/envoyer_au_directeur`;
        const f = postFn(url, token);
        return processFile(f);
    },
    continueToPrint(id, token) {
        const url = `${baseUrl}demande/${id}/envoyer_imprimer`;
        const f = postFn(url, token);
        return processFile(f);
    },
    getCipTopPrint(token, id) {
        const url = `${settings.api}/api/v1/cip/${id}`;
        const f = getFn(url, token);
        return processFile(f);
    },
    getFile(token, id) {

        return fetch(`${settings.api}/api/v1/dossier/${id}/file`,
            {
                method: "GET",
                mode: 'cors',
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + token
                },
                cache: "force-cache"
            }
        )
            .then(res => res.blob())
            .then(blob => {
                // window.location.assign(file);
                return blob;
            });
    },
    markCardAsPrinted(id) {
        const url = `${baseUrl}cip/${id}/mark_as_printed`;
        const f = postFn(url);
        return processFile(f);
    },
    updatePassword(token, data) {
        const url = `${baseUrl}user/update/password`;
        const f = postFn(url, token, data);
        return processFile(f);
    },
    drawOneCard(token) {
        const url = `${baseUrl}drawOne/cip`;
        const f = postFn(url, token);
        return processFile(f);
    },
    checkUserStatus() {
        const url = `${baseUrl}user/status`;
        const f = getFn(url);
        return processFile(f);
    },
    resetPassword(body) {
        const url = `${baseUrl}user/passwordReset`;
        const f = postFn(url, '', body);
        return processFile(f);
    },
    logoutUser(token) {
        const url = `${baseUrl}user/logout`;
        const f = postFn(url, token);
        return processFile(f);
    },
    changeProfile(token, body) {
        const url = `${baseUrl}user/profile`;
        const f = postFn(url, token, body);
        return processFile(f);
    },
    refreshToken() {
        const url = `${baseUrl}user/refreshToken`;
        const f = postFn(url);
        return processFile(f);
    },
    getAvatar(token, user_id = null) {

        const url = user_id ? `${baseUrl}user/avatar/${user_id}` : `${baseUrl}user/avatar`;
        return fetch(url, {
            headers: {
                Accept: "image/*",
                Authorization: "Bearer " + token
            }
        }).then(response => response.blob())
            .then(async images => {
                const url = await convertBlobToBase64(images);
                return url;
            })
    },
    updateAvatar(token, avatar) {
        const url = `${baseUrl}user/avatar`;
        const dataFile = new FormData();
        dataFile.append("avatar", avatar);
        const f = fetch(url, {
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + token
            },
            method: 'POST',
            body: dataFile
        });
        return processFile(f);
    },
    assignDemandeToMe(id) {
        const url = `${baseUrl}demande/${id}/assign_to_me_to_evaluate`;

        const f = postFn(url, '');
        return processFile(f);
    },
    sendCardToPrint(id) {
        const url = `${baseUrl}cip/reprint/${id}`;
        const f = postFn(url);
        return processFile(f);
    },
    postNotesCommercant(demandeId, data) {
        const url = `${baseUrl}demande/${demandeId}/noteCommercant`;
        const f = postFn(url, '', data);
        return processFile(f);
    },
    acceptCommercant(demandeId) {
        const url = `${baseUrl}demande/${demandeId}/sendSummaryToBusinessOwner`;
        const f = postFn(url);
        return processFile(f);
    },
    markCardAsDelivered(id, data) {
        const url = `${baseUrl}cip/${id}/mark_as_delivered`;
        const f = postFn(url, '', {
            name: data.name,
            card_type: data.type,
            card_no: data.no
        });
        return processFile(f);
    },
    free() {
        const url = `${baseUrl}free/demande`;
        const f = postFn(url);
        return processFile(f);
    },
    adminResetPassword(id) {
        const url = `${baseUrl}super/admin/${id}/reset-password`;
        const f = postFn(url);
        return processFile(f);
    },
    adminChangePassword(id, password, confirmPassword) {
        const url = `${baseUrl}super/admin/${id}/change-password`;
        const f = postFn(url, '', {
            new_password: password,
            confirm_password: confirmPassword
        });
        return processFile(f);
    }
}

